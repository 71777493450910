import { FC } from 'react';

import CreateToolbar, {
  TableToolbarProps,
} from '../../Table/Toolbars/CreateToolbar';

const ServicesHistoryToolbar: FC<TableToolbarProps> = params => {
  return <CreateToolbar {...params} withOutCreate />;
};

export default ServicesHistoryToolbar;
