import { AssetCreationType, AssetFiltersType } from 'src/api/api-types/assets';
import * as yup from 'yup';

import { ColumnsVisibilityModel } from '../../../api/api-types/gridColumnsManager';

export const initialAssetsData: AssetCreationType = {
  shortText: '',
  client: { id: 0, name: '' },
  totalAttachments: 0,
  createdAt: new Date(),
  id: 0,
  description: '',
  dateIssued: new Date(),
};

const basicValidationConfig = {
  type: yup.mixed().label('Type').required(),
  relatedToType: yup.object().label('Related to').nullable(),
  relatedTo: yup.object().label('Related to').nullable(),
  dateIssued: yup
    .string()
    .label('Date issued')
    .nullable()
    .when('client', {
      is: (val: string) => val === '2' || val === '3',
      then: yup.string().required().typeError('Date issued to is required'),
    }),
  description: yup.string().label('Description').optional().max(100_000),
  category: yup
    .number()
    .required()
    .label('Category')
    .typeError('Category is required'),
};
export const validationSchema = yup
  .object({
    ...basicValidationConfig,
    subCategory: yup.string().optional().label('Sub-Category').nullable(),
  })
  .required();

export const validationActivitySchema = yup
  .object({
    ...basicValidationConfig,
    subCategory: yup.string().required().label('Sub-Category').nullable(),
  })
  .required();
export const validationWaiverSchema = yup
  .object({
    ...basicValidationConfig,
    waiverInfoStatusId: yup
      .string()
      .required()
      .label('Waiver Status')
      .nullable(),
    subCategory: yup.string().required().label('Sub-Category').nullable(),
  })
  .required();

export const validationIRSchema = yup
  .object({
    shortText: yup.string().label('Activity title').required(),
    description: yup.string().label('Description').optional().max(100_000),
  })
  .required();

export const defaultVisibilityColumns = {
  accountType: true,
  generator: true,
  createdAt: true,
  createdBy: true,
  description: true,
  shortText: true,
};

export const visibilityDefaultState: Partial<ColumnsVisibilityModel> = {
  admin: {
    jurisdiction: true,
    ...defaultVisibilityColumns,
  },
  hauler: {
    hauler: true,
    ...defaultVisibilityColumns,
  },
};

export const defaultAssetsSearch = {
  'q.jurisdiction.id': '',
  'q.generator.accountNumber': '',
  'q.generator.name': '',
  'q.generator.reference': '',
  'q.generator.usaAddress': '',
  'q.parentLocation.reference': '',
} as AssetFiltersType;

export const DEFAULT_POPUP_AREA_WIDTH = 380;
