import { lazy } from 'react';

import { MinervaRoute } from 'src/utils/router';

import { userStore } from '../../stores/user';

import { dashboardStore } from './store';

export const DashboardRoute: MinervaRoute<'index'> = {
  name: 'index',
  path: '/',
  comp: () => lazy(() => import('./index')),
  activate: async () => {
    await dashboardStore.initDashboardFetching(
      userStore.selectedJurisdictionId,
    );
  },
  deactivate: () => dashboardStore.resetStore(),
};
