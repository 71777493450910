import { useContext } from 'react';

import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react-lite';
import Autocomplete from 'src/components/Autocomplete';
import { lookupsStoreCtx } from 'src/stores/lookups';
import { isPending } from 'src/utils/common';

const GeneratorField = observer(
  ({
    fieldName = 'generator',
    placeholder = 'Look for generators...',
  }: {
    fieldName?: string;
    placeholder?: string;
  }) => {
    const { generatorsLookupsReq, generatorsLookups, fetchGeneratorsLookups } =
      useContext(lookupsStoreCtx);

    return (
      <Autocomplete
        asyncSearch
        name={fieldName}
        options={generatorsLookups}
        sx={{ minWidth: '200px' }}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            variant="outlined"
            placeholder={placeholder}
          />
        )}
        request={fetchGeneratorsLookups}
        loading={isPending(generatorsLookupsReq)}
      />
    );
  },
);

export default GeneratorField;
