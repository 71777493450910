import { FC, MouseEvent } from 'react';

import AddIcon from '@mui/icons-material/Add';
import AttachmentIcon from '@mui/icons-material/Attachment';
import BlockIcon from '@mui/icons-material/Block';
import EditOffIcon from '@mui/icons-material/EditOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react-lite';

import { Asset } from '../../../../models/assets/asset';
import { useAssetsTableStore } from '../../AssetsTableProvider';
import { fileStore } from '../AddAttachments';

interface Props {
  collapsed: boolean;
  asset: Asset;
  handleOpenList: (event: MouseEvent<HTMLElement>) => void;
}

const CountItem: FC<Props> = observer(
  ({
    collapsed,
    handleOpenList,
    asset: { id, totalAttachments, canUpdate, isRelatedEntityActive },
  }) => {
    const { uploadAssets, chosenAsset } = useAssetsTableStore();

    const handleUpload = () => {
      uploadAssets(id);
      fileStore.fileDialogStore.openDialog();
    };

    const attachmentsCount =
      chosenAsset?.id === id
        ? chosenAsset?.attachments.totalAttachments
        : totalAttachments;

    return (
      <Grid
        container
        alignItems="center"
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          flexDirection: 'row',
          overflow: 'hidden',
          cursor: 'pointer',
        }}>
        {canUpdate && !isRelatedEntityActive && (
          <Tooltip title="Inactive activity">
            <BlockIcon fontSize="small" />
          </Tooltip>
        )}
        {!canUpdate && (
          <Tooltip title="Read only asset">
            <EditOffIcon fontSize="small" />
          </Tooltip>
        )}
        <AttachmentIcon
          fontSize="small"
          sx={{
            transform: 'rotate(-45deg)',
            color: 'text.secondary',
          }}
        />
        <Typography
          variant="body1"
          sx={{ margin: theme => theme.spacing(0, 0.7), width: 3 }}>
          {attachmentsCount}
        </Typography>
        {attachmentsCount === 0 ? (
          <IconButton
            name="add-btn"
            disabled={!canUpdate || !isRelatedEntityActive}
            sx={{ color: 'text.secondary' }}
            size="medium"
            onClick={handleUpload}>
            <AddIcon />
          </IconButton>
        ) : (
          <Button
            id="basic-button"
            size="medium"
            name="file-btn"
            sx={{ color: 'text.secondary' }}
            aria-controls={collapsed ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={collapsed ? 'true' : undefined}
            startIcon={
              collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />
            }
            onClick={handleOpenList}
          />
        )}
      </Grid>
    );
  },
);

export default CountItem;
