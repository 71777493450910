import Highcharts, { Options } from 'highcharts';
import { PieChartType } from 'src/api/api-types/diagrams';
import { pieColors } from '../../pages/Dashboard/components/Charts/config/constant';
import { ClickOnLegendType, ExportCallBackType } from '../../types';
import { exportOptions } from '../../pages/Dashboard/helpers/common';
import HighchartsReact from 'highcharts-react-official';
import {
  PrintableChart,
  downloadPdf,
  getPieChartExportRasterImageProps,
  getPieChartExportSVGProps,
} from './utils';
import { ExportFileType } from 'src/api/exportTypes';

export class PieChart extends PrintableChart {
  protected exportCallBack?: ExportCallBackType;
  protected clickOnSection?: ClickOnLegendType;
  public options: Options;
  public chartRef?: HighchartsReact.Props['pie'];
  constructor(
    pieChart: PieChartType,
    callBackClick: ClickOnLegendType,
    exportCallBack: ExportCallBackType,
  ) {
    const options = getPieChartExportRasterImageProps('application/pdf');
    super(
      options.exportOptions.sourceWidth,
      options.exportOptions.sourceHeight,
      options.exportOptions.scale,
    );

    this.clickOnSection = callBackClick;
    this.exportCallBack = exportCallBack;
    this.options = {
      chart: {
        type: 'pie',
      },
      loading: {
        style: {
          opacity: 0.7,
          top: '0px',
          left: '0px'
        },
        labelStyle: {
          fontSize: "24px"
        },
      },
      title: {
        text: pieChart.title,
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          opacity: 0.8,
        },
      },
      series: [
        {
          name: pieChart.title,
          data: this.formatToSeries(pieChart),
          tooltip: {
            headerFormat: '',
            pointFormatter: function () {
              return `${this.name} Collection System: ${this.y}`;
            },
          },
        },
      ] as Highcharts.SeriesOptionsType[],
      legend: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.y}',
            connectorWidth: 2,
            style: {
              fontSize: '16px',
            },
          },
        },
        series: {
          showInNavigator: true,
          point: {
            events: {
              click: this.pointClick.bind(this),
            },
          },
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              series: [
                {
                  dataLabels: {
                    style: {
                      fontSize: '6px',
                    },
                  },
                },
              ] as Highcharts.SeriesOptionsType[],
            },
          },
          {
            condition: {
              maxWidth: 700,
            },
            chartOptions: {
              series: [
                {
                  dataLabels: {
                    style: {
                      fontSize: '10px',
                    },
                  },
                },
              ] as Highcharts.SeriesOptionsType[],
            },
          },
        ],
      },
      exporting: {
        chartOptions: {
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
              },
            },
          },
        },
        buttons: {
          contextButton: {
            menuItems: [
              'viewFullscreen',
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
              'downloadCSV',
              'downloadXSLS',
            ],
          },
        },
        menuItemDefinitions: {
          downloadXSLS: {
            onclick: () => {
              if (!this.exportCallBack) return;
              this.chartRef.fullscreen.isOpen && this.chartRef.showLoading();
              this.exportCallBack({
                dashboard: [exportOptions[1]],
                type: ExportFileType.XLSX,
              });
            },
            text: 'Download XSLS',
          },
          downloadSVG: {
            onclick: () => {
              if (!this.chartRef) return;
              const options = getPieChartExportSVGProps('image/svg+xml');
              this.chartRef.exportChartLocal(
                options.exportOptions,
                options.chartOptions,
              );
            },
          },
          downloadCSV: {
            onclick: () => {
              if (!this.exportCallBack) return;
              this.chartRef.fullscreen.isOpen && this.chartRef.showLoading();
              this.exportCallBack({
                dashboard: [exportOptions[1]],
                type: ExportFileType.CSV,
              });
            },
            text: 'Download CSV',
          },
          downloadPNG: {
            onclick: () => {
              if (!this.chartRef) return;
              const options = getPieChartExportRasterImageProps('image/png');
              this.chartRef.exportChartLocal(
                options.exportOptions,
                options.chartOptions,
              );
            },
            text: 'Download PNG image',
          },
          downloadJPEG: {
            onclick: () => {
              if (!this.chartRef) return;
              const options = getPieChartExportRasterImageProps('image/jpeg');
              this.chartRef.exportChartLocal(
                options.exportOptions,
                options.chartOptions,
              );
            },
            text: 'Download JPEG image',
          },
          printChart: {
            onclick: () => {
              if (!this.chartRef || !this.onPrint) return;
              this.onPrint();
            },
            text: 'Print chart',
          },
          downloadPDF: {
            onclick: () => {
              if (!this.chartRef) return;
              const options =
                getPieChartExportRasterImageProps('application/pdf');
              downloadPdf(
                this.chartRef,
                options.exportOptions.sourceWidth,
                options.exportOptions.sourceHeight,
              );
            },
            text: 'Download PDF document',
          },
        },
        fallbackToExportServer: false,
      },
    };
  }

  protected formatToSeries(data: PieChartType) {
    return data.values.map((item, index) => {
      if (item.value <= 0) return null;
      return {
        id: !!item.filter?.id ? item.filter.id : `0`,
        name: item.title,
        y: item.value,
        color: pieColors[index],
      };
    });
  }

  private pointClick(
    data: Highcharts.PointClickEventObject,
    resetSelection: boolean = false,
  ) {
    if (resetSelection) {
      data.point.series.points.forEach(point => {
        point.setState('');
      });
    }
    if (!this.clickOnSection || data.point.selected) return;
    this.clickOnSection(
      (data.point as Highcharts.Point & { id: string }).id,
      data.point.name,
      data.point.series.name,
      data.point.y,
    );
  }

  public setChartRef(data: HighchartsReact.Props['pie']) {
    this.chartRef = data;
  }

  public resetChartRef() {
    this.chartRef = undefined;
  }

  public resetSelection() {
    if (this.chartRef === undefined) {
      // eslint-disable-next-line no-console
      console.error("Chart red is empty, can't reset selection");
      return;
    }
    this.chartRef.series[0].points.forEach((point: Highcharts.Point) => {
      point.setState('');
      if (point.sliced) {
        point.sliced = false;
        point.select(false);
      }
    });
  }
}
