import { useEffect } from 'react';

import { useGridApiContext } from '@mui/x-data-grid-pro';
import { WaiverInfoType } from 'src/api/api-types/assets';

import { WaiverTypeId } from '../CreateActivity/type';

import BaseSelectDate from './BaseSelectDate';
import { storeManager } from './CellManager/store';

interface SelectDateProps {
  id: number | string;
  value: Date | null;
  field: string;
}

export const SelectDate = (props: SelectDateProps) => {
  const apiRef = useGridApiContext();
  const handleChange = (
    id: number | string,
    field: string,
    value: Date | null,
  ) => {
    apiRef.current.setEditCellValue({
      id,
      field,
      value,
    });
  };

  return <BaseSelectDate {...props} onChange={handleChange} />;
};

interface SelectWaiverFollowUpDateProps {
  id: number | string;
  value: WaiverInfoType['followUpDate'];
  field: string;
}

export const SelectWaiverFollowUpDate = (
  props: SelectWaiverFollowUpDateProps,
) => {
  const apiRef = useGridApiContext();
  const store = storeManager.getStore();

  useEffect(() => {
    store.addSubscriber();

    return () => {
      store.removeSubscriber();
      storeManager.releaseStore();
    };
  }, [store]);

  const handleChange = (
    id: number | string,
    field: string,
    value: Date | null,
  ) => {
    apiRef.current.setEditCellValue({
      id,
      field,
      value,
    });
  };

  if (store.activityType?.id !== WaiverTypeId) {
    return null;
  }

  return (
    <BaseSelectDate {...props} value={props.value} onChange={handleChange} />
  );
};
