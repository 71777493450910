import { TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface BaseSelectDateProps {
  id: number | string;
  field: string;
  value: any;
  onChange: (id: number | string, field: string, value: any) => void;
}

const BaseSelectDate = observer(
  ({ id, field, value, onChange }: BaseSelectDateProps) => {

    const changeCellHandler = (selectedDate: Date | null) => {
      onChange(id, field, selectedDate);
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          inputFormat='MM/dd/yyyy'
          value={value}
          onChange={(date: Date | null) => changeCellHandler(date)}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    );
  }
);

export default BaseSelectDate;