import { dotNameKeyToString } from './../utils/string';
import { ApiClient } from '.';
import {
  RequestServicesParams,
  ServicesResponse,
  ServiceHistoryResponse,
  FilterFields,
  ServicesFilter,
} from './api-types/services';
import { dotNameKeyToObject } from 'src/utils/string';
import { AsyncExportResponse, ExportParams, ItemType, ListResponseType, Pageable, ParamsWithFilters } from 'src/types';

export class ServicesApi extends ApiClient {
  getServicesData({ page, pageSize, filters }: RequestServicesParams) {
    return this.requestPost<
      ServicesResponse,
      ParamsWithFilters<ServicesFilter>
    >('services/list', {
      q: dotNameKeyToObject(filters),
      page: {
        num: page,
        size: pageSize,
      },
    });
  }

  async getServicesFilterOptions({ filters, column }: { filters: Partial<FilterFields>, column: string }) {
    const filterMap: { [key: string]: string } = {
      hauler: 'hauler.name',
      jurisdiction: 'jurisdiction.name',
      unitOfMeasure: 'unitOfMeasure.name',
      accountType: 'accountType.name',
      serviceWasteType: 'serviceWasteType.name'
    };
    const filterColumn = filterMap[column] ?? column;
    const response = await this.requestPost<
      ListResponseType<ItemType> & Pageable,
      ParamsWithFilters<FilterFields> & { column: string }
    >('services/list/filter-options', {
      q: dotNameKeyToObject(filters),
      page: {
        num: 1,
        size: 100000,
      },
      column: filterColumn,
    });

    return response.data!.items;
  }

  getServiceHistoryData({ page, pageSize, filters }: RequestServicesParams) {
    return this.requestGet<ServiceHistoryResponse>('services-history', {
      'page.num': page,
      'page.size': pageSize,
      ...dotNameKeyToString(filters),
    });
  }

  getAsyncServicesHistoryReportByType(params: ExportParams, exportType: string) {
    return this.requestPost<AsyncExportResponse, ExportParams>(
      `services-history/export/${exportType}?flowType=2`,
      params,
    );
  }

  getAsyncReportByType(params: ExportParams, exportType: string) {
    return this.requestPost<AsyncExportResponse, ExportParams>(
      `services/export/${exportType}?flowType=2`,
      params,
    );
  }
}
