import {
  FeaturesConfigType,
  FeaturesConfiguration,
  PortalConfiguration,
  RouteFeaturesConfigType,
} from 'src/api/api-types/configuration';
import { computed, makeObservable, observable } from 'mobx';

export class FeaturesConfigurationModel implements FeaturesConfiguration {
  userConnectedTo = false;
  isNewCharts = false;
  isStockChart = false;
  isHaulerWorkspace = false;
  isAgencyWorkspace = false;
  isAccountGeneratorEditing = false;
  isClientLogo = false;
  isNavBarClientJurisdictionLogo = false;
  isEditUserEmployer = false;
  isNewPagination = false;
  isExportFilteredColumns = false;
  isInactiveLists = false;
  displayAgencyColumn = false;
  isGeneratorType = false;
  isRollupPhase1 = false;
  isEFRPPhase2 = false;
  isExportEFR = false;
  isExportEFG = false;
  isEmailLinks = false;
  isExportServiceHistory = false;
  isExportServices = false;
  isExportContainers = false;
  isExportUsers = false;
  isExportClients = false;
  isExportHaulers = false;
  isExportAssetsFromDetails = false;
  isExportContacts = false;
  isExportAccountsFromDetails = false;
  isCommunicationModule = false;
  isAssetsStandaloneCreation = false;
  isWaiverModule = false;
  isIRExport = false;

  @observable portalConfiguration: PortalConfiguration = {
    implementationRecordAvailable: false,
    rollupAvailable: false,
    singleJurisdictionId: null,
    multipleJurisdictionsAvailable: false,
    rollupHaulerSelectorAvailable: false,
  };

  constructor(
    data?: FeaturesConfiguration & { portalConfiguration: PortalConfiguration },
  ) {
    makeObservable(this);
    Object.assign(this, data);
  }

  //Feature Router control
  @computed get featuresRouterMap(): RouteFeaturesConfigType {
    return {
      'implementation-record':
        this.portalConfiguration.implementationRecordAvailable,
    };
  }

  //Feature control
  @computed get featuresMap(): FeaturesConfigType {
    return {};
  }
}
