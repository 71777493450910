import { action, computed, IObservableArray, makeObservable, observable, reaction, runInAction } from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import { ClickOnLegendType, ClickOnPointType, ExportCallBackType, ExportTypes, ItemType } from 'src/types';

import { storeFactory } from '../../utils/store';

import {
  GeneratorItem,
  GeneratorsFilterType,
  GeneratorsResponse,
} from 'src/api/api-types/generators';
import { GeneratorModification } from 'src/models/generators/generator-modification';
import { Generator } from '../../models/generators/generator';

import { EarDiagramsApi } from '../../api/diagrams';
import {
  CardType,
  DiagramsCardsResponse,
  LineChartsResponse,
  LineChartsType,
  PieChartsResponse,
} from '../../api/api-types/diagrams';
import { lookupsStore } from '../../stores/lookups';
import { Sections } from 'src/models/SectionsOfDashboard';
import { ExportDataType, ExportDashboardFormType } from 'src/api/exportTypes';
import { GridRowId } from '@mui/x-data-grid-pro';
import { exportOptions, generateFilterTitle } from './helpers/common';
import { TableFilter } from 'src/models/filter/table-filter';
import { columns } from '../Generators/helpers/gridColumns';
import { generatorsFilterNameMap } from '../../models/filter/constants';
import {
  defaultGeneratorSearch,
  visibilityDefaultState,
} from '../Generators/helpers/common';
import { MergedLookup } from 'src/api/api-types/lookups';
import { TablePagination } from '../../models/pagination/pagination';
import { GridColumnsManager } from '../../models/grid-columns-manager/grid-columns-manager';
import { StockChart } from '../../models/charts/StockChart';
import { MultiStockChart } from '../../models/charts/MultiStockChart';
import { PieChart } from '../../models/charts/PieChart';
import { PolarChart } from '../../models/charts/PolarChart';
import { ignoreType } from './components/Cards/utils';
import { CardsType } from 'src/utils/constants';
import { AsyncExportEntity } from 'src/models/export/AsyncExportEntity';
import pick from 'lodash/pick';
import { dotNameKeyToObject } from 'src/utils/string';

export type GeneratorsLookUpsType = Partial<
  Record<keyof GeneratorItem, Array<ItemType | MergedLookup>>
>

export class DashboardStore {
  diagramApi = new EarDiagramsApi({ prefix: '' });

  @observable targetJurisdiction?: ItemType;
  @observable cardFilterTitle?: string;
  @observable selectedGenerators: Array<GridRowId> = [];
  @observable sections = new Sections();
  @observable stockChartOptions?: StockChart;
  @observable multiChartOptions?: MultiStockChart;
  @observable pieChartOptions?: PieChart;
  @observable polarChartOptions?: PolarChart;
  @observable exportEntity: AsyncExportEntity = new AsyncExportEntity();

  static readonly defaultScreenHeight = 400;
  @observable screenHeight: number = DashboardStore.defaultScreenHeight;

  @observable.ref polarChartReq: ApiReq<PieChartsResponse> = emptyValue;
  @observable.ref doughnutChartReq: ApiReq<PieChartsResponse> = emptyValue;

  @observable.ref barChartReq: ApiReq<LineChartsResponse> = emptyValue;
  @observable.ref earChartReq: ApiReq<LineChartsType> = emptyValue;
  @observable.ref multiLineChartReq: ApiReq<LineChartsType> = emptyValue;
  @observable.ref slcpChartReq: ApiReq<LineChartsType> = emptyValue;

  @observable.ref cardsCommonReq: ApiReq<DiagramsCardsResponse> = emptyValue;
  @observable.ref cardsMoreReq: ApiReq<DiagramsCardsResponse> = emptyValue;
  @observable.ref cardsMcrReq: ApiReq<DiagramsCardsResponse> = emptyValue;

  @observable.ref generatorsReq: ApiReq<GeneratorsResponse> = emptyValue;
  @observable.ref generators: IObservableArray<Generator> = observable([]);
  @observable.ref chosenGenerator?: GeneratorModification;

  @observable dashboardExportEntity: AsyncExportEntity = new AsyncExportEntity();

  @observable pagination = new TablePagination<GeneratorsResponse>({
    requestRef: this.generatorsReq,
  });

  @observable columnsManager;
  @observable filterModel?: TableFilter<GeneratorsFilterType, GeneratorItem>;

  constructor() {
    makeObservable(this);

    this.pagination.setFetchingCallBack(this.fetchLocations);
    this.columnsManager = new GridColumnsManager<GeneratorItem>({
      columns,
      columnsMap: this.columnsVisibleMap,
      visibilityModel: visibilityDefaultState,
    });

    reaction(() => this.dashboardExportEntity.loading, () => {
      if (!this.dashboardExportEntity.loading) {
          const chartOptionsList = [
              this.stockChartOptions,
              this.multiChartOptions,
              this.pieChartOptions,
              this.polarChartOptions
          ];
          chartOptionsList.forEach(chartOptions => 
            chartOptions?.chartRef?.loadingShown && chartOptions?.chartRef?.hideLoading()
          );
      }
    });
  }

  @computed get columnsVisibleMap() {
    return {
      jurisdiction: {
        jurisdiction: false,
      },
    };
  }

  @computed get filter() {
    this.filterModel ??= new TableFilter<GeneratorsFilterType, GeneratorItem>(
      this.columnsManager?.gridColumns,
      defaultGeneratorSearch,
      generatorsFilterNameMap,
      (column, filters) => this.diagramApi.getGeneratorsFilterOptions({ filters, column })
    );
    return this.filterModel;
  }

  @computed get generatorsData() {
    if (this.generatorsReq.state !== 'fulfilled' || !this.generatorsReq.value) {
      return [];
    }
    return this.generatorsReq.value.data?.items || [];
  }

  @computed get commonCards() {
    if (this.cardsCommonReq.state !== 'fulfilled' || !this.cardsCommonReq.value) {
      return [];
    }
    return this.cardsCommonReq.value.data?.cards || [];
  }

  @computed get mcrCards() {
    if (this.cardsMcrReq.state !== 'fulfilled' || !this.cardsMcrReq.value) {
      return [];
    }
    return this.cardsMcrReq.value.data?.cards || [];
  }

  @computed get moreCards() {
    if (this.cardsMoreReq.state !== 'fulfilled' || !this.cardsMoreReq.value) {
      return [];
    }
    return this.cardsMoreReq.value.data?.cards || [];
  }

  @computed get polarChartData() {
    if (this.polarChartReq.state !== 'fulfilled' || !this.polarChartReq.value) {
      return null;
    }
    return this.polarChartReq.value.data?.pieChart;
  }

  @computed get doughnutChartData() {
    if (this.doughnutChartReq.state !== 'fulfilled' || !this.doughnutChartReq.value) {
      return null;
    }
    return this.doughnutChartReq.value.data?.pieChart;
  }

  @computed get isDoughnutChartEmpty() {
    if (!this.doughnutChartData) return false;
    const res = this.doughnutChartData.values.reduce((acc: any, cur: any) => {
      return acc + Number(cur.value);
    }, 0);

    return res !== 0;
  }

  @computed get isPieChartEmpty() {
    if (!this.polarChartData) return false;
    const res = this.polarChartData.values.reduce((acc: any, cur: any) => {
      return acc + Number(cur.value);
    }, 0);
    return res !== 0;
  }

  @computed get barChartData() {
    if (this.barChartReq.state !== 'fulfilled' || !this.barChartReq.value) {
      return null;
    }
    return this.barChartReq.value.data?.lineChart;
  }

  @computed get earChartData() {
    if (this.earChartReq.state !== 'fulfilled' || !this.earChartReq.value) {
      return null;
    }
    return this.earChartReq.value.data?.lineChart;
  }

  @computed get slcpChartData() {
    if (this.slcpChartReq.state !== 'fulfilled' || !this.slcpChartReq.value) {
      return null;
    }
    return this.slcpChartReq.value.data?.lineChart;
  }

  @computed get chartData() {
    if (!this.barChartData) return [];
    return this.barChartData?.values.map((item: any) => ({
      name: item.title,
      data: item.values.map((item: any) => item.value),
    }));
  }

  @computed get multiLineChartData() {
    if (this.multiLineChartReq.state !== 'fulfilled' || !this.multiLineChartReq.value) {
      return null;
    }
    return this.multiLineChartReq.value.data?.lineChart;
  }

  @computed get requestGeneratorsFilter() {
    return {
      ...this.filter.filterParams,
      ...(this.targetJurisdiction?.id && { 'q.jurisdiction.id': String(this.targetJurisdiction?.id) || null }),
    };
  }

  @action setScreenHeight = (screenHeight: number) => {
    this.screenHeight = screenHeight;
  };
  @action setJurisdictions = (value: ItemType) => {
    this.targetJurisdiction = value;
  };

  @action setCardFilterTitle = (title?: string) => this.cardFilterTitle = title;
  @action fetchCommonCards = async (id?: number) => {
    this.cardsCommonReq = this.diagramApi.getCommonCards(id);
    await this.cardsCommonReq;
    return this.generatorsReq;
  };

  @action fetchMcrCards = async (id?: number) => {
    this.cardsMcrReq = this.diagramApi.getMcrCards(id);
    await this.cardsMcrReq;
    return this.cardsMcrReq;
  };
  @action fetchMoreCards = async (id?: number) => {
    this.cardsMoreReq = this.diagramApi.getMOReCards(id);
    await this.cardsMoreReq;
    return this.cardsMoreReq;
  };

  @action fetchDataBehavior = async (selectedJurisdictions: ItemType | null) => {
    if (selectedJurisdictions) {
      return this.selectingJurisdictions(selectedJurisdictions);
    } else {
      return this.fetchJurisdiction();
    }
  };
  @action fetchJurisdiction = async () => {
    await lookupsStore.fetchJurisdictionsLookups();
    runInAction(() => {
      if (lookupsStore.jurisdictions.length > 0) {
        this.selectingJurisdictions(lookupsStore.jurisdictions[0]);
      }
    });
  };
  @action fetchCards = async () => {
    await Promise.allSettled([
      this.fetchCommonCards(this.targetJurisdiction?.id),
      this.fetchMcrCards(this.targetJurisdiction?.id),
      this.fetchMoreCards(this.targetJurisdiction?.id),
    ]);
  };
  @action initDashboardFetching = async (selectedJurisdictions: ItemType | null) => {
    await this.fetchDataBehavior(selectedJurisdictions);
  };

  @action fetchCharts = async () => {
    await this.fetchChartsOfEAR();
    //todo find a reason why without this call the chart is not displayed
    await this.fetchChartsOfCompliance();
  };

  @action fetchChartsOfEAR = async () => {
    await Promise.allSettled([
      this.fetchPolarChart(this.targetJurisdiction?.id),
      this.fetchEARChart(this.targetJurisdiction?.id),
    ]);

    runInAction(() => {
      if (this.earChartData) {
        this.stockChartOptions = new StockChart({
          ...this.earChartData,
          title: 'Included in a Compliance Review',
        },
          this.applyFilter,
          this.applyPointFilter,
          this.exportEarCallBack
        );
      }
      if (this.polarChartData) {
        this.pieChartOptions = new PieChart({
          ...this.polarChartData,
          title: 'Collection Systems',
        },
          this.applyFilter,
          this.getEarExportFile
        );
      }
    });
  };
  @action fetchChartsOfCompliance = async () => {
    this.multiChartOptions = undefined;
    await Promise.allSettled([
      this.fetchDoughnutChart(this.targetJurisdiction?.id),
      this.fetchSLCPChart(this.targetJurisdiction?.id),
    ]);

    runInAction(() => {
      if (this.slcpChartData) {
        this.multiChartOptions = new MultiStockChart({
          ...this.slcpChartData,
          title: 'Recycling Compliance',
        },
          this.applyFilter,
          this.applyPointFilter,
          this.exportComplianceCallBack,
        );
      }
      if (this.doughnutChartData) {
        this.polarChartOptions = new PolarChart({
          ...this.doughnutChartData,
          title: 'Generators NOT Compliant by Material Type',
        },
          this.applyFilter,
          this.getEarExportFile
        );
      }
    });
  };

  @action exportEarCallBack: ExportCallBackType = ({ type }) => {
    this.getEarExportFile({
      dashboard: [exportOptions[0]],
      type,
    });
  }

  @action exportComplianceCallBack: ExportCallBackType = ({ type }) => {
    this.getEarExportFile({
      dashboard: [exportOptions[3]],
      type
    });
  }

  @action applyFilter: ClickOnLegendType = async (id, chartTargetName, chartTitle, pointAmmount) => {
    this.filter.resetFilters();
    this.resetChartSelection();
    this.fetchLegendFilter(
      String(id),
      chartTargetName,
      chartTitle,
      pointAmmount,
    );
  };
  @action applyPointFilter: ClickOnPointType = async (id, chartTargetName, chartTitle, pointId, date) => {
    this.filter.resetFilters();
    this.resetChartSelection();
    this.filter.setFilterValue({
      'q.point.id': pointId ?? 'null',
    });

    this.fetchLegendFilter(
      String(id),
      chartTargetName,
      chartTitle,
      date,
    );
  };

  @action selectingJurisdictions = async (value: ItemType) => {
    this.resetAll();
    await this.setJurisdictions(value);
    await this.fetchCards();
    await this.fetchCharts();
    await this.fetchLocations();
  };

  @action selectingCards = (
    value: CardType['value'],
    title: CardType['title'],
    count?: string | number,
  ) => {
    this.filter.resetFilters();
    const label = !!count ? `${count} ${title}` : title;
    if (ignoreType(Number(value), [CardsType.RecoveryOS, CardsType.TotalPounds])) {
      this.filter.setFilterValue({
        'q.filter.id': value as string,
      });
      this.setCardFilterTitle(label);

      (async () => {
        await this.resetChartSelection();
        await this.fetchLocations();
      })();
    }
  };

  @action fetchPolarChart = async (id?: number) => {
    this.polarChartReq = this.diagramApi.getPieChart(id);
    await this.polarChartReq;
    return this.polarChartReq;
  };
  @action fetchDoughnutChart = async (id?: number) => {
    this.doughnutChartReq = this.diagramApi.getNonPieChart(id);
    await this.doughnutChartReq;
    return this.doughnutChartReq;
  };
  @action fetchBarChart = async (id?: number) => {
    this.barChartReq = this.diagramApi.getSubjectLineChart(id);
    await this.barChartReq;
    return this.barChartReq;
  };
  @action fetchMultiLineChart = async (id?: number) => {
    this.multiLineChartReq = this.diagramApi.getEARLineChart(id);
    await this.multiLineChartReq;
    return this.multiLineChartReq;
  };

  @action fetchEARChart = async (id?: number) => {
    this.earChartReq = this.diagramApi.getEARLineChart(id);
    await this.earChartReq;
    return this.earChartReq;
  };

  @action fetchSLCPChart = async (id?: number) => {
    this.slcpChartReq = this.diagramApi.getSLCPLineChart(id);
    await this.slcpChartReq;
    return this.slcpChartReq;
  };


  @action fetchLocations = async () => {
    this.generatorsReq = this.diagramApi.getEarGeneratorsData({
      ...this.pagination.serialize,
      filters: this.requestGeneratorsFilter,
    });
    await this.generatorsReq;

    runInAction(() => {
      if (
        this.generatorsReq.state !== 'fulfilled' ||
        this.generatorsReq.value?.data === null
      )
        return;
      this.generators.replace(
        this.generatorsData.map(generator => {
          return new Generator(generator);
        }),
      );
      this.pagination.setPagination(this.generatorsReq.value?.data);
    });
    return this.generatorsReq;
  };

  @action changeJurisdictions = async (page: number) => {
    runInAction(() => {
      this.pagination.setPagination({
        pagination: {
          num: page + 1,
          size: this.pagination.serialize.pageSize,
        },
      });
    });
    await this.fetchLocations();
  };

  @action removeTargetGenerator = () => this.chosenGenerator = undefined;

  @action setSelectedGenerators = (items: GridRowId[]) => {
    this.selectedGenerators = items;
  };

  @action getEarExportFile = async ({ dashboard, type }: ExportDashboardFormType) => {
    if (!dashboard || dashboard.length === 0) return;
    for (const { path, name } of dashboard) {
      this.dashboardExportEntity.setParams({
        fileName: name,
        type: type,
        mode: ExportTypes.ALL,
      });
      const exportParams = this.dashboardExportEntity.exportParams([], null, this.pagination, []);
      await this.dashboardExportEntity.asyncExportAction(
        this.diagramApi.getAsyncDashboardChartByType(
          path,
          type,
          exportParams,
        )
      )
      runInAction(() => {
        if (this.dashboardExportEntity.exportReq.state === 'fulfilled' && this.dashboardExportEntity.isExport) {
          this.dashboardExportEntity.resetParams();
        }
      });
    }
  };

  @action exportAction = async (data: ExportDataType) => {
    if (!data.type) return;
    if (Number(data.mode) === ExportTypes.ALL) this.exportEntity.setExportWithUnactiveRecords(true);
    this.exportEntity.setParams(data);

    const exportParams = this.exportEntity.exportParams<
      GeneratorsResponse, GeneratorsFilterType, GeneratorItem
    >(
      this.columnsManager.hiddenColumns,
      this.filter,
      this.pagination,
      this.selectedGenerators
    );
    const exportParamsToSend = {
      ...exportParams,
      q: {
        ...exportParams.q,
        ...pick(dotNameKeyToObject(this.filter.filterParams), ["filter", "point"])
      }
    };
    await this.exportEntity.asyncExportAction(
      this.diagramApi.getAsyncGeneratorsReportByType(
        exportParamsToSend,
        data.type
      )
    );
  };

  @action fetchLegendFilter: ClickOnLegendType = (filterId, legendTitle, chartTitle, pointAmmount, date) => {
    if (!legendTitle || !chartTitle) return;

    this.filter.setFilterValue({
      'q.filter.id': filterId,
    });
    this.setCardFilterTitle(generateFilterTitle(chartTitle, legendTitle, pointAmmount, date));
    (async () => await this.fetchLocations())();
  };

  @action resetStore = () => {
    this.pagination.resetPagination();
    this.filter.resetFilters();
    this.filter.setSearchOpen(false);
    this.removeSelectedGenerators();
  };


  @action resetChartEntity = () => {
    this.multiChartOptions = undefined;
    this.stockChartOptions = undefined;
    this.pieChartOptions = undefined;
    this.polarChartOptions = undefined;
  };

  @action resetCardFilterTitle = () => this.cardFilterTitle = undefined;
  @action removeSelectedGenerators = () => this.selectedGenerators = [];

  @action resetPieChartSelection = () => {
    if (this.pieChartOptions?.chartRef) {
      this.pieChartOptions.resetSelection();
    }
    if (this.polarChartOptions?.chartRef) {
      this.polarChartOptions.resetSelection();
    }
  };
  @action resetLineChartSelection = () => {
    if (this.stockChartOptions?.chartRef) {
      this.stockChartOptions.resetSelection();
    }
    if (this.multiChartOptions?.chartRef) {
      this.multiChartOptions.resetSelection();
    }
  };
  @action resetChartSelection = () => {
    this.resetPieChartSelection();
    this.resetLineChartSelection();
  };
  @action resetAllChart = () => {
    this.resetPieChartSelection();
    this.resetLineChartSelection();
    this.resetCardFilterTitle();
    this.resetChartEntity(); //position is important
  };
  @action resetAll = () => {
    this.resetAllChart();
    this.resetStore();
  };
}


export const { store: dashboardStore, storeCtx: dashboardStoreCtx } = storeFactory(
  DashboardStore,
  'dashboard',
);
