import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import {
  FilterFields,
  ServiceItem,
  ServicesResponse,
} from 'src/api/api-types/services';
import { ServicesApi } from 'src/api/services';
import { TableFilter } from 'src/models/filter/table-filter';
import { Service } from 'src/models/servise/service';
import { servicesColumns } from 'src/pages/Services/helpers/gridColumns';
import { defaultFilterField, ServicesVisibleModel } from 'src/pages/Services/helpers/common';
import { servicesFilterNameMap, ServicesExportColumnsNameMap } from 'src/models/filter/constants';
import { userStore } from '../../stores/user';
import { TablePagination } from '../pagination/pagination';
import { GridColumnsManager } from '../grid-columns-manager/grid-columns-manager';
import { AsyncExportEntity } from '../export/AsyncExportEntity';
import { GridRowId } from '@mui/x-data-grid-pro';
import { ExportDataType } from 'src/api/exportTypes';

export class ServicesModel {
  api = new ServicesApi({ prefix: '' });
  @observable filter: TableFilter<FilterFields, ServiceItem>;
  @observable selectedServices: Array<GridRowId> = [];
  @observable exportEntity: AsyncExportEntity = new AsyncExportEntity();
  @observable columnsManager;
  @observable servicesReq: ApiReq<ServicesResponse> = emptyValue;
  @observable.ref services: IObservableArray<Service> = observable([]);
  @observable pagination = new TablePagination<ServicesResponse>({
    requestRef: this.servicesReq,
  });

  constructor() {
    makeObservable(this);
    this.pagination.setFetchingCallBack(this.fetchServices);
    this.columnsManager = new GridColumnsManager<ServiceItem>({
      columns: this.columns,
      columnsMap: {},
      visibilityModel: this.columnsVisibleMap
    });

    this.filter = new TableFilter<FilterFields, ServiceItem>(
      servicesColumns,
      defaultFilterField,
      servicesFilterNameMap,
      (column, filters) => this.api.getServicesFilterOptions({ filters, column })
    );
  }

  @computed get columnsVisibleMap() {
    return {
      admin: ServicesVisibleModel,
      jurisdiction: ServicesVisibleModel,
      hauler: ServicesVisibleModel,
      agency: ServicesVisibleModel,
    };
  }

  @computed get columns() {
    return !userStore.isAdmin
      ? servicesColumns.filter(({ field }) => !field.includes('jurisdiction'))
      : servicesColumns;
  }

  @computed get servicesData() {
    if (this.servicesReq.state !== 'fulfilled' || !this.servicesReq.value) {
      return [];
    }
    return this.servicesReq.value.data?.items || [];
  }

  @action fetchServices = async () => {
    this.servicesReq = this.api.getServicesData({
      ...this.pagination.serialize,
      filters: this.filter.filterParams,
    });
    await this.servicesReq;

    runInAction(() => {
      if (
        this.servicesReq.state !== 'fulfilled' ||
        this.servicesReq.value?.data === null
      )
        return;
      this.services.replace(
        this.servicesData.map(service => {
          return new Service(service);
        }),
      );
      this.pagination.setPagination(this.servicesReq.value?.data);
    });
    return this.servicesReq;
  };

  @action setSelectedServices = (items: GridRowId[]) => {
    this.selectedServices = items;
  };

  @action exportAction = async (data: ExportDataType) => {
    if (!data.type) return;
    this.exportEntity.setParams(data);
    await this.exportEntity.asyncExportAction(
      this.api.getAsyncReportByType(
        this.exportEntity.exportParams<
          ServicesResponse,
          FilterFields,
          ServiceItem
        >(
          ServicesExportColumnsNameMap(this.columnsManager.hiddenColumns as IObservableArray<keyof ServiceItem>),
          this.filter,
          this.pagination,
          this.selectedServices
        ),
        data.type,
      )
    );
  };

  @action resetStore = () => {
    this.pagination.resetPagination();
    this.services.replace([]);
    this.filter.resetFilters();
    this.filter.setSearchOpen(false);
  };
}
