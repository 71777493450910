import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import {
  RecordCategoriesResponse,
  RecordPeriod,
} from 'src/api/api-types/implementation-record';
import { ImplementationRecordApi } from 'src/api/implementationRecord';
import { storeFactory } from '../../utils/store';
import { ApiReq, emptyValue } from '../../api';
import { Category } from './models/category';
import { fileStore } from './components/AssetsList/components/AddAttachments';
import { AssetsModel } from '../../models/assets/assets-model';
import { AsyncExportEntity } from 'src/models/export/AsyncExportEntity';
import { userStore } from 'src/stores/user';

class ImplementationRecordsStore {
  api = new ImplementationRecordApi();

  @observable isCollapseAll?: boolean = true;
  @observable isDeletionNumber?: number;
  @observable isEditNumber?: number;
  @observable drawerState?: 'edit' | 'assets' | 'selection' = undefined;
  @observable exportEntity: AsyncExportEntity = new AsyncExportEntity();
  @observable assets = new AssetsModel()

  @observable.ref recordsPeriodsReq: ApiReq<RecordPeriod[]> = emptyValue;
  @observable.ref recordsCategoriesReq: ApiReq<RecordCategoriesResponse> = emptyValue;

  @observable.ref targetCategory?: Category;
  @observable categories: IObservableArray<Category> = observable([]);

  @observable chosenPeriod?: RecordPeriod;


  constructor() {
    makeObservable(this);
  }

  @computed get recordsPeriods() {
    if (this.recordsPeriodsReq.state !== 'fulfilled' || !this.recordsPeriodsReq.value) {
      return [];
    }
    return this.recordsPeriodsReq.value.data || [];
  }

  @computed get categoriesData() {
    if (this.recordsCategoriesReq.state !== 'fulfilled' || !this.recordsCategoriesReq.value) {
      return [];
    }
    return this.recordsCategoriesReq.value.data || [];
  }

  @action fetchRecordPeriods = async () => {
    this.recordsPeriodsReq = this.api.getImplementationRecordPeriods()
    await this.recordsPeriodsReq
    runInAction(() => {
      this.chosenPeriod = this.recordsPeriods?.find(({ isDefault }) => isDefault)
    })
    return this.recordsPeriodsReq;
  }

  @action chooseRecordPeriod = async (period: RecordPeriod) => {
    this.chosenPeriod = period;
    this.resetDrawerState();
    this.fetchRecordCategories();
  }

  @action fetchRecordCategories = async () => {
    if (!this.chosenPeriod) return;
    this.recordsCategoriesReq = this.api.getImplementationRecordCategories(this.chosenPeriod?.id);
    await this.recordsCategoriesReq
    runInAction(() => {
      this.categories.replace(this.categoriesData.map(category => new Category(category)))
    })
    return this.recordsCategoriesReq
  };


  @action selectCategory = (id: number) => {
    this.targetCategory = this.categories.find(item => item.id === id);
  };

  @action selectIRdata = (categoryId: number, subCategoryId: number) => {
    this.selectCategory(categoryId);
    this.targetCategory?.selectSubCategory(subCategoryId);
  }

  @action editRecordNote = async (categoryId: number, subCategoryId: number) => {
    this.selectIRdata(categoryId, subCategoryId)
    await this.targetCategory?.chosenSubCategory?.fetchSubCategoryNote(subCategoryId)
    this.drawerState = 'edit';
  };

  @action showAssetsListAction = async (categoryId: number, subCategoryId: number) => {
    this.selectIRdata(categoryId, subCategoryId)
    await this.targetCategory?.chosenSubCategory?.getAssetsList()
    this.drawerState = 'assets';
  };

  @action showSelectionPreview = async (categoryId: number, subCategoryId: number, selectionId: number) => {
    this.selectIRdata(categoryId, subCategoryId)
    await this.targetCategory?.chosenSubCategory?.getSelectionDetail(selectionId)
    this.drawerState = 'selection';
  };

  @action collapseAll = (expand?: boolean) => {
    this.categories.map(category => {
      return expand ?
        category.expand() :
        category.collapse()
    })
  };

  @action resetAllCollapseState = () => this.isCollapseAll = undefined;

  @action callDeletion = (id?: number) => {
    this.isDeletionNumber = id;
  };

  @action editToggle = async (id?: number) => {
    if (!id) return this.isEditNumber = undefined;
    this.isEditNumber = id
  }

  @action deleteAction = async (isDelete?: boolean) => {
    if (isDelete && this.isDeletionNumber) {
      await this.targetCategory?.chosenSubCategory?.deleteAsset(this.isDeletionNumber);
      if (!this.targetCategory?.chosenSubCategory?.id) return;
      await this.targetCategory?.fetchSubCategoryDetails(this.targetCategory?.chosenSubCategory?.id)
    }
    this.isDeletionNumber = undefined;
  }

  @action resetDrawerState = () => {
    this.drawerState = undefined;
  };

  @action afterCreateAssetAction = async (id?: number) => {
    if (!id) return;
    await this.targetCategory?.chosenSubCategory?.getAssetsList();
    this.targetCategory?.chosenSubCategory?.selectAsset(id);
    if (!this.targetCategory?.chosenSubCategory?.id ||
      !this.targetCategory?.chosenSubCategory?.chosenAsset) return;
    await this.targetCategory?.fetchSubCategoryDetails(this.targetCategory?.chosenSubCategory?.id)
    fileStore.fileDialogStore.openDialog();
    fileStore.fileDialogStore.dependingToAsset(this.targetCategory?.chosenSubCategory?.chosenAsset);
  }

  @action exportImplementationRecord = async () => {
    if (!this.chosenPeriod) return;
    await this.exportEntity.asyncExportAction(
      this.api.exportImplementationRecord({
        period: { id: this.chosenPeriod.id },
        ...(userStore.userJurisdiction && { jurisdiction: { id: userStore.userJurisdiction.id } })
      })
    );
  };

}

export const {
  store: implementationRecordsStore,
  storeCtx: implementationRecordsStoreCtx,
} = storeFactory(ImplementationRecordsStore, 'ImplementationRecord');
