import { KeyboardEvent, PropsWithChildren } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { FormProvider, useForm } from 'react-hook-form';
import { Filter } from 'src/models/filter';

import Actions from './components/Actions';
import LazyDrawer from './components/LazyDrawer';
import { SearchProvider } from './context';

export interface SearchPanelProps<T> {
  filterCtx: Filter<T>;
  filterReq: () => Promise<unknown>;
}

const SearchPanel = <T extends object>({
  filterCtx,
  filterReq,
  children,
}: PropsWithChildren<SearchPanelProps<T>>) => {
  const { isSearchOpen, setSearchOpen, resetFilters } = filterCtx;

  const methods = useForm<Filter<T>>({
    defaultValues: filterCtx.filterFields,
  });

  const clear = async () => {
    await resetFilters();
    setSearchOpen(false);
    await filterReq();
    methods.reset();
  };

  const submit = async () => {
    setSearchOpen(false);
    await filterReq();
  };

  const back = () => setSearchOpen(false);

  const enterPressHandler = (event: KeyboardEvent<HTMLInputElement>) =>
    event.key === 'Enter' && submit();

  return (
    <LazyDrawer
      lazyTrigger={isSearchOpen}
      variant="persistent"
      anchor="right"
      elevation={5}
      onClose={back}
      onKeyPress={enterPressHandler}
      PaperProps={{
        style: {
          top: '82px',
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          height: 'auto'
        },
      }}>
      <FormProvider 
        {...methods}
      >
        <AppBar
          position="static"
          sx={{
            minWidth: '400px',
            width: '100%',
            color: theme => theme.palette.text.primary,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <Typography variant="h5">Search Panel</Typography>
          <CloseIcon onClick={back} sx={{ cursor: 'pointer' }} />
        </AppBar>
        <div
          style={{
            padding: 16,
            flex: 1,
            paddingBottom: 100,
            overflowY: 'auto',
          }}
          data-cy="search-panel-fields-wrapper"
        >
          <SearchProvider<T> filterCtx={filterCtx}>{children}</SearchProvider>
        </div>
        <Divider />
        <Actions clear={clear} submit={submit} />
      </FormProvider>
    </LazyDrawer>
  );
};

export default observer(SearchPanel);
