import { ApiClient } from './';
import {
  AccountLookup,
  CategoriesLookup,
  CategoriesLookupReq,
  FoodRecoveryTypesReq,
  GeneratorAddresses,
  MergedLookup,
  SubCategoriesLookupReq,
  TypesLookupReq,
} from './api-types/lookups';
import { ItemDescType, ItemType } from '../types';
import { EFGTypesMeta } from './api-types/food-generators';
import { AddressLookup } from './api-types/generators';

export class LookupsApi extends ApiClient {
  getAssetTypes() {
    return this.requestGet<ItemType[]>('activities/types');
  }

  getAccountTypes() {
    return this.requestGet<ItemType[]>('generators/types');
  }

  getLocationTypes() {
    return this.requestGet<ItemType[]>('locations/types');
  }

  getAccountsDependingToEFG(id: number | string, isEfg: boolean = false) {
    return this.requestGet<ItemType[]>(`generators/lookups`, {
      'q.location.id': id,
      'q.isEfg': isEfg,
    });
  }

  getAssetCategories({ type }: CategoriesLookupReq) {
    return this.requestGet<CategoriesLookup[]>('activities/categories', {
      'q.type.id': type,
    });
  }

  getAssetSubCategories({ id, typeId }: SubCategoriesLookupReq) {
    return this.requestGet<CategoriesLookup[]>(
      `activities/categories/${id}/sub-categories/lookups`,
      {
        'q.category.id': typeId,
      },
    );
  }

  getAssetsWaiverStatus() {
    return this.requestGet<ItemType[]>('activities/waiver-statuses/lookups', {
      limit: 100,
    });
  }

  getRelatedTypes() {
    return this.requestGet<ItemType[]>('activities/related-entities/types');
  }

  getRelatedLookups({ type, name }: TypesLookupReq) {
    return this.requestGet<ItemType[]>('activities/related-entities/lookups', {
      type,
      ...(!!name && { name: name }),
      limit: 100,
    });
  }

  getFoodRecoveriesTypes({ name }: FoodRecoveryTypesReq) {
    return this.requestGet<ItemDescType[]>('food-recoveries/types', {
      ...(!!name && { name }),
    });
  }

  getFoodWillingTypeLookups({ name }: FoodRecoveryTypesReq) {
    return this.requestGet<ItemType[]>('food-recoveries/food-willing-types', {
      ...(!!name && { name }),
    });
  }

  getAvailableAccountsLookups({ name }: FoodRecoveryTypesReq) {
    return this.requestGet<ItemType[]>('generators/available-parents', {
      'q.accountNumber': !!name ? name : null,
    });
  }

  getAddressLookups(address?: string) {
    return this.requestGeneratorsAddresses<GeneratorAddresses>(
      'AddressLookup/autocomplete',
      {
        ...(!!address && { address }),
      },
    );
  }

  getClientTypes() {
    return this.requestGet<ItemType[]>('clients/types');
  }

  getJurisdictions(name?: string, withBlank?: boolean) {
    return this.requestGet<ItemType[]>('clients/jurisdictions/lookups', {
      ...(!!name && { name }),
      withBlank,
    });
  }

  getAllJurisdictions() {
    return this.requestGet<ItemType[]>(
      'clients/jurisdictions/all',
      {},
      { headers: this.headersProps },
    );
  }

  getClientStates() {
    return this.requestGet<ItemType[]>('clients/states');
  }

  getClients(type: number) {
    return this.requestGet<ItemType[]>('clients/lookups', {
      'type.id': type,
    });
  }

  getClientsHaulers(name?: string) {
    return this.requestGet<ItemType[]>('clients/haulers/lookups', {
      'q.name': !!name ? name : null,
      limit: 100,
    });
  }

  getAllHaulers(name?: string) {
    return this.requestGet<ItemType[]>('clients/haulers/lookups/all', {
      name: !!name ? name : null,
    });
  }

  getGeneratorsLookups(name?: string) {
    return this.requestGet<ItemType[]>('locations/lookups', {
      'q.name': !!name ? name : null,
      limit: 100,
    });
  }

  getAccountsLookups(name?: string, locationId?: number | null) {
    return this.requestGet<AccountLookup[]>('generators/lookups', {
      'q.accountNumber': !!name ? name : null,
      'q.location.id': !!locationId ? locationId : null,
      limit: 100,
    });
  }

  getTiersLookups(name?: string) {
    return this.requestGet<ItemType[]>('edible-food-generators/tiers', {
      ...(!!name && { name }),
    });
  }

  getEdibleFoodGeneratorsTypes(name?: string) {
    return this.requestGet<EFGTypesMeta[]>('edible-food-generators/types', {
      ...(!!name && { name }),
    });
  }

  getFoodRecoveriesLookups(
    name?: string,
    id?: number | string,
    jurisdictionId?: number | string,
  ) {
    return this.requestGet<ItemType[]>('food-recoveries/lookups', {
      ...(!!name && { name }),
      ...(!!id && { 'type.id': id }),
      ...(!!jurisdictionId && { 'jurisdiction.id': jurisdictionId }),
    });
  }

  getWasteMaterialTypes(name?: string) {
    return this.requestGet<ItemType[]>('waste-material-types/lookups', {
      ...(!!name && { name }),
    });
  }

  getJurisdictionZones(name?: string) {
    return this.requestGet<ItemType[]>('locations/zones', {
      ...(!!name && { name }),
    });
  }

  getUsers(name?: string) {
    return this.requestGet<ItemType[]>('users/lookups', {
      ...(!!name && { name }),
    });
  }

  getCollectionSystems() {
    return this.requestGet<ItemType[]>('compliance-matrix/collection-systems');
  }

  getExtensionField(
    fieldNumber: number,
    jurisdictionId: number,
    name?: string,
  ) {
    return this.requestGet<ItemType[]>(
      `generators/extension-fields-${fieldNumber}`,
      {
        jurisdictionId: jurisdictionId,
        ...(!!name && { name }),
      },
    );
  }

  getContactTypes(name?: string) {
    return this.requestGet<ItemType[]>('contacts/types/lookups', {
      ...(!!name && { name }),
    });
  }

  getGeneratorsAddresses(name?: string, jurisdictionId?: number) {
    return this.requestGet<AddressLookup[]>('locations/lookups/with-address', {
      ...(!!name && { 'q.name': name }),
      ...(!!jurisdictionId && { 'q.jurisdiction.id': jurisdictionId }),
    });
  }

  getSearchGeneratorsAddresses(generatorId: string | number, value: any) {
    return this.requestGet<AddressLookup[]>(
      `generators/${generatorId}/locations`,
      {
        ...value,
      },
    );
  }

  getAgenciesLookup(name?: string, accessType?: string, withBlank?: boolean) {
    return this.requestGet<ItemType[]>('clients/agencies/lookups', {
      ...(!!name && { name }),
      ...(!!accessType && { accessType }),
      withBlank,
    });
  }

  getContactRelatedTypes() {
    return this.requestGet<ItemType[]>('contacts/related-entities/types');
  }

  getUnitOfMeasure() {
    return this.requestGet<ItemType[]>('services/units-of-measure');
  }

  getServiceWasteType() {
    return this.requestGet<ItemType[]>('services/waste-types');
  }

  getMergedSubCategories(withOutBlank?: boolean) {
    return this.requestGet<MergedLookup[]>(
      'activities/sub-categories/lookups/merged',
      {
        withBlank: withOutBlank ? false : true,
      },
    );
  }

  getMergedZones(withOutBlank?: boolean) {
    return this.requestGet<MergedLookup[]>('locations/zones/lookups/merged', {
      withBlank: withOutBlank ? false : true,
    });
  }

  getMergedExtensionField1(withOutBlank?: boolean) {
    return this.requestGet<MergedLookup[]>(
      'generators/extension-fields-1/lookups/merged',
      {
        withBlank: withOutBlank ? false : true,
      },
    );
  }

  getMergedExtensionField2(withOutBlank?: boolean) {
    return this.requestGet<MergedLookup[]>(
      'generators/extension-fields-2/lookups/merged',
      {
        withBlank: withOutBlank ? false : true,
      },
    );
  }
}
