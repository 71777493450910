import { Box, styled } from '@mui/material';
import { CSSProperties } from '@mui/styled-engine';
import {
  generateActivityTypeColors,
  generateColoredBoxItem,
  generateSystemTypeColors,
} from 'src/utils/colors';

import { authStore } from '../../stores/auth';

export enum ColorFlowTypes {
  ACCOUNT = 'account',
  ASSET = 'asset',
  COLORED = 'colored',
  ACTIVITY = 'activity',
}

interface ColoredItemProps {
  id?: number;
  name?: string;
  flow?: `${ColorFlowTypes}`;
}

const Container = styled(Box)<{ indexing: number }>(({ theme }) => ({
  textTransform: 'capitalize',
  borderRadius: 4,
  border: '1px solid',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: theme.spacing(0.25, 1),
  ...(theme.typography.hint as CSSProperties),
}));

export const ColoredItemType = ({
  id,
  name,
  flow = ColorFlowTypes.COLORED,
}: ColoredItemProps) => {
  const colorSchema = {
    colored: generateColoredBoxItem,
    asset:
      authStore.fCfg.isWaiverModule || authStore.fCfg.isCommunicationModule
        ? generateActivityTypeColors
        : generateSystemTypeColors,
    account: generateActivityTypeColors,
    activity: generateActivityTypeColors,
  };
  if (!id || !name) return null;

  return (
    <Container
      sx={{
        ...colorSchema[flow](id, name),
      }}
      indexing={id}>
      {name}
    </Container>
  );
};

export default ColoredItemType;
