import { action, computed, makeObservable, observable } from 'mobx';
import { storeFactory } from '../utils/store';
import { LookupsApi } from '../api/lookups';
import { ApiReq, emptyValue } from 'src/api';
import {
  AccountLookup,
  CategoriesLookup,
  GeneratorAddresses,
  MergedLookup,
} from 'src/api/api-types/lookups';
import { ItemDescType, ItemType } from '../types';
import { EFGTypesMeta } from '../api/api-types/food-generators';
import { AddressLookup } from '../api/api-types/generators';
import { isPending } from '../utils/common';

export class Lookups {
  api = new LookupsApi();

  @observable.ref clientTypesReq: ApiReq<ItemType[] | CategoriesLookup[]> =
    emptyValue;

  @observable.ref clientsReq: ApiReq<ItemType[]> = emptyValue;

  @observable.ref categoryReq: ApiReq<CategoriesLookup[]> = emptyValue;
  @observable.ref subCategoryReq: ApiReq<CategoriesLookup[]> = emptyValue;
  @observable.ref assetsLookupReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref assetsWaiverStatusLookupReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref accountTypeLookupReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref locationTypeLookupReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref accountIsNotEFGReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref accountWithEFGReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref relatedTypesReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref relatedLookupsReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref clientStatesReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref generatorsLookupsReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref haulersLookupsReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref haulersAllLookupsReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref accountsLookupsReq: ApiReq<AccountLookup[]> = emptyValue;
  @observable.ref foodRecoveriesTypesReq: ApiReq<ItemDescType[]> = emptyValue;
  @observable.ref foodWillingTypesReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref availableAccountsReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref addressLookupsReq: ApiReq<GeneratorAddresses> = emptyValue;
  @observable.ref tiersLookupsReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref foodRecoveriesReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref edibleFoodGeneratorsTypesReq: ApiReq<EFGTypesMeta[]> =
    emptyValue;
  @observable.ref wasteMaterialTypesReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref jurisdictionsReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref allJurisdictionsReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref jurisdictionZonesReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref extensionFieldReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref usersReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref collectionSystemsReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref contactTypesReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref contactRelatedTypesReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref unitsOfMeasureReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref serviceWasteTypeReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref agenciesLookupReq: ApiReq<ItemType[]> = emptyValue;
  @observable.ref mergedSubCategoriesReq: ApiReq<MergedLookup[]> = emptyValue;
  @observable.ref mergedZonesReq: ApiReq<MergedLookup[]> = emptyValue;
  @observable.ref mergedExtensionFields1Req: ApiReq<MergedLookup[]> =
    emptyValue;
  @observable.ref mergedExtensionFields2Req: ApiReq<MergedLookup[]> =
    emptyValue;
  @observable.ref generatorAddressesReq: ApiReq<AddressLookup[]> = emptyValue;
  @observable.ref generatorsReq: Array<ItemType> = [];

  constructor() {
    makeObservable(this);
  }

  @computed get clientTypes(): Array<ItemType> {
    if (this.clientTypesReq.state !== 'fulfilled' || !this.clientTypesReq.value)
      return [];

    return this.clientTypesReq.value.data || [];
  }

  @computed get generators(): Array<ItemType> {
    return this.generatorsReq || [];
  }

  @computed get clientStates(): Array<ItemType> {
    if (
      this.clientStatesReq.state !== 'fulfilled' ||
      !this.clientStatesReq.value
    )
      return [];

    return this.clientStatesReq.value.data || [];
  }

  @computed get assetTypes() {
    if (
      this.assetsLookupReq.state !== 'fulfilled' ||
      !this.assetsLookupReq.value
    )
      return [];

    return this.assetsLookupReq.value.data || [];
  }

  @computed get assetsWaiverStatus() {
    if (
      this.assetsWaiverStatusLookupReq.state !== 'fulfilled' ||
      !this.assetsWaiverStatusLookupReq.value
    )
      return [];

    return this.assetsWaiverStatusLookupReq.value.data || [];
  }

  @computed get accountTypes() {
    if (
      this.accountTypeLookupReq.state !== 'fulfilled' ||
      !this.accountTypeLookupReq.value
    )
      return [];

    return this.accountTypeLookupReq.value.data || [];
  }

  @computed get locationTypes() {
    if (
      this.locationTypeLookupReq.state !== 'fulfilled' ||
      !this.locationTypeLookupReq.value
    )
      return [];

    return this.locationTypeLookupReq.value.data || [];
  }

  @computed get accountsIsNotEfg() {
    if (
      this.accountIsNotEFGReq.state !== 'fulfilled' ||
      !this.accountIsNotEFGReq.value
    )
      return [];

    return this.accountIsNotEFGReq.value.data || [];
  }

  @computed get accountsWithEfg() {
    if (
      this.accountWithEFGReq.state !== 'fulfilled' ||
      !this.accountWithEFGReq.value
    )
      return [];

    return this.accountWithEFGReq.value.data || [];
  }

  @computed get accountsInitCallPending() {
    return (
      isPending(this.jurisdictionsReq) ||
      isPending(this.haulersLookupsReq) ||
      isPending(this.agenciesLookupReq) ||
      isPending(this.accountTypeLookupReq) ||
      isPending(this.collectionSystemsReq) ||
      isPending(this.mergedZonesReq) ||
      isPending(this.mergedExtensionFields1Req) ||
      isPending(this.mergedExtensionFields2Req)
    );
  }

  @computed get generatorsInitCallPending() {
    return (
      isPending(this.jurisdictionsReq) ||
      isPending(this.haulersLookupsReq) ||
      isPending(this.agenciesLookupReq) ||
      isPending(this.accountTypeLookupReq) ||
      isPending(this.collectionSystemsReq)
    );
  }

  @computed get assetCategories() {
    if (this.categoryReq.state !== 'fulfilled' || !this.categoryReq.value)
      return [];

    return this.categoryReq.value.data || [];
  }

  @computed get assetSubCategories() {
    if (this.subCategoryReq.state !== 'fulfilled' || !this.subCategoryReq.value)
      return [];

    return this.subCategoryReq.value.data || [];
  }

  @computed get relatedTypes() {
    if (
      this.relatedTypesReq.state !== 'fulfilled' ||
      !this.relatedTypesReq.value
    )
      return [];

    return this.relatedTypesReq.value.data || [];
  }

  @computed get relatedLookups() {
    if (
      this.relatedLookupsReq.state !== 'fulfilled' ||
      !this.relatedLookupsReq.value
    )
      return [];

    return this.relatedLookupsReq.value.data || [];
  }

  @computed get generatorsLookups() {
    if (
      this.generatorsLookupsReq.state !== 'fulfilled' ||
      !this.generatorsLookupsReq.value
    )
      return [];

    return this.generatorsLookupsReq.value.data || [];
  }

  @computed get haulersLookups() {
    if (
      this.haulersLookupsReq.state !== 'fulfilled' ||
      !this.haulersLookupsReq.value
    )
      return [];

    return this.haulersLookupsReq.value.data || [];
  }

  @computed get haulersAllLookups() {
    if (
      this.haulersAllLookupsReq.state !== 'fulfilled' ||
      !this.haulersAllLookupsReq.value
    )
      return [];

    return this.haulersAllLookupsReq.value.data || [];
  }

  @computed get accountsLookups() {
    if (
      this.accountsLookupsReq.state !== 'fulfilled' ||
      !this.accountsLookupsReq.value
    )
      return [];

    return this.accountsLookupsReq.value.data || [];
  }

  @computed get foodRecoveriesTypes() {
    if (
      this.foodRecoveriesTypesReq.state !== 'fulfilled' ||
      !this.foodRecoveriesTypesReq.value
    )
      return [];

    return this.foodRecoveriesTypesReq.value.data || [];
  }

  @computed get availableAccounts() {
    if (
      this.availableAccountsReq.state !== 'fulfilled' ||
      !this.availableAccountsReq.value
    )
      return [];

    return this.availableAccountsReq.value.data || [];
  }

  @computed get addressLookups() {
    if (
      this.addressLookupsReq.state !== 'fulfilled' ||
      !this.addressLookupsReq.value
    )
      return [];

    return this.addressLookupsReq.value.data?.addresses || [];
  }

  @computed get tiersLookups() {
    if (
      this.tiersLookupsReq.state !== 'fulfilled' ||
      !this.tiersLookupsReq.value
    )
      return [];
    return this.tiersLookupsReq.value.data || [];
  }

  @computed get edibleFoodGeneratorsTypes() {
    if (
      this.edibleFoodGeneratorsTypesReq.state !== 'fulfilled' ||
      !this.edibleFoodGeneratorsTypesReq.value
    )
      return [];
    return this.edibleFoodGeneratorsTypesReq.value.data || [];
  }

  @computed get foodRecoveriesLookups() {
    if (
      this.foodRecoveriesReq.state !== 'fulfilled' ||
      !this.foodRecoveriesReq.value
    )
      return [];

    return this.foodRecoveriesReq.value.data || [];
  }

  @computed get jurisdictionZones() {
    if (
      this.jurisdictionZonesReq.state !== 'fulfilled' ||
      !this.jurisdictionZonesReq.value
    )
      return [];

    return this.jurisdictionZonesReq.value.data || [];
  }

  @computed get extensionFields() {
    if (
      this.extensionFieldReq.state !== 'fulfilled' ||
      !this.extensionFieldReq.value
    )
      return [];

    return this.extensionFieldReq.value.data || [];
  }

  @computed get usersLookups() {
    if (this.usersReq.state !== 'fulfilled' || !this.usersReq.value) return [];

    return this.usersReq.value.data || [];
  }

  @computed get contactTypesLookup() {
    if (
      this.contactTypesReq.state !== 'fulfilled' ||
      !this.contactTypesReq.value
    )
      return [];

    return this.contactTypesReq.value.data || [];
  }

  @computed get agenciesLookup() {
    if (
      this.agenciesLookupReq.state !== 'fulfilled' ||
      !this.agenciesLookupReq.value
    )
      return [];

    return this.agenciesLookupReq.value.data || [];
  }

  @action resetRelatedLookups = async () => {
    this.relatedLookupsReq = emptyValue;
  };

  @action fetchClientTypes = async () => {
    this.clientTypesReq = this.api.getClientTypes();
    await this.clientTypesReq;
    return this.clientTypesReq;
  };

  @action fetchClientStates = async () => {
    this.clientStatesReq = this.api.getClientStates();
    await this.clientStatesReq;
    return this.clientStatesReq;
  };

  @computed get clients(): Array<ItemType> {
    if (this.clientsReq.state !== 'fulfilled' || !this.clientsReq.value)
      return [];

    return this.clientsReq.value.data || [];
  }

  @computed get jurisdictions(): Array<ItemType> {
    if (
      this.jurisdictionsReq.state !== 'fulfilled' ||
      !this.jurisdictionsReq.value
    )
      return [];

    return this.jurisdictionsReq.value.data || [];
  }

  @computed get allJurisdictions(): Array<ItemType> {
    if (
      this.allJurisdictionsReq.state !== 'fulfilled' ||
      !this.allJurisdictionsReq.value
    )
      return [];

    return this.allJurisdictionsReq.value.data || [];
  }

  @computed get onlyOneJurisdiction(): boolean {
    return this.allJurisdictions.length === 1;
  }

  @computed get foodWillingTypes(): Array<ItemType> {
    if (
      this.foodWillingTypesReq.state !== 'fulfilled' ||
      !this.foodWillingTypesReq.value
    )
      return [];

    return this.foodWillingTypesReq.value.data || [];
  }

  @computed get wasteMaterialTypes(): Array<ItemType> {
    if (
      this.wasteMaterialTypesReq.state !== 'fulfilled' ||
      !this.wasteMaterialTypesReq.value
    )
      return [];

    return this.wasteMaterialTypesReq.value.data || [];
  }

  @computed get collectionSystemsLookup(): Array<ItemType> {
    if (
      this.collectionSystemsReq.state !== 'fulfilled' ||
      !this.collectionSystemsReq.value
    )
      return [];

    return this.collectionSystemsReq.value.data || [];
  }

  @computed get contactRelatedTypes(): Array<ItemType> {
    if (
      this.contactRelatedTypesReq.state !== 'fulfilled' ||
      !this.contactRelatedTypesReq.value
    )
      return [];

    return this.contactRelatedTypesReq.value.data || [];
  }

  @computed get unitsOfMeasure(): Array<ItemType> {
    if (
      this.unitsOfMeasureReq.state !== 'fulfilled' ||
      !this.unitsOfMeasureReq.value
    )
      return [];

    return this.unitsOfMeasureReq.value.data || [];
  }

  @computed get serviceWasteTypes(): Array<ItemType> {
    if (
      this.serviceWasteTypeReq.state !== 'fulfilled' ||
      !this.serviceWasteTypeReq.value
    )
      return [];

    return this.serviceWasteTypeReq.value.data || [];
  }

  @computed get mergedSubCategories(): Array<MergedLookup> {
    if (
      this.mergedSubCategoriesReq.state !== 'fulfilled' ||
      !this.mergedSubCategoriesReq.value
    )
      return [];

    return this.mergedSubCategoriesReq.value.data || [];
  }

  @computed get mergedZones(): Array<MergedLookup> {
    if (this.mergedZonesReq.state !== 'fulfilled' || !this.mergedZonesReq.value)
      return [];

    return this.mergedZonesReq.value.data || [];
  }

  @computed get mergedExtensionFields1(): Array<MergedLookup> {
    if (
      this.mergedExtensionFields1Req.state !== 'fulfilled' ||
      !this.mergedExtensionFields1Req.value
    )
      return [];

    return this.mergedExtensionFields1Req.value.data || [];
  }

  @computed get mergedExtensionFields2(): Array<MergedLookup> {
    if (
      this.mergedExtensionFields2Req.state !== 'fulfilled' ||
      !this.mergedExtensionFields2Req.value
    )
      return [];

    return this.mergedExtensionFields2Req.value.data || [];
  }

  @computed get generatorsAddresses(): Array<AddressLookup> {
    if (
      this.generatorAddressesReq.state !== 'fulfilled' ||
      !this.generatorAddressesReq.value
    )
      return [];

    return this.generatorAddressesReq.value.data || [];
  }

  @action fetchClients = async (type: number) => {
    this.clientsReq = this.api.getClients(type);
    await this.clientsReq;
    return this.clientsReq;
  };

  @action fetchHaulersLookup = async (name?: string) => {
    this.haulersLookupsReq = this.api.getClientsHaulers(name);
    return this.haulersLookupsReq;
  };

  @action fetchAllHaulersLookup = async (name?: string) => {
    this.haulersAllLookupsReq = this.api.getAllHaulers(name);
    return this.haulersAllLookupsReq;
  };

  @action resetClients = async () => {
    this.clientsReq = emptyValue;
  };
  @action resetSubCategoryReq = () => {
    this.subCategoryReq = emptyValue;
  };
  @action resetCategoryReq = () => {
    this.categoryReq = emptyValue;
  };
  @action resetJurisdictions = () => {
    this.jurisdictionsReq = emptyValue;
  };
  @action fetchAssetTypes = async () => {
    this.assetsLookupReq = this.api.getAssetTypes();
    await this.assetsLookupReq;
    return this.assetsLookupReq;
  };

  @action fetchAssetsWaiverStatus = async () => {
    this.assetsWaiverStatusLookupReq = this.api.getAssetsWaiverStatus();
    await this.assetsWaiverStatusLookupReq;
    return this.assetsWaiverStatusLookupReq;
  };

  @action fetchAccountTypes = async () => {
    this.accountTypeLookupReq = this.api.getAccountTypes();

    return this.accountTypeLookupReq;
  };

  @action fetchLocationTypes = async () => {
    this.locationTypeLookupReq = this.api.getLocationTypes();

    return this.locationTypeLookupReq;
  };

  @action fetchAccountsIsNotEFG = async (id: number | string) => {
    this.accountIsNotEFGReq = this.api.getAccountsDependingToEFG(id);

    return this.accountIsNotEFGReq;
  };

  @action fetchAccountsWithEFG = async (id: number | string) => {
    this.accountWithEFGReq = this.api.getAccountsDependingToEFG(id, true);

    return this.accountWithEFGReq;
  };

  @action fetchAssetCategories = async (typeId?: number) => {
    this.categoryReq = this.api.getAssetCategories({ type: typeId });
    await this.categoryReq;
    return this.categoryReq;
  };

  @action fetchAssetSubCategories = async (categoryId: number) => {
    this.subCategoryReq = this.api.getAssetSubCategories({ id: categoryId });
    await this.subCategoryReq;
    return this.subCategoryReq;
  };

  @action fetchGeneratorsLookups = async (name?: string) => {
    this.generatorsLookupsReq = this.api.getGeneratorsLookups(name);

    return this.generatorsLookupsReq;
  };

  @action fetchRelatedType = async () => {
    this.relatedTypesReq = this.api.getRelatedTypes();
    await this.relatedTypesReq;
    return this.relatedTypesReq;
  };

  @action fetchRelatedLookups = async (
    relatedTypeId: number,
    name?: string,
  ) => {
    this.relatedLookupsReq = this.api.getRelatedLookups({
      type: relatedTypeId,
      name,
    });
    await this.relatedLookupsReq;
    return this.relatedLookupsReq;
  };

  @action fetchJurisdictionsLookups = async (
    name?: string,
    withBlank?: boolean,
  ) => {
    this.jurisdictionsReq = this.api.getJurisdictions(name, withBlank);
    return this.jurisdictionsReq;
  };

  @action fetchAllJurisdictionsLookups = async () => {
    this.allJurisdictionsReq = this.api.getAllJurisdictions();
    return this.allJurisdictionsReq;
  };

  @action fetchAccountsLookups = async (
    name?: string,
    locationId?: number | null,
  ) => {
    this.accountsLookupsReq = this.api.getAccountsLookups(name, locationId);
    await this.accountsLookupsReq;
    return this.accountsLookupsReq;
  };

  @action fetchFoodType = async (name?: string) => {
    this.foodRecoveriesTypesReq = this.api.getFoodRecoveriesTypes({ name });

    await this.foodRecoveriesTypesReq;
    return this.foodRecoveriesTypesReq;
  };

  @action fetchFoodWillingType = async (name?: string) => {
    this.foodWillingTypesReq = this.api.getFoodWillingTypeLookups({ name });

    await this.foodWillingTypesReq;
    return this.foodWillingTypesReq;
  };

  @action fetchAvailableAccounts = async (name?: string) => {
    this.availableAccountsReq = this.api.getAvailableAccountsLookups({
      name,
    });

    await this.availableAccountsReq;
    return this.availableAccountsReq;
  };

  @action fetchTiersLookups = async (name?: string) => {
    this.tiersLookupsReq = this.api.getTiersLookups(name);

    await this.tiersLookupsReq;
    return this.tiersLookupsReq;
  };

  @action fetchEdibleFoodGeneratorsTypes = async (name?: string) => {
    this.edibleFoodGeneratorsTypesReq =
      this.api.getEdibleFoodGeneratorsTypes(name);

    await this.edibleFoodGeneratorsTypesReq;
    return this.edibleFoodGeneratorsTypesReq;
  };

  @action fetchFoodRecoveriesLookups = async (
    name?: string,
    id?: number | string,
    jurisdictionId?: number | string,
  ) => {
    this.foodRecoveriesReq = this.api.getFoodRecoveriesLookups(
      name,
      id,
      jurisdictionId,
    );

    await this.foodRecoveriesReq;
    return this.foodRecoveriesReq;
  };

  @action fetchAddressLookups = async (address?: string) => {
    this.addressLookupsReq = this.api.getAddressLookups(address);

    await this.addressLookupsReq;
    return this.addressLookupsReq;
  };

  @action fetchWasteMaterialTypes = async (name?: string) => {
    this.wasteMaterialTypesReq = this.api.getWasteMaterialTypes(name);
    await this.wasteMaterialTypesReq;
    return this.wasteMaterialTypesReq;
  };

  @action fetchJurisdictionZones = async (name?: string) => {
    this.jurisdictionZonesReq = this.api.getJurisdictionZones(name);
    await this.jurisdictionZonesReq;
    return this.jurisdictionZonesReq;
  };

  @action fetchUsersLookup = async (name?: string) => {
    this.usersReq = this.api.getUsers(name);
    await this.usersReq;
    return this.usersReq;
  };

  @action fetchCollectionSystems = async () => {
    if (
      this.collectionSystemsLookup.length !== 0 ||
      this.collectionSystemsReq.state === 'pending'
    ) {
      return;
    }
    this.collectionSystemsReq = this.api.getCollectionSystems();

    return this.collectionSystemsReq;
  };

  @action fetchExtensionField = async (
    fieldNumber: number,
    jurisdictionId: number,
    name?: string,
  ) => {
    this.extensionFieldReq = this.api.getExtensionField(
      fieldNumber,
      jurisdictionId,
      name,
    );
    await this.extensionFieldReq;
    return this.extensionFieldReq;
  };

  @action fetchContactTypes = async (name?: string) => {
    this.contactTypesReq = this.api.getContactTypes(name);
    await this.contactTypesReq;
    return this.contactTypesReq;
  };

  @action fetchGeneratorsAddresses = async (
    name?: string,
    jurisdictionId?: number,
  ) => {
    this.generatorAddressesReq = this.api.getGeneratorsAddresses(
      name,
      jurisdictionId,
    );
    await this.generatorAddressesReq;
    return this.generatorAddressesReq;
  };

  @action searchGeneratorsAddresses = async (
    generatorId: string | number,
    value: any,
  ) => {
    this.generatorAddressesReq = this.api.getSearchGeneratorsAddresses(
      generatorId,
      value,
    );
    await this.generatorAddressesReq;
    return this.generatorAddressesReq;
  };

  @action fetchAgenciesLookup = async (name?: string, accessType?: string) => {
    this.agenciesLookupReq = this.api.getAgenciesLookup(name, accessType);
    await this.agenciesLookupReq;
    return this.agenciesLookupReq;
  };

  @action fetchAgenciesLookupWithBlank = async (
    name?: string,
    accessType?: string,
  ) => {
    this.agenciesLookupReq = this.api.getAgenciesLookup(name, accessType, true);
    await this.agenciesLookupReq;
    return this.agenciesLookupReq;
  };

  @action fetchContactRelatedTypes = async () => {
    this.contactRelatedTypesReq = this.api.getContactRelatedTypes();
    await this.contactRelatedTypesReq;
    return this.contactRelatedTypesReq;
  };

  @action fetchUnitOfMeasure = async () => {
    this.unitsOfMeasureReq = this.api.getUnitOfMeasure();
    await this.unitsOfMeasureReq;
    return this.unitsOfMeasureReq;
  };

  @action fetchServiceWasteType = async () => {
    this.serviceWasteTypeReq = this.api.getServiceWasteType();
    await this.serviceWasteTypeReq;
    return this.serviceWasteTypeReq;
  };

  @action fetchMergedSubCategories = async () => {
    this.mergedSubCategoriesReq = this.api.getMergedSubCategories();
    await this.mergedSubCategoriesReq;
    return this.mergedSubCategoriesReq;
  };

  @action fetchMergedZones = async () => {
    this.mergedZonesReq = this.api.getMergedZones();
    await this.mergedZonesReq;
    return this.mergedZonesReq;
  };

  @action fetchMergedExtensionField1 = async () => {
    this.mergedExtensionFields1Req = this.api.getMergedExtensionField1();
    await this.mergedExtensionFields1Req;
    return this.mergedExtensionFields1Req;
  };

  @action fetchMergedExtensionField2 = async () => {
    this.mergedExtensionFields2Req = this.api.getMergedExtensionField2();
    await this.mergedExtensionFields2Req;
    return this.mergedExtensionFields2Req;
  };
}

export const { store: lookupsStore, storeCtx: lookupsStoreCtx } = storeFactory(
  Lookups,
  'lookups',
);
