import { FoodsApi } from '../../api/foods';
import { FullDescriptionResponse, ItemType } from '../../types';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import {
  EfrDetailsType,
  FoodDetailsResponse,
  FoodRecoveryItem,
} from '../../api/api-types/foods';
import { ApiReq, emptyValue } from '../../api';
import { dateFromHours, dateToISO, formatToHours } from '../../utils/date';
import { globalViewModelStore } from '../../stores/global-vm';
import { ContactType } from '../../api/api-types/address-book';
import { matchAddressData } from '../../api/api-types/generators';
import debounce from 'lodash/debounce';
import { DEBOUNCE_DURATION } from '../../utils/constants';

export class EfrDetailsItem {
  readonly api = new FoodsApi();
  readonly jurisdiction?: ItemType;
  readonly parentJurisdiction?: ItemType;

  @observable id: number;
  @observable reference: string;
  @observable name: string;
  @observable addressLine1: string;
  @observable addressLine2: string;
  @observable country: string;
  @observable city: string;
  @observable state: string;
  @observable zipCode: string;
  @observable description: string;
  @observable monthCapacity?: number;
  @observable annualCapacity: number = 0;
  @observable totalPounds: number = 0;
  @observable type: ItemType | null;
  @observable contact?: Partial<ContactType>;
  @observable foodWillingTypes: (ItemType | null)[] = [];
  @observable accountId: number | null = null;
  @observable parentJurisdictionId?: number;

  @observable webSite: string;
  @observable purpose: string;
  @observable receiveDonationsTimeFrom: Date | string | null;
  @observable receiveDonationsTimeTo: Date | string | null;
  @observable distributeDonationsTimeFrom: Date | string | null;
  @observable distributeDonationsTimeTo: Date | string | null;
  @observable defaultState: Partial<EfrDetailsType>;
  @observable generatorId?: number | null;
  @observable descriptionTruncated: boolean;

  @observable.ref createReq: ApiReq<FoodRecoveryItem> = emptyValue;
  @observable.ref editReq: ApiReq<FoodDetailsResponse> = emptyValue;
  @observable.ref editAddressReq: ApiReq<FoodDetailsResponse> = emptyValue;
  @observable.ref deleteReq: ApiReq<FoodDetailsResponse> = emptyValue;
  @observable.ref activateReq: ApiReq<void> = emptyValue;

  @observable.ref fullDescriptionReq: ApiReq<FullDescriptionResponse> = emptyValue;
  @observable.ref jurisdictionByAddressReq: ApiReq<ItemType | string> = emptyValue;
  

  constructor(props: EfrDetailsType) {
    makeObservable(this);
    this.id = props.id ?? 0;
    this.reference = props?.reference || '';
    this.name = props.name;
    this.contact = props.contact;
    this.jurisdiction = props.jurisdiction;
    this.parentJurisdiction = props.parentJurisdiction;
    this.addressLine1 = props.addressLine1 ?? '';
    this.addressLine2 = props.addressLine2 ?? '';
    this.country = props.country ?? '';
    this.city = props.city ?? '';
    this.state = props.state ?? '';
    this.zipCode = props.zipCode ?? '';
    this.description = props.description ?? '';
    this.type = props.type;
    this.monthCapacity =
      typeof props.monthCapacity === 'undefined' ? 0 : props.monthCapacity;
    this.foodWillingTypes = !!props.foodWillingTypes
      ? props.foodWillingTypes
      : [];

    this.webSite = props.webSite ?? '';
    this.purpose = props.purpose ?? '';

    this.annualCapacity = props.annualCapacity ?? 0;
    this.totalPounds = props.totalPounds ?? 0;

    this.receiveDonationsTimeFrom = props.receiveDonationsTimeFrom
      ? dateFromHours(props.receiveDonationsTimeFrom)
      : null;
    this.receiveDonationsTimeTo = props.receiveDonationsTimeTo
      ? dateFromHours(props.receiveDonationsTimeTo)
      : null;
    this.distributeDonationsTimeFrom = props.distributeDonationsTimeFrom
      ? dateFromHours(props.distributeDonationsTimeFrom)
      : null;
    this.distributeDonationsTimeTo = props.distributeDonationsTimeTo
      ? dateFromHours(props.distributeDonationsTimeTo)
      : null;
    this.descriptionTruncated = props.descriptionTruncated || false;
    this.defaultState = props;
    this.accountId = props.accountId ?? null;
    this.parentJurisdictionId = props.parentJurisdictionId ?? props.parentJurisdiction?.id;
  }

  @computed get serialized() {
    if (!this.type) throw Error('Empty data in Food Modification ');

    return {
      id: this.id,
      name: this.name,
      jurisdiction: this.jurisdiction,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      city: this.city,
      state: this.state,
      zipCode: this.zipCode,
      type: this.type,
      contact: this.contact,
      monthCapacity: Number(this.monthCapacity),
      foodWillingTypes:
        this.foodWillingTypes.indexOf(null) > -1 ? [] : this.foodWillingTypes,
      webSite: this.webSite,
      purpose: this.purpose,
      description: this.description,
      totalPounds: this.totalPounds,
      annualCapacity: this.annualCapacity,
      receiveDonationsTimeFrom: this.receiveDonationsTimeFrom
        ? formatToHours(this.receiveDonationsTimeFrom)
        : null,
      receiveDonationsTimeTo: this.receiveDonationsTimeTo
        ? formatToHours(this.receiveDonationsTimeTo)
        : null,
      distributeDonationsTimeFrom: this.distributeDonationsTimeFrom
        ? formatToHours(this.distributeDonationsTimeFrom)
        : null,
      distributeDonationsTimeTo: this.distributeDonationsTimeFrom
        ? formatToHours(this.distributeDonationsTimeTo)
        : null,
      ...(this.generatorId && {
        generator: {
          id: this.generatorId,
        },
      }),
      ...(this.accountId && { account: { id: this.accountId } }),
    };
  }

  @computed get fullDescription() {
    if (
      this.fullDescriptionReq.state !== 'fulfilled' ||
      !this.fullDescriptionReq.value
    ) {
      return '';
    }

    return this.fullDescriptionReq.value.data?.description || '';
  }
  
  @computed get initValue() {
    if (!this.type) throw Error('Empty data in Food Modification ');
    return {
      id: this.id,
      name: this.name,
      jurisdiction: this.jurisdiction,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      city: this.city,
      state: this.state,
      zipCode: this.zipCode,
      type: this.type,
      monthCapacity: Number(this.monthCapacity),
      foodWillingTypes:
        this.foodWillingTypes.indexOf(null) > -1 ? [] : this.foodWillingTypes,
      webSite: this.webSite,
      purpose: this.purpose,
      description: this.description,
      receiveDonationsTimeFrom: this.receiveDonationsTimeFrom,
      receiveDonationsTimeTo: this.receiveDonationsTimeTo,
      distributeDonationsTimeFrom: this.distributeDonationsTimeFrom,
      distributeDonationsTimeTo: this.distributeDonationsTimeTo,
    };
  }

  @computed get serialization() {
    if (!this.type) throw Error('Empty data in Food Modification ');
    return {
      id: this.id,
      name: this.name,
      jurisdiction: this.jurisdiction,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      city: this.city,
      state: this.state,
      zipCode: this.zipCode,
      type: this.type,
      contact: this.contact,
      monthCapacity: Number(this.monthCapacity),
      foodWillingTypes:
        this.foodWillingTypes.indexOf(null) > -1 ? [] : this.foodWillingTypes,
      webSite: this.webSite,
      purpose: this.purpose,
      description: this.description,
      receiveDonationsTimeFrom: this.receiveDonationsTimeFrom
        ? formatToHours(this.receiveDonationsTimeFrom)
        : null,
      receiveDonationsTimeTo: this.receiveDonationsTimeTo
        ? formatToHours(this.receiveDonationsTimeTo)
        : null,
      distributeDonationsTimeFrom: this.distributeDonationsTimeFrom
        ? formatToHours(this.distributeDonationsTimeFrom)
        : null,
      distributeDonationsTimeTo: this.distributeDonationsTimeFrom
        ? formatToHours(this.distributeDonationsTimeTo)
        : null,
        ...(this.accountId && { account: { id: this.accountId } }),
    };
  }

  @computed get addressFields() {
    return {
      id: this.id,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      city: this.city,
      state: this.state,
      zipCode: this.zipCode,
    };
  }

  @computed get editFormData() {
    return {
      name: this.name,
      type: this.type,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      city: this.city,
      state: this.state,
      zipCode: this.zipCode,
      totalPounds: this.totalPounds,
      annualCapacity: this.annualCapacity,
      contactName: this.contact?.fullName || '',
      phone: this.contact?.phone || '',
      email: this.contact?.email || '',
      generatorId: null,
    };
  }

  @computed get calculatedJurisdiction() {
    if (
      this.jurisdictionByAddressReq.state !== 'fulfilled' ||
      !this.jurisdictionByAddressReq.value
    ) {
      return null;
    }
    return this.jurisdictionByAddressReq.value.data;
  }

  @action setEntity = async (foodFields: Partial<EfrDetailsType>) => {
    this.id = foodFields.id ?? this.id ?? 0;
    this.name = foodFields.name ?? this.name;
    this.type = foodFields.type ?? this.type;
    this.contact = foodFields.contact ?? this.contact;
    this.addressLine1 = foodFields.addressLine1 ?? '';
    this.addressLine2 = foodFields.addressLine2 ?? '';
    this.city = foodFields.city ?? '';
    this.state = foodFields.state ?? '';
    this.zipCode = foodFields.zipCode ?? '';
    this.generatorId = foodFields.generatorId ?? this.generatorId;
    this.description = !!foodFields.description ? foodFields.description : '';
    this.monthCapacity = foodFields.monthCapacity;
    this.foodWillingTypes = !!foodFields.foodWillingTypes
      ? foodFields.foodWillingTypes
      : [];

    this.webSite = foodFields.webSite ?? '';
    this.purpose = foodFields.purpose ?? '';
    this.totalPounds = !!foodFields.totalPounds ? foodFields.totalPounds : 0;
    this.annualCapacity = !!foodFields.annualCapacity
      ? foodFields.annualCapacity
      : 0;
    this.receiveDonationsTimeFrom = foodFields.receiveDonationsTimeFrom
      ? dateToISO(foodFields.receiveDonationsTimeFrom)
      : null;
    this.receiveDonationsTimeTo = foodFields.receiveDonationsTimeTo ?? null;
    this.distributeDonationsTimeFrom =
      foodFields.distributeDonationsTimeFrom ?? null;
    this.distributeDonationsTimeTo =
      foodFields.distributeDonationsTimeTo ?? null;
    this.accountId = foodFields.accountId ?? null;
  };

  @action setDescription = async (value: EfrDetailsItem['description']) => {
    if (value !== undefined) {
      this.editReq = this.api.updateDescription({
        description: value,
        id: this.id,
      });

      await this.editReq;

      runInAction(() => {
        if (this.editReq.state === 'fulfilled') {
          this.description = !!this.editReq.value.data?.description
            ? this.editReq.value.data?.description
            : '';
        }
      });
    }
  };

  @action createFood = async () => {
    this.createReq = this.api.createFood(this.serialized);

    const res = await this.createReq;
    runInAction(() => {
      if (res.data) {
        this.id = res.data?.id;
      }
    });
    return this.createReq;
  };

  @action editFood = async () => {
    this.editReq = this.api.updateFood(this.serialized);
    await this.editReq;
    if (this.editReq.state === 'fulfilled') {
      globalViewModelStore.setSuccessMessages([
        { message: 'Entity successfully updated ', code: 'successUpdated' },
      ]);
    }
    return this.editReq;
  };
  @action editFoodAddress = async () => {
    this.editAddressReq = this.api.updateFoodAddress(this.addressFields);
    await this.editAddressReq;
    if (this.editAddressReq.state === 'fulfilled') {
      globalViewModelStore.setSuccessMessages([
        { message: 'Address successfully updated ', code: 'successUpdated' },
      ]);
    }
    return this.editAddressReq;
  };

  @action deleteFood = async () => {
    this.deleteReq = this.api.deleteFood(this.id);
    return this.deleteReq;
  };

  @action activateFood = () => {
    this.activateReq = this.api.activateFood(this.id);
    return this.activateReq;
  };

  @action calculateJurisdictionByAddress = debounce(
    async (data: matchAddressData) => {
      this.jurisdictionByAddressReq = this.api.getJurisdictionByAddress(data);
      await this.jurisdictionByAddressReq;
    },
    DEBOUNCE_DURATION,
  );

  @action resetFields = () => {
    this.addressLine1 = this.defaultState.addressLine1 ?? this.addressLine1;
    this.addressLine2 = this.defaultState.addressLine2 ?? this.addressLine2;
    this.city = this.defaultState.city ?? this.city;
    this.state = this.defaultState.state ?? this.state;
    this.zipCode = this.defaultState.zipCode ?? this.zipCode;
  };

  @action getFullDescription = async () => {
    if (this.descriptionTruncated) {
      this.fullDescriptionReq = this.api.getFullDescription(this.id);
      await this.fullDescriptionReq;
      runInAction(() => {
        this.descriptionTruncated = false;
        this.description = this.fullDescription;
      });
    }
  };
}
