import Highcharts, { Options } from 'highcharts';
import { PointMarkerOptionsObject } from 'highcharts/highcharts.src';
import {
  HighchartsChartType,
  HighchartsValueType,
} from 'src/api/api-types/diagrams';
import {
  ClickOnLegendType,
  ClickOnPointType,
  ExportCallBackType,
} from 'src/types';
import {
  lineColors,
  MultiLineColors,
} from '../../pages/Dashboard/components/Charts/config/constant';
import HighchartsReact from 'highcharts-react-official';
import { endOfYear, startOfYear } from 'date-fns';
import { monthInMilliseconds } from '../../utils/common';
import { isValidMilliseconds, removeUserTimezone } from 'src/utils/date';
import {
  downloadPdf,
  getLineChartExportRasterImageProps,
  PrintableChart,
  getLineChartExportSVGProps,
  pointItemClick,
  setSeriesOpacity,
  legendItemClick,
} from './utils';
import { ExportFileType } from '../../api/exportTypes';

export class StockChart extends PrintableChart {
  protected clickOnLegend?: ClickOnLegendType;
  protected clickOnPoint?: ClickOnPointType;
  protected exportCallBack?: ExportCallBackType;
  protected data: HighchartsChartType;
  static defaultSelectedZoom: number = 3;
  options: Options;
  chartRef?: HighchartsReact.Props['line'];

  constructor(
    lineChart: HighchartsChartType,
    callBackClickClickOnLegend: ClickOnLegendType,
    callBackClickOnPoint: ClickOnPointType,
    exportCallBack: ExportCallBackType,
  ) {
    const options = getLineChartExportRasterImageProps('application/pdf');
    super(
      options.exportOptions.sourceWidth,
      options.exportOptions.sourceHeight,
      options.exportOptions.scale,
    );

    this.data = lineChart;
    this.clickOnLegend = callBackClickClickOnLegend;
    this.clickOnPoint = callBackClickOnPoint;
    this.exportCallBack = exportCallBack;
    const that = this;

    this.options = {
      chart: {
        type: 'line',
      },
      loading: {
        style: {
          opacity: 0.7,
          top: '0px',
          left: '0px',
        },
        labelStyle: {
          fontSize: '24px',
        },
      },
      title: {
        text: lineChart.title,
        align: 'center',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          opacity: 0.8,
        },
      },
      navigator: {
        xAxis: {
          labels: {
            format: "{value:%b %est, '%y}",
          },
        },
      },
      xAxis: {
        crosshair: true,
        type: 'datetime',
        title: {
          text: '',
        },
        labels: {
          formatter: ctx => {
            if (ctx.dateTimeLabelFormat === "%b '%y") {
              return Highcharts.dateFormat(
                '%m/%e/%y',
                Number(ctx.value),
              ).replace(' ', '');
            }
            return Highcharts.dateFormat('%m/%d/%y', Number(ctx.value));
          },
        },
        min: removeUserTimezone(startOfYear(Date.now())).getTime(),
        max: removeUserTimezone(endOfYear(Date.now())).getTime(),
        gridLineWidth: 1,
        gridZIndex: 1,
        tickInterval: monthInMilliseconds,
        endOnTick: false,
        startOnTick: false,
        ordinal: false,
        startOfWeek: 0,
        tickPixelInterval: 1,
      },
      yAxis: {
        title: {
          text: '',
        },
        opposite: false,
        gridZIndex: 1,
        min: 0,
      },
      plotOptions: {
        series: {
          allowPointSelect: true,
          showInNavigator: true,
          cursor: 'pointer',
          point: {
            events: {
              click: function (data: Highcharts.PointClickEventObject) {
                pointItemClick(data, that.clickOnPoint);
                setSeriesOpacity(data.point.series);
                data.point.series.select();
              },
            },
          },
          events: {
            legendItemClick: function (data) {
              that.resetSelection();
              legendItemClick(data, that.clickOnLegend);
              setSeriesOpacity(this);
              this.select();
            },
          },
        },
        line: {
          tooltip: {
            pointFormatter: function () {
              return `${this.y}`;
            },
          },
        },
      },
      series: this.addMarkerToSeries(
        this.formatToSeries(this.data.values, lineColors),
      ),
      legend: {
        enabled: true,
        align: 'left',
        verticalAlign: 'bottom',
        x: 0,
        y: 0,
        floating: false,
        labelFormatter: function () {
          return `${this.name}: ${this.options?.custom?.total}`;
        },
      },
      exporting: this.exporting,
    };
  }

  get exporting(): Options['exporting'] {
    return {
      chartOptions: {
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
            },
          },
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'separator',
            'downloadCSV',
            'downloadXSLS',
          ],
        },
      },
      menuItemDefinitions: {
        downloadXSLS: {
          onclick: () => {
            if (!this.exportCallBack) return;
            this.chartRef.fullscreen.isOpen && this.chartRef.showLoading();
            this.exportCallBack({
              type: ExportFileType.XLSX,
            });
          },
          text: 'Download XSLS',
        },
        downloadSVG: {
          onclick: () => {
            if (!this.chartRef) return;
            const options = getLineChartExportSVGProps('image/svg+xml');
            this.chartRef.exportChartLocal(
              options.exportOptions,
              options.chartOptions,
            );
          },
        },
        downloadCSV: {
          onclick: () => {
            if (!this.exportCallBack) return;
            this.chartRef.fullscreen.isOpen && this.chartRef.showLoading();
            this.exportCallBack({
              type: ExportFileType.CSV,
            });
          },
          text: 'Download CSV',
        },
        downloadPNG: {
          onclick: () => {
            if (!this.chartRef) return;
            const options = getLineChartExportRasterImageProps('image/png');
            this.chartRef.exportChartLocal(
              options.exportOptions,
              options.chartOptions,
            );
          },
          text: 'Download PNG image',
        },
        downloadJPEG: {
          onclick: () => {
            if (!this.chartRef) return;
            const options = getLineChartExportRasterImageProps('image/jpeg');
            this.chartRef.exportChartLocal(
              options.exportOptions,
              options.chartOptions,
            );
          },
          text: 'Download JPEG image',
        },
        printChart: {
          onclick: () => {
            if (!this.chartRef || !this.onPrint) return;
            this.onPrint();
          },
          text: 'Print chart',
        },
        downloadPDF: {
          onclick: () => {
            if (!this.chartRef) return;
            const options =
              getLineChartExportRasterImageProps('application/pdf');
            downloadPdf(
              this.chartRef,
              options.exportOptions.sourceWidth,
              options.exportOptions.sourceHeight,
            );
          },
          text: 'Download PDF document',
        },
      },
      fallbackToExportServer: false,
    };
  }

  public setChartRef(data: Highcharts.Chart) {
    this.chartRef = data;
  }

  public resetChartRef() {
    this.chartRef = undefined;
  }

  public resetSelection() {
    this.chartRef.series.forEach((series: Highcharts.Series) => {
      series.update(
        {
          opacity: 1,
          zIndex: 99,
        } as any,
        false,
      );
    });
    const selectedPoints = this.chartRef.getSelectedPoints();
    selectedPoints.forEach((point: Highcharts.Point) => {
      point.select(false, true);
    });

    this.chartRef.redraw();
  }

  public resetChartZoom() {
    if (!this.chartRef) {
      // eslint-disable-next-line no-console
      console.error('Chart ref is not set');
      return;
    }
    this.chartRef.xAxis[0].update({
      min: removeUserTimezone(startOfYear(Date.now())).getTime(),
      max: removeUserTimezone(endOfYear(Date.now())).getTime(),
      tickInterval: monthInMilliseconds,
    });
    this.chartRef.xAxis[0].setExtremes(
      removeUserTimezone(startOfYear(Date.now())).getTime(),
      removeUserTimezone(endOfYear(Date.now())).getTime(),
      true,
    );
    this.chartRef.rangeSelector.clickButton(
      StockChart.defaultSelectedZoom,
      true,
    );
  }

  protected addMarkerToSeries(
    seriesData: Highcharts.SeriesOptionsType[],
    options?: PointMarkerOptionsObject,
  ) {
    return seriesData.map(data => ({
      ...data,
      zIndex: 99,
      marker: {
        enabled: true,
        lineWidth: 2,
        symbol: 'circle',
        radius: 5,
        ...(options || {}),
      },
    }));
  }

  protected formatToSeries(
    data: HighchartsValueType[],
    lineColors: MultiLineColors,
  ) {
    return data
      .filter(item => !(item?.filter?.id === 34 || item?.filter?.id === 33))
      .map(
        item =>
          ({
            id: !!item.filter?.id ? item.filter.id : `0`,
            name: item.title,
            type: 'line',
            custom: {
              total: item?.total,
            },
            color: lineColors[item.guid],
            data: item.values.map(
              value =>
                ({
                  x: isValidMilliseconds(value[0]) ? value[0] : Date.now(),
                  y: value[1],
                  id: value[2],
                } as Highcharts.PointOptionsObject & { id: number | null }),
            ),
            yAxis: 0,
          } as Highcharts.SeriesOptionsType),
      );
  }
}
