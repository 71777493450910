import { lazy } from 'react';

import { MinervaRoute } from 'src/utils/router';

export const LoginRoute: MinervaRoute<'login'> = {
  name: 'login',
  path: '/login',
  comp: () => lazy(() => import('./index'))
};

export const UnauthenticatedHomepage: MinervaRoute<'index'> = {
  name: 'index',
  path: '/',
  comp: () => lazy(() => import('./index'))
};
