import { FC, useContext } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button, CircularProgress, Grid } from '@mui/material';
import { GridRowId, GridToolbarContainer } from '@mui/x-data-grid-pro';
import { ExportFileType } from 'src/api/exportTypes';

import { authStoreCtx } from '../../../stores/auth';
import CustomFooter, { FooterProps } from '../components/CustomFooter';

import ColumnsButton from './Actions/ColumnsButton';
import DensityButton from './Actions/DensityButton';
import ExportButton from './Actions/ExportButton';
import ExportButtonWithMenu from './Actions/ExportButtonWithMenu';
import SearchPanelButton from './Actions/SearchPanelButton';

export interface TableToolbarProps {
  createAction: () => void;
  saveAction: () => void;
  cancelAction: () => void;
  editeAction: () => void;
  deleteAction: () => void;
  openSearchAction: () => void;
  openExportAction: () => void;
  rowMode: boolean;
  withDelete: boolean;
  withEdite: boolean;
  withOutCreate: boolean;
  CustomCreationButton?: JSX.Element;
  CustomEditButton?: JSX.Element;
  createLoading: boolean;
  selectedItems: GridRowId[];
  createTitle: string;
  CustomDeleteButton?: JSX.Element;
  paginationProps: FooterProps;
  CustomPagination?: JSX.Element;
  quickExportAction?: (type: ExportFileType) => void;
}

const CreateToolbar: FC<TableToolbarProps> = ({
  createAction,
  saveAction,
  cancelAction,
  rowMode,
  children,
  deleteAction,
  editeAction,
  openSearchAction,
  openExportAction,
  withDelete,
  withEdite,
  selectedItems,
  createLoading,
  withOutCreate,
  CustomCreationButton,
  createTitle,
  CustomDeleteButton,
  CustomEditButton,
  paginationProps,
  CustomPagination,
  quickExportAction,
}) => {
  const { fCfg } = useContext(authStoreCtx);
  return (
    <>
      <GridToolbarContainer
        sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'space-between',
          minWidth: '500px',
          flexWrap: 'nowrap',
          padding: theme => theme.spacing(1, 1),
        }}>
        <Grid container item wrap="nowrap">
          {!withOutCreate ? (
            !!CustomCreationButton ? (
              CustomCreationButton
            ) : (
              <Button
                disabled={rowMode}
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={createAction}
                sx={{ whiteSpace: 'nowrap' }}>
                {createTitle}
              </Button>
            )
          ) : null}
          {createLoading && <CircularProgress sx={{ ml: 2 }} />}
          {children}
          {rowMode && (
            <>
              <Button
                onClick={saveAction}
                variant="outlined"
                color="inherit"
                sx={{ ml: 2 }}>
                Save
              </Button>
              <Button
                onClick={cancelAction}
                variant="outlined"
                color="inherit"
                sx={{ ml: 1 }}>
                Cancel
              </Button>
            </>
          )}
          {withEdite && selectedItems?.length === 1 ? (
            !!CustomEditButton ? (
              CustomEditButton
            ) : (
              <Button onClick={editeAction} variant="outlined" sx={{ ml: 1 }}>
                Edite
              </Button>
            )
          ) : null}
          {withDelete && !!selectedItems?.length ? (
            !!CustomDeleteButton ? (
              CustomDeleteButton
            ) : (
              <Button
                onClick={deleteAction}
                variant="outlined"
                color="error"
                sx={{ ml: 1 }}>
                Delete
              </Button>
            )
          ) : null}
        </Grid>
        <Grid
          container
          item
          width="auto"
          justifyContent="flex-end"
          wrap="nowrap"
          sx={{
            pr: 2,
          }}>
          <DensityButton />
          <ColumnsButton />
          {openSearchAction && <SearchPanelButton onClick={openSearchAction} />}
          {!quickExportAction && !!openExportAction && (
            <ExportButton onClick={openExportAction} />
          )}
          {quickExportAction && (
            <ExportButtonWithMenu quickExportAction={quickExportAction} />
          )}
        </Grid>
      </GridToolbarContainer>
      {fCfg.isNewPagination && CustomPagination ? (
        CustomPagination
      ) : (
        <CustomFooter {...paginationProps} />
      )}
    </>
  );
};

export default CreateToolbar;
