import { action, makeObservable, observable } from 'mobx';

import { storeFactory } from 'src/utils/store';

import { FoodGeneratorsApi } from 'src/api/foodGenerators';
import { AccountEFGStore } from 'src/pages/AccountDetails/components/EFG/store';
import { ItemType } from 'src/types';
import { FoodGeneratorDetailsResponse } from 'src/api/api-types/food-generators';
import { ApiReq, emptyValue } from 'src/api';
import { EfrDetailsType } from 'src/api/api-types/foods';
import { EfrModel } from 'src/models/edible-food/efr-model';


class GeneratorEFRStore {
  api = new FoodGeneratorsApi({ prefix: '' });
  @observable accountEFGStore: AccountEFGStore;
  @observable efrModel = new EfrModel();
  @observable accountsId?: number;
  @observable isLinking: boolean = false;
  @observable deleteFoodModal: boolean = false;

  @observable.ref updateReq: ApiReq<FoodGeneratorDetailsResponse> = emptyValue;

  constructor() {
    makeObservable(this);
    this.accountEFGStore = new AccountEFGStore();
    this.efrModel = new EfrModel();
  }

  @action addEFR = () => {
    this.isLinking = !this.isLinking;
  };

  @action editEFR = () => {
    this.efrModel.editEFRAction(Number(this.efrModel.selectedFoods[0]));
    this.efrModel.removeSelectedFoods();
  };

  @action setAccountId = (value: number) => {
    this.accountsId = value;
  };

  @action addAssignFoodRecovery = async (data: ItemType) => {
    if (!this.accountsId) return;
    this.updateReq = this.api.updateAssignFoodGenerator({
      id: this.accountsId,
      foodRecovery: {
        id: data.id,
      },
    });

    await this.updateReq;
    this.isLinking = false;
    await this.efrModel.fetchFoods();
    this.efrModel.removeSelectedFoods();
  };

  @action toggleDeleteModal = (force: boolean) => {
    this.deleteFoodModal = force;
  };
  @action deleteAssignFoodRecovery = async (generatorId: number) => {
    if (this.efrModel.selectedFoods.length > 0) {
      await Promise.all(this.efrModel.selectedFoods.map(async (id) => {
        this.updateReq = this.api.deleteGeneratorFoodRecoveries({
          id: generatorId,
          foodRecovery: {
            id: Number(id),
          },
        });
        await this.updateReq;
      }));
    }

    return this.updateReq;
  };
  @action deleteSelectedEFR = async (generatorId: number) => {
    await this.deleteAssignFoodRecovery(generatorId);
    await this.efrModel.fetchFoods();
    this.toggleDeleteModal(false);

  };
  @action fetchInitData = async (generatorId: number) => {
    try {
      await this.efrModel.filter.setPersistedFilters({ 'q.generator.id': `${generatorId}` });
      await this.efrModel.fetchFoods();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  @action creation = async (data: Partial<EfrDetailsType>) => {
    await this.efrModel.creationEFR({ ...data, accountId: this.accountsId });
    await this.efrModel.fetchFoods();
  };

}

export const { store: generatorEFRStore, storeCtx: generatorEFRStoreCtx } = storeFactory(
  GeneratorEFRStore,
  'generatorEFRStore',
);
