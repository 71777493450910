import { useContext } from 'react';

import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react-lite';
import Autocomplete from 'src/components/Autocomplete';
import { lookupsStoreCtx } from 'src/stores/lookups';
import { isPending } from 'src/utils/common';

const AccountField = ({
  fieldName = 'account',
  placeholder = 'Look for accounts...',
}: {
  fieldName?: string;
  placeholder?: string;
}) => {
  const { accountsLookupsReq, accountsLookups, fetchAccountsLookups } =
    useContext(lookupsStoreCtx);

  return (
    <Autocomplete
      asyncSearch
      name={fieldName}
      options={accountsLookups}
      sx={{ minWidth: '200px' }}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          variant="outlined"
          placeholder={placeholder}
        />
      )}
      request={fetchAccountsLookups}
      loading={isPending(accountsLookupsReq)}
    />
  );
};

export default observer(AccountField);
