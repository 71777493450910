import { MutableRefObject, SyntheticEvent, DragEvent, useState } from 'react';

import Typography from '@mui/material/Typography';
import { styled } from '@mui/styles';
import { observer } from 'mobx-react-lite';

import { FileUploadType } from '../../../api/api-types/files';
import UploadFileIcon from '../../../assets/icons/File/UploadFileIcon';
import { WithStore } from '../../../utils/store';
import { FileUploadDialogStore } from '../FileUploadDialog/store';
import { ImportUploadStore } from '../ImportUpload/store';

const FragAndDropZone = observer(
  ({
    store,
    uploadInputRef,
    dropZoneDescription,
  }: WithStore<
    {
      uploadInputRef?: MutableRefObject<HTMLInputElement | null>;
      dropZoneDescription?: string;
    },
    FileUploadDialogStore | ImportUploadStore
  >) => {
    const [dragOver, setDragOver] = useState('false');
    const onDragOver = (e: SyntheticEvent) => {
      e.preventDefault();
      setDragOver('true');
    };

    const onDragLeave = () => setDragOver('false');
    const { appendFiles } = store;

    const onDrop = (e: DragEvent<HTMLLabelElement>) => {
      e.preventDefault();

      setDragOver('false');

      if (e?.dataTransfer.files) {
        appendFiles(e?.dataTransfer?.files, FileUploadType.file);
      }
    };

    return (
      <Zone
        onClick={() => uploadInputRef?.current?.click()}
        dragover={`${dragOver}`}>
        <label
          htmlFor="file"
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            border: `${
              dragOver !== 'false' ? '3px dashed rgb(235, 248, 255)' : ''
            }`,
          }}>
          <UploadFileIcon />
          <Typography variant="body1" noWrap>
            Drag and Drop files here or
            <Typography
              variant="body2"
              component="span"
              color="dodgerblue"
              sx={{ pl: '4px', cursor: 'pointer' }}>
              Select one
            </Typography>
          </Typography>
          <Typography variant="caption" fontSize={12}>
            {dropZoneDescription ||
              'You can upload documents, video, images or audio files. Max file size 50MB.'}
          </Typography>
        </label>
      </Zone>
    );
  },
);

const Zone = styled('div')(({ dragover }: { dragover: string }) => ({
  display: 'flex',
  flex: 1,
  padding: '24px 12px',
  background: dragover !== 'false' ? 'rgba(235, 248, 255, 0.5)' : 'inherit',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  border: '1px dashed rgba(118, 125, 134, 0.5)',
}));

export default FragAndDropZone;
