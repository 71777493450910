import { useContext, Suspense, Fragment } from 'react';

import CssBaseline from '@mui/material/CssBaseline/CssBaseline';
import LinearProgress from '@mui/material/LinearProgress';
import { ThemeProvider } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { TypedRoute, useRoute } from 'mobx-router6';
import { I18nextProvider } from 'react-i18next';

import HandleSystemError from './components/HandleSystemError';
import Layout from './components/Layout';
import { authStoreCtx } from './stores/auth';
import { createRouter } from './stores/router';
import { userGlobalStoreCtx } from './stores/user';
import i18n from './utils/i18n';
import { WithLayoutInnerBar } from './utils/router';
import { theme } from './utils/theme/minerva-main';

function App() {
  const { isLoggedIn, currentAccount, featuresConfiguration } =
    useContext(authStoreCtx);
  const { clientType, isSelectedJurisdiction } = useContext(userGlobalStoreCtx);

  const routerStore = createRouter({
    authenticated: isLoggedIn,
    client: clientType,
    selectedJurisdiction: isSelectedJurisdiction,
    featuresConfiguration: featuresConfiguration,
  });
  const { WrapperComponent, RouteComponent } = useRoute({
    defaultWrapper: isLoggedIn ? Layout : Fragment,
  });

  const currentRoute = routerStore.routes[routerStore.route.name];
  const LayoutInnerBar = (currentRoute as WithLayoutInnerBar<TypedRoute<string>>)?.layoutInnerBar || null;

  
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <HandleSystemError>
          <CssBaseline />
          <WrapperComponent
            currentAccount={currentAccount}
            currentRoute={routerStore.currentRouteName}
            LayoutInnerBar={LayoutInnerBar}>
            <Suspense fallback={<LinearProgress />}>
              {RouteComponent && <RouteComponent />}
            </Suspense>
          </WrapperComponent>
        </HandleSystemError>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default observer(App);
