import * as React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton/IconButton';

export const SearchPanelButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps
>(function GridToolbarColumnsButton(props, ref) {
  const { onClick, ...other } = props;
  return (
    <IconButton
      ref={ref}
      size="small"
      {...other}
      onClick={onClick}
      aria-label="Filter Menu"
      data-cy="filter-menu-button"
    >
      <SearchIcon />
    </IconButton>
  );
});

export default SearchPanelButton;
