import { observer } from 'mobx-react-lite';
import CollapseFilter from 'src/components/Collapsible/CollapseFilter';
import SearchPanel from 'src/components/SearchPanel';
import { ServiceHistoryStore } from '../../store';
import { WithStore } from 'src/utils/store';
import GeneratorsIcon from '../../../../assets/icons/Filters/GeneratorsIcon';
import HaulerField from 'src/components/SearchPanel/components/Fields/HaulerField';
import AccountField from 'src/components/SearchPanel/components/Fields/AccountField';
import GeneratorField from 'src/components/SearchPanel/components/Fields/GeneratorField';
import { FilterFields } from 'src/api/api-types/services';

const SearchComponent = (data: WithStore<{}, ServiceHistoryStore>) => {
  const {
    filter,
    fetchServiceHistory,
    pagination: { resetPagination }
  } = data.store;

  const dataRequest = () => {
    resetPagination();
    filter.resetLookups();
    return fetchServiceHistory();
  };
  if(!filter.filter.isSearchOpen) return null;
  return (
    <SearchPanel<FilterFields>
      filterReq={dataRequest}
      filterCtx={filter.filter}>
        <CollapseFilter
          open
          title="Filters"
          Icon={GeneratorsIcon}
          disableCollapse>
          
          <HaulerField />
          <AccountField />
          <GeneratorField />
      </CollapseFilter>
    </SearchPanel>
  );
}

export default observer(SearchComponent);
