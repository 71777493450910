import { PropsWithChildren, useEffect, useState } from 'react';

import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/system';

const StyledDrawer = styled(Drawer)({
  position: 'absolute',
  minWidth: '400px',
  zIndex: 1201,
});

export default ({
  lazyTrigger,
  children,
  ...props
}: PropsWithChildren<any>) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (lazyTrigger) {
      const timer = setTimeout(() => setOpen(prev => !prev), 0);
      return () => clearTimeout(timer);
    } else {
      setOpen(prev => !prev);
    }
  }, [lazyTrigger]);

  return (
    <StyledDrawer open={open} {...props}>
      {children}
    </StyledDrawer>
  );
};
