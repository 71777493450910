export interface CreationFlowProps {
  isIR?: boolean;
  afterIRCreateAction?: (id?: number) => Promise<unknown>;
  afterCreateAction?: () => void;
}

export const CommunicationTypeId = 5;
export const NoteTypeId = 4;
export const WaiverTypeId = 3;
export const MMCompliance = 2;
