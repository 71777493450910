import {
  AssetCategory,
  AssetCreationReq,
  AssetCreationType,
  AssetFormValue,
  AssetItem,
  AssetsResponse,
  AssetSubCategory,
  WaiverInfoType,
} from 'src/api/api-types/assets';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { ApiReq, emptyValue } from '../../api';
import { AssetsApi } from 'src/api/assets';
import { FullDescriptionResponse, ItemType } from '../../types';
import { dateToISO } from 'src/utils/date';
import { AssetAttachments } from './assets-attachments';
import { LinkedFileResponse, LinkFileDto } from 'src/api/api-types/files';
import { ACCOUNT_TYPE_OBJECT } from '../../utils/constants';

export class AssetModification {
  public api = new AssetsApi();
  readonly canUpdate?: boolean = true;
  readonly isRelatedEntityActive?: boolean = true;

  @observable id: number;
  @observable updateDescription?: boolean;
  @observable category?: AssetCategory;
  @observable subCategory?: AssetSubCategory;
  @observable shortText: string;
  @observable type?: ItemType;
  @observable description: string;
  @observable descriptionTruncated: boolean;
  @observable accountNumber?: ItemType | null;
  @observable relatedLookupsName?: string;
  @observable dateIssued: Date | string | null;
  @observable relatedTo?: ItemType | null;
  @observable relatedToType?: ItemType | null;
  @observable attachments: AssetAttachments;
  @observable waiverInfo?: WaiverInfoType;
  @observable waiverInfoStatusId?: WaiverInfoType['status'] | null;
  @observable waiverInfoFollowUpDate?: WaiverInfoType['followUpDate'];

  @observable.ref createReq: ApiReq<AssetItem> = emptyValue;
  @observable.ref editReq: ApiReq<AssetsResponse> = emptyValue;
  @observable.ref deleteReq: ApiReq<AssetsResponse> = emptyValue;
  @observable.ref fullDescriptionReq: ApiReq<FullDescriptionResponse> =
    emptyValue;
  @observable createFormData?: AssetCreationReq;
  @observable.ref linkFileReq: ApiReq<LinkedFileResponse> = emptyValue;

  constructor(asset: AssetCreationType) {
    makeObservable(this);

    const {
      id,
      type,
      category,
      subCategory,
      shortText,
      description,
      relatedTo,
      account,
      relatedToType,
      totalAttachments,
      dateIssued,
      descriptionTruncated,
      canUpdate,
      isRelatedEntityActive,
      waiverInfo,
      waiverInfoStatusId,
      waiverInfoFollowUpDate,
    } = asset;

    this.id = id;
    this.type = type;
    this.category = category;
    this.subCategory = subCategory;
    this.shortText = shortText || '';
    this.description = description || '';
    this.descriptionTruncated = descriptionTruncated || false;
    this.accountNumber = account || null;
    this.dateIssued = dateIssued || null;
    this.attachments = new AssetAttachments({
      id,
      totalAttachments: totalAttachments,
    });
    this.relatedTo = relatedTo || null;
    this.relatedToType = relatedToType || null;
    this.canUpdate = canUpdate;
    this.isRelatedEntityActive = isRelatedEntityActive;
    this.waiverInfo = waiverInfo;
    this.waiverInfoStatusId = waiverInfoStatusId || null;
    this.waiverInfoFollowUpDate = waiverInfoFollowUpDate;
  }

  @computed get serialized() {
    return {
      id: this.id,
      shortText: this.shortText,
      type: this.type?.id ? { id: this.type?.id } : null,
      category: this.category?.id ? { id: this.category?.id } : null,
      description: this.description,
      accountNumber: this.accountNumber?.id
        ? { id: this.accountNumber?.id }
        : null,
      dateIssued: this.dateIssued,
      relatedTo: this.relatedTo?.id ? this.relatedTo : null,
      relatedToType: this.relatedToType?.id ? this.relatedToType : null,
      subCategory: this.subCategory?.id ? { id: this.subCategory?.id } : null,
      updateDescription: this.updateDescription,
      waiverInfo: this.waiverInfoStatusId
        ? {
            status: this.waiverInfoStatusId,
            followUpDate: this.waiverInfoFollowUpDate,
          }
        : null,
    };
  }

  @computed get fullDescription() {
    if (
      this.fullDescriptionReq.state !== 'fulfilled' ||
      !this.fullDescriptionReq.value
    ) {
      return '';
    }

    return this.fullDescriptionReq.value.data?.description || '';
  }

  @action setEntity = async (assetsFields: Partial<AssetCreationType>) => {
    this.shortText =
      !!assetsFields.shortText || assetsFields.shortText === ''
        ? assetsFields.shortText
        : this.shortText;
    this.description =
      !!assetsFields.description || assetsFields.description === ''
        ? assetsFields.description
        : this.description;
    this.type = !!assetsFields.type ? assetsFields.type : this.type;
    this.relatedTo = !!assetsFields.account
      ? assetsFields.account
      : !!assetsFields.relatedTo
      ? assetsFields.relatedTo
      : this.relatedTo;
    this.relatedToType = !!assetsFields.account
      ? ACCOUNT_TYPE_OBJECT
      : !!assetsFields.relatedToType
      ? assetsFields.relatedToType
      : this.relatedToType;
    this.accountNumber = !!assetsFields.account
      ? assetsFields.account
      : this.accountNumber;
    this.dateIssued = !!assetsFields.dateIssued
      ? assetsFields.dateIssued
      : null;
    this.updateDescription = !!assetsFields.updateDescription
      ? assetsFields.updateDescription
      : false;
    this.waiverInfoStatusId = !!assetsFields.waiverInfoStatusId
      ? assetsFields.waiverInfoStatusId
      : this.waiverInfo?.status;
  };

  @action setCreationForm = (formData: AssetFormValue) => {
    this.createFormData = {
      shortText: formData.shortText,
      description: formData.description,
      accountNumber: formData.accountNumber
        ? { id: Number(formData.accountNumber?.id) }
        : null,
      dateIssued: formData.dateIssued ? dateToISO(formData.dateIssued) : null,
      type: { id: Number(formData.type) || formData?.type?.id },
      category: { id: Number(formData?.category) || formData?.category?.id },
      relatedTo: formData.relatedTo
        ? { id: Number(formData.relatedTo?.id) }
        : null,
      relatedToType: formData.relatedToType
        ? { id: Number(formData.relatedToType?.id) }
        : null,
      waiverInfo: formData.waiverInfoStatusId
        ? {
            status: {
              id: Number(formData.waiverInfoStatusId) ?? null,
            },
            followUpDate: formData.waiverInfoFollowUpDate
              ? dateToISO(formData.waiverInfoFollowUpDate)
              : null,
          }
        : null,
      subCategory: formData.subCategory
        ? { id: Number(formData.subCategory) || formData.subCategory.id }
        : null,
    };
  };

  @action createAsset = async () => {
    if (!this.createFormData) return;
    this.createReq = this.api.createAsset(this.createFormData);
    this.createReq.then(res => (this.id = res?.data?.id ?? 0));
    return this.createReq;
  };

  @action createIRAsset = async () => {
    if (!this.createFormData) return;
    this.createReq = this.api.createIRAsset(this.createFormData);
    this.createReq.then(res => (this.id = res?.data?.id ?? 0));
    return this.createReq;
  };

  @action editAsset = async () => {
    if (!this.id) return null;
    this.editReq = this.api.updateAsset(this.serialized);
    return this.editReq;
  };

  @action getFullDescription = async () => {
    if (this.descriptionTruncated) {
      this.fullDescriptionReq = this.api.getFullDescription(this.id);
      await this.fullDescriptionReq;
      runInAction(() => {
        this.descriptionTruncated = false;
        this.description = this.fullDescription;
      });
    }
  };

  @action linkFile = async (file: LinkFileDto) => {
    this.linkFileReq = this.api.linkFileToAsset(this.id, file);
    await this.linkFileReq;
    return this.linkFileReq;
  };

  @action deleteAsset = async () => {
    if (!this.id) return null;
    this.deleteReq = this.api.deleteAsset(this.id);
    return this.deleteReq;
  };

  @action reset = (data: AssetModification) => {
    this.type = data.type;
    this.category = data.category;
    this.subCategory = data.subCategory;
    this.relatedTo = data.relatedTo;
    this.relatedToType = data.relatedToType;
  };
}
