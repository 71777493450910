import Highcharts, { Options } from 'highcharts';
import { PieChartType } from 'src/api/api-types/diagrams';
import { mapColorGuid } from '../../pages/Generators/helpers/common';
import { ClickOnLegendType, ExportCallBackType } from '../../types';
import { exportOptions } from '../../pages/Dashboard/helpers/common';
import {
  PrintableChart,
  downloadPdf,
  getPieChartExportRasterImageProps,
  getPieChartExportSVGProps,
} from './utils';
import { ExportFileType } from '../../api/exportTypes';
import HighchartsReact from 'highcharts-react-official';

export class PolarChart extends PrintableChart {
  protected clickOnSection?: ClickOnLegendType;
  protected exportCallBack?: ExportCallBackType;
  public options: Options;
  public chartRef?: HighchartsReact.Props['pie'];

  constructor(
    polarChart: PieChartType,
    callBackClick: ClickOnLegendType,
    exportCallBack: ExportCallBackType,

  ) {
    const options = getPieChartExportRasterImageProps('application/pdf');
    super(
      options.exportOptions.sourceWidth,
      options.exportOptions.sourceHeight,
      options.exportOptions.scale,
    );

    this.clickOnSection = callBackClick;
    this.exportCallBack = exportCallBack;
    this.options = {
      chart: {
        type: 'pie',
      },
      loading: {
        style: {
          opacity: 0.7,
          top: '0px',
          left: '0px'
        },
        labelStyle: {
          fontSize: "24px"
        },
      },
      title: {
        text: polarChart.title,
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          opacity: 0.8,
        },
      },
      series: [
        {
          name: polarChart.title,
          data: this.formatToSeries(polarChart),
          size: '85%',
          dataLabels: {
            connectorColor: 'black',
            enabled: true,
            format: '{point.name}: {point.y}',
            style: {
              fontSize: '14px',
              fontFamily: 'Arial',
              fontWeight: 'bold',
              textOverflow: 'none',
              whiteSpace: 'normal',
            },
          },
          tooltip: {
            pointFormatter: function () { return `${(this as any).i}: ${this.y}` }
          },
        },
      ] as Highcharts.SeriesOptionsType[],
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.y}',
            style: {
              fontSize: '16px',
            },
          },
        },
        series: {
          showInNavigator: true,
          point: {
            events: {
              click: data => {
                if (!this.clickOnSection || data.point.selected) return;
                this.clickOnSection(
                  (data.point as Highcharts.Point & { id: string }).id,
                  data.point.name,
                  data.point.series.name,
                  data.point.y
                );
              },
            },
          },
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              series: [
                {
                  dataLabels: {
                    style: {
                      fontSize: '6px',
                    },
                  },
                },
              ] as Highcharts.SeriesOptionsType[],
            },
          },
          {
            condition: {
              maxWidth: 700,
            },
            chartOptions: {
              series: [
                {
                  dataLabels: {
                    style: {
                      fontSize: '10px',
                    },
                  },
                },
              ] as Highcharts.SeriesOptionsType[],
            },
          },
        ],
      },
      exporting: {
        chartOptions: {
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
              },
            },
          },
        },
        buttons: {
          contextButton: {
            menuItems: [
              'viewFullscreen',
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
              'separator',
              'downloadCSV',
              'downloadXSLS',
            ],
          },
        },
        menuItemDefinitions: {
          downloadXSLS: {
            onclick: () => {
              if (!this.exportCallBack) return;
              this.chartRef.fullscreen.isOpen && this.chartRef.showLoading();
              this.exportCallBack({
                dashboard: [exportOptions[4]],
                type: ExportFileType.XLSX,
              });
            },
            text: 'Download XSLS',
          },
          downloadSVG: {
            onclick: () => {
              if (!this.chartRef) return;
              const options = getPieChartExportSVGProps('image/svg+xml');
              this.chartRef.exportChartLocal(
                options.exportOptions,
                options.chartOptions,
              );
            },
          },
          downloadCSV: {
            onclick: () => {
              if (!this.exportCallBack) return;
              this.chartRef.fullscreen.isOpen && this.chartRef.showLoading();
              this.exportCallBack({
                dashboard: [exportOptions[4]],
                type: ExportFileType.CSV,
              });
            },
            text: 'Download CSV',
          },
          downloadPNG: {
            onclick: () => {
              if (!this.chartRef) return;
              const options = getPieChartExportRasterImageProps('image/png');
              this.chartRef.exportChartLocal(
                options.exportOptions,
                options.chartOptions,
              );
            },
            text: 'Download PNG image',
          },
          downloadJPEG: {
            onclick: () => {
              if (!this.chartRef) return;
              const options = getPieChartExportRasterImageProps('image/jpeg');
              this.chartRef.exportChartLocal(
                options.exportOptions,
                options.chartOptions,
              );
            },
            text: 'Download JPEG image',
          },
          printChart: {
            onclick: () => {
              if (!this.chartRef || !this.onPrint) return;
              this.onPrint();
            },
            text: 'Print chart',
          },
          downloadPDF: {
            onclick: () => {
              if (!this.chartRef) return;
              const options =
                getPieChartExportRasterImageProps('application/pdf');
              downloadPdf(
                this.chartRef,
                options.exportOptions.sourceWidth,
                options.exportOptions.sourceHeight,
              );
            },
            text: 'Download PDF document',
          },
        },
        fallbackToExportServer: false,
      },
    };
  }

  protected formatToSeries(data: PieChartType) {
    return data.values.map(item => {
      if (item.value <= 0) return null;
      return {
        id: !!item.filter?.id ? item.filter.id : `0`,
        name: item.title,
        y: item.value,
        i: item.info || data.title,
        color: Object.values(mapColorGuid).find(
          target => target.guid === item.guid,
        )?.color,
      };
    });
  }

  protected totalValue(data: PieChartType) {
    return data.values.reduce((acc, item) => acc + Number(item.value), 0);
  }

  public setChartRef(data: HighchartsReact.Props['pie']) {
    this.chartRef = data;
  }

  public resetChartRef() {
    this.chartRef = undefined;
  }

  public resetSelection() {
    this.chartRef?.series[0].points.forEach((point: Highcharts.Point) => {
      point.setState('');
      if (point.sliced) {
        point.sliced = false;
        point.select(false);
      }
    });
  }
}
