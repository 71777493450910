import { lazy } from 'react';

import { MinervaRoute } from 'src/utils/router';

import { servicesStore } from './store';

export const ServicesRoute: MinervaRoute<'services'> = {
  name: 'services',
  path: '/services',
  comp: () => lazy(() => import('./index')),
  activate: async () => {
    servicesStore.resetStore();
    await servicesStore.fetchServices();
  },
  deactivate: () => {
    servicesStore.resetStore();
  },
};
