import { makeAutoObservable } from 'mobx';
import { ItemType } from 'src/types';

//todo all new editing entity for inline editing must be here
//todo transfer relation of categories and subCategories there too
class CellManagerStore {
  activityType: ItemType | null = null;
  subscribersCount: number = 0;

  constructor(initActivityType?: ItemType) {
    if (initActivityType) {
      this.activityType = initActivityType;
    }
    makeAutoObservable(this);
  }

  setValue(newValue: ItemType | null): void {
    this.activityType = newValue;
  }

  addSubscriber() {
    this.subscribersCount++;
  }

  removeSubscriber() {
    this.subscribersCount--;
  }

  hasSubscribers() {
    return this.subscribersCount > 0;
  }
}

class StoreManager {
  private store: CellManagerStore | null = null;

  getStore(initActivityType?: ItemType) {
    if (!this.store) {
      this.store = new CellManagerStore(initActivityType);
    }
    return this.store;
  }

  releaseStore() {
    if (this.store && !this.store.hasSubscribers()) {
      this.store = null;
      console.log('Store removed');
    }
  }
}

export const storeManager = new StoreManager();
