import { useContext, useEffect, useMemo } from 'react';

import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';
import Autocomplete from 'src/components/Autocomplete';
import { useSearchPanel } from '../../context';
import { lookupsStoreCtx } from 'src/stores/lookups';
import { isPending } from 'src/utils/common';
import { TextField } from '@mui/material';

const HaulerField = observer(() => {
  const { haulersLookupsReq, haulersLookups, fetchHaulersLookup } = useContext(lookupsStoreCtx);
  const { filterFields, setFilterValue } = useSearchPanel() || {};
  const { watch } = useFormContext();
  const value = watch('q.hauler.id');

  const memoizedOptions = useMemo(() => haulersLookups, [haulersLookups]);

  const updateFilterValue = () => {
    if (!setFilterValue) return;
    setFilterValue({
      ...filterFields,
      'q.hauler.id': value || '',
    });
  };

  useEffect(updateFilterValue, [value, filterFields, setFilterValue]);

  return (
    <Grid container direction="column">
      <Grid sx={{ mb: 2 }}>
        <Typography variant="body2" color="grey">
          Hauler
        </Typography>
        <Autocomplete
          name="q.hauler.id"
          options={memoizedOptions}
          sx={{ minWidth: '200px' }}
          renderInput={params => (
            <TextField {...params} name="q.hauler.id" variant="outlined" placeholder="Look for haulers..."/>
          )}
          request={fetchHaulersLookup}
          asyncSearch
          placeholder="Look for haulers..."
          loading={isPending(haulersLookupsReq)}
        />
      </Grid>
    </Grid>
  );
});

export default HaulerField;
