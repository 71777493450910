import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { FileUploadType } from 'src/api/api-types/files';
import AssetsTypeInfo from 'src/components/AssetsTable/components/AddAttachments/AssetsTypeInfo';
import { WithStore } from 'src/utils/store';

import AssetDetailsIcon from '../../../assets/icons/AssetDetailsIcon';
import { theme } from '../../../utils/theme/minerva-main';
import LoadButton from '../../Buttons/Button';
import { FileUploadDialog, Props } from '../FileUploadDialog';
import { FileUploadDialogStore } from '../FileUploadDialog/store';

const AttachmentsDialog = observer(
  ({
    uploadInputRef,
    store,
    onClear,
    onDone,
    onClose,
    linkAction,
    asset,
  }: WithStore<Props, FileUploadDialogStore>) => {
    const {
      clear,
      files,
      closeDialog,
      allUploadsDone,
      currentlyUploadingReq,
      upload,
      isOpen,
    } = store;

    const handleClose = () => {
      if (onClose) onClose();
      closeDialog();
    };
    const handleClearAll = () => {
      if (onClear) onClear();
      if (uploadInputRef?.current?.value) uploadInputRef.current.value = '';
      clear();
      closeDialog();
      handleClose();
    };

    const handleDone = async () => {
      if (onDone) onDone();
      if (uploadInputRef?.current?.value) uploadInputRef.current.value = '';
      closeDialog();
      clear();
      handleClose();
    };
    return (
      <Dialog
        id={'AddAttachments'}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <IconButton
          color="inherit"
          style={{ position: 'absolute', right: 0 }}
          onClick={handleClose}
          aria-label="close">
          <CloseIcon style={{ color: theme.palette.text.secondary }} />
        </IconButton>
        <DialogTitle>
          <Typography variant="h4" component="div">
            Add Attachments
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ minWidth: 600 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AssetDetailsIcon style={{ padding: '4px' }} />
            <Typography variant="subtitle1">Activity details</Typography>
          </div>
          <Box sx={{ p: 1 }}>
            <Typography variant="body2" gutterBottom>
              {asset?.shortText}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {asset?.description}
            </Typography>
          </Box>
          <AssetsTypeInfo asset={asset} />
          <FileUploadDialog
            store={store}
            uploadInputRef={uploadInputRef}
            showGenerator={false}
            type={FileUploadType.file}
            asset={asset!}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClearAll} variant="outlined" color="inherit">
            Cancel
          </Button>

          {allUploadsDone ? (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              sx={{
                m: 2,
                py: 1,
              }}
              onClick={handleDone}>
              <Typography>Done</Typography>
            </Button>
          ) : (
            <Tooltip
              title={!files.length ? 'Please attach the file' : ''}
              placement="top">
              <Box>
                <LoadButton
                  color="primary"
                  variant="contained"
                  size="small"
                  sx={{ py: 1, ml: 1 }}
                  asyncAction={currentlyUploadingReq}
                  onClick={() =>
                    upload({
                      params: {
                        groupId: asset?.id,
                      },
                      linkAction,
                    })
                  }
                  disabled={
                    files.filter((x: any) => x.error !== undefined).length >
                      0 || files.length === 0
                  }>
                  <Typography>Upload Files</Typography>
                </LoadButton>
              </Box>
            </Tooltip>
          )}
        </DialogActions>
      </Dialog>
    );
  },
);
export default AttachmentsDialog;
