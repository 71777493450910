import { MouseEventHandler } from 'react';

import Button from '@mui/material/Button';

interface ActionProps {
  submit: MouseEventHandler<HTMLButtonElement>;
  clear: MouseEventHandler<HTMLButtonElement>;
}

const Actions = ({ submit, clear }: ActionProps) => {
  return (
    <div
      style={{
        marginLeft: 'auto',
      }}>
      <Button onClick={clear} variant="outlined" color="inherit" data-cy="clear-search-form-btn">
        Clear all
      </Button>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ m: 2 }}
        onClick={submit}
        data-cy="submit-search-form-btn"
      >
        Search
      </Button>
    </div>
  );
};

export default Actions;
