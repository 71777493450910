import { useEffect } from 'react';

import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';
import  Account from "src/components/Lookups/AccountField";
import { useSearchPanel } from '../../context';

const AccountField = observer(() => {
  const { filterFields, setFilterValue } = useSearchPanel() || {};

  const { watch } = useFormContext();
  const value = watch('q.generator.id');

  useEffect(() => {
    if (!setFilterValue) return;
    setFilterValue({
      ...filterFields,
      'q.generator.id': value || '',
    });
  }, [value, filterFields, setFilterValue]);

  return (
    <Grid container direction="column">
      <Grid sx={{ mb: 2 }}>
        <Typography variant="body2" color="grey">
          Account
        </Typography>
        <Account fieldName='q.generator.id' />
      </Grid>
    </Grid>
  );
});

export default AccountField;
