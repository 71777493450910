import { CommonObj } from 'src/types';

export const capitalize = (test: string) =>
  test
    .split('-')
    .map(x => `${x.charAt(0).toUpperCase()}${x.slice(1)}`)
    .join(' ');

export const dotNameKeyToObject = <T extends CommonObj>(data?: T) => {
  if (!data) return undefined;
  let res = {} as CommonObj;
  Object.keys(data).forEach(key => {
    const value = data[key].hasOwnProperty('id') ? data[key]?.id : data[key];
    const obj = {} as CommonObj;
    key
      .substring(2)
      .split('.')
      .forEach((key, index, arr) => {
        if (arr.length === 1) return (obj[key] = value);
        if (index % 2 !== 0) {
          const similarKey = arr[0];
          obj[similarKey] = {
            ...res[similarKey],
            [key]: value,
          };
        }
      });
    res = { ...res, ...obj };
  });
  return res;
};

export const dotNameKeyToString = <T extends CommonObj>(data?: T) => {
  if (!data) return undefined;
  let res = {} as CommonObj;
  Object.keys(data).forEach(key => {
    const value = data[key].hasOwnProperty('state')
      ? data[key].state
      : data[key].hasOwnProperty('id')
      ? data[key]?.id
      : data[key];
    res = { ...res, [key]: value };
  });
  return res;
};

export function pluralize(word: string, value: number | unknown[]): string {
  const isPlural = Array.isArray(value) ? value.length > 1 : value > 1;

  let pluralWord: string;

  if (isPlural) {
    pluralWord = word.endsWith('y')
      ? word.slice(0, -1) + 'ies' // activity -> activities
      : word + 's'; // example -> examples
  } else {
    pluralWord = word;
  }

  if (word[0] === word[0].toUpperCase()) {
    pluralWord = pluralWord[0].toUpperCase() + pluralWord.slice(1);
  }

  return pluralWord;
}
