import { styled } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

export const Table = styled(DataGridPro)(({ theme, rows }) => ({
  borderBottom: 'none',
  fontWeight: 400,
  width: '100%',
  height: "100%",

  '&.MuiDataGrid-autoHeight': {
    minHeight: rows.length === 0 ? '20vh' : 'auto',
    width: '100%',
    '& .MuiDataGrid-main': {
      overflowY: 'auto',
    },
    '& .MuiDataGrid-virtualScrollerContent': {
      height: rows.length === 0 ? '20vh !important' : 'auto',
    },
  },

  '& [class*="editInputCell"]': {
    height: '100%',
    border: '1px solid rgba(224, 224, 224, 1);',
  },

  '& .MuiDataGrid-row': {
    position: 'relative',
  },

  '& .MuiDataGrid-columnHeaders': {
    background: '#F6F7F9',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '150%',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'visible',
  },
  '& .MuiDataGrid-cell--textLeft': {
    color: theme.palette.text.primary,
    ...theme.typography.body1,
  },
  '& .MuiTablePagination-caption[id]': {
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  '& .MuiTablePagination-input': {
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  '& .MuiToolbar-root > *': {
    color: theme.palette.text.primary,
    ...theme.typography.hint,
  },
  '.MuiDataGrid-panelWrapper > .Mui-disabled': {
    visible: 'hidden !important',
  },
  '.MuiFormControlLabel-root .Mui-disabled': {
    visible: 'hidden !important',
  },
  '& .disabled-row': {
    opacity: '0.5',
  },
  '& .active-filter': {
    '& .MuiDataGrid-menuIcon': {
      visibility: 'visible',
      width: 'auto'
    },
  },
}));
