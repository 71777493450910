import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import { AssetsApi } from '../../api/assets';
import { ApiReq, emptyValue } from '../../api';
import {
  AssetCreationType,
  AssetFiltersType,
  AssetFormValue,
  AssetItem,
  AssetsResponse,
} from 'src/api/api-types/assets';
import { lookupsStore } from '../../stores/lookups';
import { GridRowId } from '@mui/x-data-grid-pro';
import { OnStartEditType } from 'src/components/Table/types';
import {
  defaultAssetsSearch,
  initialAssetsData,
  visibilityDefaultState,
} from 'src/components/AssetsTable/helpers/common';
import { returnIds } from 'src/utils/common';
import { ExportDataType } from 'src/api/exportTypes';
import { TableFilter } from 'src/models/filter/table-filter';
import { AssetModification } from 'src/models/assets/asset-modification';
import { Asset } from 'src/models/assets/asset';
import {
  AssetsExportColumnsNameMap,
  assetsFilterNameMap,
  AssetsNameMapType,
} from 'src/models/filter/constants';
import { TablePagination } from '../pagination/pagination';
import { GridColumnsManager } from '../grid-columns-manager/grid-columns-manager';
import { columns } from '../../components/AssetsTable/helpers/gridColumns';
import { userStore } from '../../stores/user';
import { AsyncExportEntity } from '../export/AsyncExportEntity';
import { ExportTypes } from 'src/types';
import {
  CommunicationTypeId,
  WaiverTypeId,
} from '../../components/AssetsTable/components/CreateActivity/type';

export class AssetsModel {
  api = new AssetsApi({ prefix: '' });
  @observable isFilterOpen = false;
  @observable isCreation = false;

  @observable isWaiverCreation = false;
  @observable isCommunicationCreation = false;

  @observable deleteAssetModal = false;
  @observable selectedAssets: Array<Asset> = [];
  @observable exportEntity: AsyncExportEntity = new AsyncExportEntity();
  @observable.ref assetsReq: ApiReq<AssetsResponse> = emptyValue;
  @observable.ref assets: IObservableArray<Asset> = observable([]);
  @observable.ref chosenAsset?: AssetModification;
  @observable pagination = new TablePagination<AssetsResponse>({
    requestRef: this.assetsReq,
  });

  @observable columnsManager;

  @observable filterModel?: TableFilter<AssetFiltersType, AssetItem>;

  constructor() {
    makeObservable(this);
    this.pagination.setFetchingCallBack(this.fetchAssets);

    this.columnsManager = new GridColumnsManager<Asset>({
      columns,
      columnsMap: this.columnsVisibleMap,
      visibilityModel: visibilityDefaultState,
    });
  }

  @computed get defaultFilterField() {
    if (userStore.isJurisdiction) {
      delete defaultAssetsSearch['q.jurisdiction.id'];
      return defaultAssetsSearch;
    }
    return defaultAssetsSearch;
  }

  @computed get columnsVisibleMap() {
    return {
      jurisdiction: {
        jurisdiction: false,
      },
    };
  }

  @computed get filter() {
    this.filterModel ??= new TableFilter<AssetFiltersType, AssetItem>(
      this.columnsManager?.gridColumns,
      this.defaultFilterField,
      assetsFilterNameMap,
      (column, filters) => this.api.getAssetsFilterOptions({ filters, column }),
    );
    return this.filterModel;
  }

  @computed get assetsData() {
    if (this.assetsReq.state !== 'fulfilled' || !this.assetsReq.value) {
      return [];
    }

    return this.assetsReq.value.data?.items || [];
  }

  @computed get isAssetsDisabledToDelete() {
    return this.selectedAssets.some(({ canUpdate }) => !canUpdate);
  }

  @action selectAsset = (id: number) => {
    const needle = this.assets.find(item => item.id === id);
    if (needle) this.chosenAsset = new AssetModification(needle);
  };

  @action modifyAsset = async (
    entity: AssetCreationType,
    old: AssetCreationType,
  ) => {
    const modifyAsset = new AssetModification(entity);
    const updateDescription = !(entity.description === old.description);
    if (!!modifyAsset) {
      try {
        await modifyAsset?.setEntity({ ...entity, updateDescription });
        await modifyAsset?.editAsset();
        this.removeChosenAsset();
        await this.fetchAssets();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
  };

  @action deleteAssets = () => {
    (async () => {
      await Promise.all(
        this.selectedAssets.map(async ({ id }) => {
          this.selectAsset(Number(id));
          await this.chosenAsset?.deleteAsset();
        }),
      );
      this.chosenAsset = undefined;
      this.removeSelectedAssets();
      await this.fetchAssets();
    })();
  };

  @action addNewAsset = async () => {
    this.isCreation = true;
    Promise.allSettled([this.prepareAssetMainValues()]).then(_ => {
      this.chosenAsset = new AssetModification({ ...initialAssetsData });
    });
  };

  @action setNewAsset = (asset: AssetCreationType) => {
    this.isCreation = true;
    Promise.allSettled([this.prepareAssetMainValues()]).then(_ => {
      this.chosenAsset = new AssetModification(asset);
    });
  };

  @action setNewActivity = (activityType: number, asset: AssetCreationType) => {
    if (activityType === CommunicationTypeId) {
      this.isCommunicationCreation = true;
    }
    if (activityType === WaiverTypeId) {
      this.isWaiverCreation = true;
    }

    Promise.allSettled([this.prepareAssetMainValues()]).then(_ => {
      this.chosenAsset = new AssetModification({
        ...asset,
        type: lookupsStore.assetTypes.find(item => item.id === activityType),
      });
    });
  };

  @action prepareAssetMainValues = async () => {
    try {
      await lookupsStore.fetchAssetTypes();
    } catch (e) {
      throw e;
    }
  };

  @action fetchAssets = async () => {
    runInAction(() => {
      this.assetsReq = this.api.getAssets({
        ...this.pagination.serialize,
        filters: this.filter.filterParams,
      });
    });

    await this.assetsReq;

    runInAction(() => {
      if (
        this.assetsReq.state !== 'fulfilled' ||
        this.assetsReq.value?.data === null
      )
        return;
      this.assets.replace(
        this.assetsData.map(clientData => new Asset(clientData)),
      );
      this.pagination.setPagination(this.assetsReq.value?.data);
    });

    return this.assetsReq;
  };

  @action handleStartEditAction = async ({ id }: OnStartEditType<Asset>) => {
    this.selectAsset(Number(id));
    const editRequests = [
      this.chosenAsset?.getFullDescription(),
      lookupsStore?.fetchAssetTypes(),
      lookupsStore?.fetchAssetCategories(this.chosenAsset?.type?.id!),
      lookupsStore?.fetchAssetSubCategories(this.chosenAsset?.category?.id!),
      this.chosenAsset?.relatedToType?.id
        ? lookupsStore?.fetchRelatedLookups(this.chosenAsset.relatedToType.id)
        : null,
      lookupsStore?.fetchAssetsWaiverStatus(),
      lookupsStore?.fetchRelatedType(),
    ].filter(Boolean);
    Promise.allSettled(editRequests);
  };

  @action handleCancelEditAction = () => {
    this.assets.replace(this.assets);
  };

  @action loadAttachments = (id: number) => {
    this.selectAsset(id);
    if (!this.chosenAsset) return;
    this.chosenAsset.attachments.getAttachmentsList();
  };

  @action removeChosenAsset = () => {
    this.chosenAsset = undefined;
  };

  @action setSelectedAssets = (items: GridRowId[]) => {
    const chosenAssets = this.assets.filter(({ id }) => items.includes(id));
    this.selectedAssets = [
      ...new Set([...chosenAssets, ...this.selectedAssets]),
    ].filter(({ id }) => items.includes(id));
  };

  @action removeSelectedAssets = () => (this.selectedAssets = []);

  @action fetchAssetsFile = async () => {
    if (!this.chosenAsset?.id) {
      throw new Error('cannot fetch Files, invalid assets id ');
    }
  };

  @action cancelCreation = () => {
    this.isCreation = false;
    this.isWaiverCreation = false;
    this.isCommunicationCreation = false;
    this.removeChosenAsset();
  };

  @action saveNewAsset = async (data: AssetFormValue) => {
    this.chosenAsset?.setCreationForm(data);
    const newAsset = await this.chosenAsset?.createAsset();
    if (!!newAsset) {
      this.chosenAsset = new AssetModification(
        newAsset.data as AssetCreationType,
      );
    }
    this.isCreation = false;
    await this.fetchAssets();
  };

  @action saveAssetWithCb = async (
    data: AssetFormValue,
    callback: (asset: AssetModification) => void,
  ) => {
    await this.saveNewAsset(data);
    if (this.chosenAsset) callback(this.chosenAsset);
  };

  @action saveIRAsset = async (data: AssetFormValue) => {
    this.chosenAsset?.setCreationForm(data);
    await this.chosenAsset?.createIRAsset();
    this.removeChosenAsset();
    this.isCreation = false;
  };

  @action uploadAssets = (id: number) => {
    this.selectAsset(id);
  };

  @action toggleDeleteModal = (force: boolean) => {
    this.deleteAssetModal = force;
  };

  @action exportAction = async (data: ExportDataType) => {
    if (!data.type) return;
    if (Number(data.mode) === ExportTypes.ALL)
      this.exportEntity.setExportWithUnactiveRecords(true);
    this.exportEntity.setParams(data);
    await this.exportEntity.asyncExportAction(
      this.api.getAsyncReportByType(
        this.exportEntity.exportParams<
          AssetsResponse,
          AssetFiltersType,
          AssetItem
        >(
          this.columnsManager.hiddenColumns.map(el => {
            return AssetsExportColumnsNameMap[el as AssetsNameMapType] || el;
          }),
          this.filter,
          this.pagination,
          returnIds(this.selectedAssets),
        ),
        data.type,
      ),
    );
  };

  @action resetStore = () => {
    this.pagination.resetPagination();
    this.filter.resetFilters();
    this.filter.setSearchOpen(false);
    this.removeSelectedAssets();
  };
}
