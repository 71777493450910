import { useEffect } from 'react';

import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';
import  Generator from "src/components/Lookups/GeneratorField";
import { useSearchPanel } from '../../context';

const GeneratorField = observer(() => {
  const { filterFields, setFilterValue } = useSearchPanel() || {};

  const { watch } = useFormContext();
  const value = watch('q.location.id');

  useEffect(() => {
    if (!setFilterValue) return;
    setFilterValue({
      ...filterFields,
      'q.location.id': value,
    });
  }, [value, filterFields, setFilterValue]);

  return (
    <Grid container direction="column">
      <Grid sx={{ mb: 2 }}>
        <Typography variant="body2" color="grey">
          Generator Address
        </Typography>
        <Generator fieldName="q.location.id" />
      </Grid>
    </Grid>
  );
});

export default GeneratorField;
