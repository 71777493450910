import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Link } from 'src/components/Link';
import { formattedDateOnly, formattedUSDateTime } from 'src/utils/date';

import { ServiceItem } from '../../../api/api-types/services';
import MaterialType from '../../../components/MaterialType';
import { ScheduleComponent } from '../../../components/Schedule/ScheduleComponent';
import { ScheduleHeader } from '../../../components/Schedule/ScheduleHeader';
import { GRID_CHECKBOX_SELECTION_COL } from '../../../components/Table/constants';

export const servicesColumns: GridColDef<ServiceItem>[] = [
  GRID_CHECKBOX_SELECTION_COL,
  {
    field: 'generatorId',
    headerName: 'Generator ID',
    width: 150,
    disableColumnMenu: true,
    renderCell: ({ value, row }: GridRenderCellParams) => (
      <Link to="generator" params={{ id: row.location.id }}>
        {value}
      </Link>
    ),
  },
  {
    field: 'minervaId',
    headerName: 'Minerva ID',
    minWidth: 150,
    disableColumnMenu: true,
    renderCell: ({ value, row }: GridRenderCellParams) => (
      <Link to="account" params={{ id: row.generator.id }}>
        {value}
      </Link>
    ),
  },
  {
    field: 'jurisdiction',
    headerName: 'Jurisdiction',
    width: 120,
    filterable: true,
    valueGetter: ({ value }) => (value ? value?.name : ''),
  },
  {
    field: 'hauler',
    headerName: 'Hauler',
    width: 90,
    filterable: true,
    valueGetter: ({ value }) => (value ? value?.name : ''),
  },
  {
    field: 'accountNumber',
    headerName: 'Account #',
    minWidth: 110,
    disableColumnMenu: true,
  },
  {
    field: 'recordId',
    headerName: 'Route ID',
    minWidth: 110,
    disableColumnMenu: true,
  },
  {
    field: 'dataDate',
    headerName: 'Data date',
    minWidth: 140,
    disableColumnMenu: true,
    renderCell: ({ value }: GridRenderCellParams) => formattedDateOnly(value),
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    disableColumnMenu: true,
    minWidth: 170,
    renderCell: ({ value }: GridRenderCellParams) => formattedUSDateTime(value),
  },
  {
    field: 'accountType',
    headerName: 'Account Type',
    flex: 1,
    minWidth: 150,
    filterable: true,
    valueGetter: ({ value }) => (value ? value?.name : ''),
  },
  {
    field: 'generator',
    headerName: 'Account Name',
    minWidth: 200,
    disableColumnMenu: true,
    valueGetter: ({ value }) => (value ? value?.name : ''),
  },
  {
    field: 'location',
    headerName: 'Account address',
    width: 450,
    disableColumnMenu: true,
    valueGetter: ({ value }) => value.name ?? '',
  },
  {
    field: 'containerQuantity',
    headerName: 'Quantity',
    width: 175,
    disableColumnMenu: true,
  },
  {
    field: 'container',
    headerName: 'Container ID',
    width: 210,
    disableColumnMenu: true,
    valueGetter: ({ value }) => value.name,
  },
  {
    field: 'serviceWasteType',
    headerName: 'Material',
    width: 145,
    filterable: true,
    valueGetter: ({ value }) => (value ? value?.name : ''), 
    renderCell: (data: GridRenderCellParams) =>
      <MaterialType type={data.row[data.field]} />
  },
  {
    field: 'containerType',
    headerName: 'Type',
    width: 145,
    disableColumnMenu: true,
  },
  {
    field: 'containerSize',
    headerName: 'Size',
    width: 145,
    disableColumnMenu: true,
  },
  {
    field: 'unitOfMeasure',
    headerName: 'Unit',
    width: 145,
    filterable: true,
    valueGetter: ({ value }) => value.name,
  },
  {
    field: 'weeklyFrequency',
    headerName: 'Weekly Pickups',
    width: 145,
    disableColumnMenu: true,
  },
  {
    field: 'schedule',
    headerName: 'Schedule',
    disableColumnMenu: true,
    renderCell: ({ value, row }) => (
      <ScheduleComponent
        schedule={value}
        scheduleDefined={row.scheduleDefined}
      />
    ),
    renderHeader: () => <ScheduleHeader />,
    width: 200,
  },
  {
    field: 'weeklyVolumeCubicYards',
    headerName: 'Weekly Volume',
    width: 145,
    disableColumnMenu: true,
  },
  {
    field: 'onCall',
    headerName: 'On call',
    width: 145,
    type: 'boolean',
    filterable: true,
  },
  {
    field: 'site',
    headerName: 'Site',
    width: 145,
    disableColumnMenu: true,
  },
  {
    field: 'containerGroup',
    headerName: 'Container Group',
    width: 145,
    disableColumnMenu: true,
  },
];
