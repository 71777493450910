
import { LazyExoticComponent, lazy } from 'react';
import { SvgIconProps } from '@mui/material';

export const cardsIconMap: { [key: string]:  LazyExoticComponent<(props: SvgIconProps) => JSX.Element>} = {
  0: lazy(() => import('../../../../../assets/icons/Dashboard/Insipid/InsipidRecyclingIcon')),
  1: lazy(() => import('../../../../../assets/icons/Dashboard/Insipid/InsipidRecyclingIcon')),
  2: lazy(() => import('../../../../../assets/icons/Dashboard/NoyRecycleIcon')),
  3: lazy(() => import('../../../../../assets/icons/Dashboard/Insipid/InsipidDeMinimisIcon')),
  4: lazy(() => import('../../../../../assets/icons/Dashboard/Insipid/InsipidPhysicalSpaceIcon')),
  5: lazy(() => import('../../../../../assets/icons/Dashboard/Insipid/InsipidEdiblefoodIcon')),
  6: lazy(() => import('../../../../../assets/icons/Dashboard/Insipid/InsipidRecoveryOSIcon')),
  7: lazy(() => import('../../../../../assets/icons/Dashboard/Insipid/InsipidRecyclingIcon')),
  8: lazy(() => import('../../../../../assets/icons/Dashboard/NoyRecycleIcon')),
  9: lazy(() => import('../../../../../assets/icons/Dashboard/Insipid/InsipidRecyclingIcon')),
  10:  lazy(() => import('../../../../../assets/icons/Dashboard/NoyRecycleIcon')),
  11:  lazy(() => import('../../../../../assets/icons/Dashboard/TotalPoundsIcon')),
};

export const ignoreType = (entity: number, target: number | number[]) => {
  if (Array.isArray(target)) {
    return target.indexOf(entity) < 0;
  }
  return target !== entity;
};
