import { AsyncExportResponse, ExportParams, ItemType, ListResponseType, Pageable, ParamsWithFilters } from 'src/types';
import { ApiClient } from '.';
import {
  GeneratorCreationType,
  GeneratorDetailsResponse,
  RequestGeneratorsParams,
  OrganicWasteMaterialsResponse,
  OrganicMaterialType,
  GeneratorsResponse,
  GeneratorDetailsItem,
  GeneratorsFilterType,
} from './api-types/generators';
import { dotNameKeyToObject } from '../utils/string';

export class GeneratorsApi extends ApiClient {
  getGeneratorsData({ page, pageSize, filters }: RequestGeneratorsParams) {
    return this.requestPost<
      GeneratorsResponse,
      ParamsWithFilters<GeneratorsFilterType>
    >('locations/list', {
      q: dotNameKeyToObject(filters),
      page: {
        num: page,
        size: pageSize,
      },
    });
  }

  async getGeneratorsFilterOptions({ filters, column }: { filters: Partial<GeneratorsFilterType>, column: string }) {
    const filterMap: { [key: string]: string } = {
      hauler: 'hauler.name',
      jurisdiction: 'jurisdiction.name',
      agency: 'agency.name',
      types: 'type.name',
      accountType: 'accountType.name',
      zone: 'zone.name',
      collectionSystem: 'collectionSystem.name'
    };
    const filterColumn = filterMap[column] ?? column;
    const response = await this.requestPost<
      ListResponseType<ItemType> & Pageable,
      ParamsWithFilters<GeneratorsFilterType> & { column: string }
    >('locations/list/filter-options', {
      q: dotNameKeyToObject(filters),
      page: {
        num: 1,
        size: 100000,
      },
      column: filterColumn,
    });

    return response.data!.items;
  }

  getGeneratorDetails(id: number) {
    return this.requestGet<GeneratorDetailsResponse>(`locations/${id}`);
  }

  creteGenerator(location: GeneratorCreationType) {
    return this.requestPost<GeneratorDetailsResponse, GeneratorCreationType>(
      'locations',
      location,
    );
  }

  updateGeneratorDescription({
    description,
    id,
  }: {
    description: string;
    id: number;
  }) {
    return this.requestPut<
      GeneratorDetailsResponse,
      Partial<GeneratorDetailsResponse>
    >(`locations/${id}/description`, {
      id,
      description,
    });
  }

  updateGenerator({ nickname, id, type }: Partial<GeneratorDetailsItem>) {
    return this.requestPut<
      GeneratorDetailsResponse,
      Partial<GeneratorDetailsResponse>
    >(`locations/${id}`, {
      id,
      nickname,
      type
    });
  }

  getOrganicWasteMaterials(id: number) {
    return this.requestGet<OrganicWasteMaterialsResponse>(
      `locations/${id}/generates-no-waste-material-types`,
    );
  }

  updateOrganicWasteMaterial(
    id: number,
    material: Omit<OrganicMaterialType, 'name'>,
  ) {
    return this.requestPut<
      OrganicMaterialType,
      Omit<OrganicMaterialType, 'name'>
    >(`locations/${id}/generates-no-waste-material-types`, material);
  }

  getAsyncReportByType(params: ExportParams, exportType: string) {
    return this.requestPost<AsyncExportResponse, ExportParams>(
      `locations/export/${exportType}?flowType=2`,
      params,
    );
  }
}
