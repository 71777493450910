import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import {
  AccountDetailsResponse,
  AccountContactsResponse,
  AccountHaulerResponse,
  AccountsResponse,
} from 'src/api/api-types/accounts';

import { storeFactory } from '../../utils/store';
import { AccountsApi } from '../../api/account';
import { AccountModification } from 'src/models/account/account-modification';

import { addressBookStore } from '../AddressBook/store';
import { ACCOUNT_TYPE_OBJECT } from '../../utils/constants';
import { ServicesModel } from 'src/models/servise/service-model';
import { AssetsModel } from '../../models/assets/assets-model';
import { GeneratorCreationType } from 'src/api/api-types/generators';
import { EditFormType } from './components/LeftBar/GeneralInfo/helpers/common';
import { isAccountDataChanged } from './helpers/utils';

class AccountDetailsStore {
  apiAccount = new AccountsApi({ prefix: '' });

  @observable isAddRelated: boolean = false;
  @observable editGeneralInfo: boolean = false;
  @observable isCollapseAll?: boolean;
  @observable isOpenLeftBar: boolean = true;
  @observable isFilterOpen = false;
  @observable account?: AccountModification | undefined;

  @observable services = new ServicesModel();
  @observable assets = new AssetsModel();

  @observable accountDetailsReq: ApiReq<AccountDetailsResponse> = emptyValue;
  @observable accountContactsReq: ApiReq<AccountContactsResponse> = emptyValue;
  @observable accountHaulersReq: ApiReq<AccountHaulerResponse> = emptyValue;
  @observable attachRelatedToReq: ApiReq<AccountsResponse> = emptyValue;
  @observable changeGeneratorReq: ApiReq<AccountDetailsResponse> = emptyValue;

  constructor() {
    makeObservable(this);
  }

  @computed get accountDetails() {
    if (this.accountDetailsReq.state !== 'fulfilled' || !this.accountDetailsReq.value) {
      return undefined;
    }
    return this.accountDetailsReq.value.data;
  }

  @computed get changeGeneratorDetails() {
    if (this.changeGeneratorReq.state !== 'fulfilled' || !this.changeGeneratorReq.value) {
      return undefined;
    }
    return this.changeGeneratorReq.value.data;
  }

  @computed get accountContacts() {
    if (this.accountContactsReq.state !== 'fulfilled' || !this.accountContactsReq.value) {
      return [];
    }
    return this.accountContactsReq.value.data?.items;
  }

  @computed get isImportAccount() {
    if (this.accountDetailsReq.state !== 'fulfilled' || !this.accountDetailsReq.value) {
      return false;
    }
    return this.accountDetailsReq.value.data?.origin.id === 2;
  }

  @computed get accountHaulers() {
    if (this.accountHaulersReq.state !== 'fulfilled' || !this.accountHaulersReq.value) {
      return [];
    }
    return this.accountHaulersReq.value.data?.items;
  }

  @computed get generalInfoData() {
    return ({
      id: this.account?.id,
      type: this.account?.defaultState.type || undefined,
      addressLine1: this.account?.defaultState?.location?.addressLine1 || this.account?.location?.addressLine1,
      addressLine2: this.account?.defaultState?.location?.addressLine2,
      city: this.account?.defaultState?.location?.city,
      state: this.account?.defaultState?.location?.state,
      zipCode: this.account?.defaultState?.location?.zipCode,
      generatorId: !!this.account?.defaultState?.location ? {
        id: this.account?.defaultState.location.id,
        name: this.account?.defaultState.location.reference,
      } : undefined,
      nickname: this.account?.defaultState?.location?.nickname,
      generatorType: this.account?.defaultState?.location?.type,
    });
  }

  @action fetchAccountHaulers = async (id: number) => {
    this.accountHaulersReq = this.apiAccount.getAccountHaulers(id);
    await this.accountDetailsReq;
  };

  @action fetchAccountDetails = async (id: number) => {
    this.accountDetailsReq = this.apiAccount.getAccountDetails(id);
    await this.accountDetailsReq;
    runInAction(() => {
      if (this.accountDetails) {
        this.account = new AccountModification(this.accountDetails);
      }
    });
    return this.accountDetailsReq;
  };

  @action clearAccount = () => {
    this.account = undefined;
  };

  @action setEditGeneralInfo = (val: boolean) => this.editGeneralInfo = val;

  @action collapseLeftBar = (force?: boolean) => {
    if (force !== undefined) {
      this.isOpenLeftBar = force;
    } else {
      this.isOpenLeftBar = !this.isOpenLeftBar;
    }
  };

  @action collapseAll = (force?: boolean) => {
    if (force !== undefined) {
      this.isCollapseAll = force;
    } else {
      this.isCollapseAll = !this.isCollapseAll;
    }
  };

  @action resetAllCollapseState = () => this.isCollapseAll = undefined;

  @action setSearchOpen = (isFilterOpen?: boolean) => {
    this.isFilterOpen = isFilterOpen !== undefined ? isFilterOpen : !this.isFilterOpen;
  };

  @action fetchAccountContacts = (async () => {
    if (!this.account?.id) return;
    addressBookStore.setRelatedToContact({
      relatedTypeId: ACCOUNT_TYPE_OBJECT.id,
      relatedId: this.account?.id,
    });
    await addressBookStore.fetchContacts();
  });

  @action addRelatedTo = () => this.isAddRelated = true;

  @action closeRelatedTo = () => this.isAddRelated = false;

  @action attachNewRelated = async (accountId: number) => {
    if (!this.account?.id) return;
    this.attachRelatedToReq = this.apiAccount.createRelatedAccount(accountId, this.account?.id);

    await this.attachRelatedToReq;
    await this.account?.fetchRelatedAccounts();
  };

  @action pageInitCall = async (accountId: number) => {
    this.assets.filter.setPersistedFilters({
      'q.generator.id': accountId,
    });
    this.services.filter.setPersistedFilters({
      'q.generator.id': accountId,
    });
    await Promise.all([
      await this.fetchAccountDetails(accountId),
      await this.fetchAccountHaulers(accountId),
    ]);
  };

  @action createGenerator = async () => {
    if (!this.account?.id) return;
    const { addressGeneratorId } = this.account;
    const dto: GeneratorCreationType = {
      addressLine1: this.account.addressLine1,
      addressLine2: this.account.addressLine2,
      city: this.account.city,
      state: this.account.state,
      zipCode: this.account.zipCode,
      id: addressGeneratorId,
    };
    this.changeGeneratorReq = this.apiAccount.manageGenerator<GeneratorCreationType>(this.account.id, dto);
    await this.changeGeneratorReq;
    if (this.changeGeneratorDetails) {
      this.account = new AccountModification(this.changeGeneratorDetails);
    }
  };

  @action fetchLocationByID = async (id: number) => this.account?.fetchLocationByID(id);

  @action reset = () => {
    this.resetAllCollapseState();
    this.services.resetStore();
    this.assets.resetStore();
    this.clearAccount();
    this.setEditGeneralInfo(false);
  };

  @action updateAccountGeneralInfo = async (data: EditFormType) => {
    try {
      const dto = {
        ...data,
        location: { ...data.generatorId, reference: data.generatorId.name },
      };

      this.account?.setEntity(dto);
      if (data.type.id !== this.account?.defaultState?.type?.id)
        await this.account?.editDetails();
      if ((this.account?.defaultState.location && isAccountDataChanged(this.account?.defaultState.location, dto)))
        await this.createGenerator();

    } finally {
      this.setEditGeneralInfo(false);
    }
  };
}

export const { store: accountDetailsStore, storeCtx: accountDetailsStoreCtx } = storeFactory(
  AccountDetailsStore,
  'accountDetails',
);
