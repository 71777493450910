import amber from '@mui/material/colors/amber';
import blue from '@mui/material/colors/blue';
import blueGrey from '@mui/material/colors/blueGrey';
import brown from '@mui/material/colors/brown';
import cyan from '@mui/material/colors/cyan';
import deepOrange from '@mui/material/colors/deepOrange';
import deepPurple from '@mui/material/colors/deepPurple';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import indigo from '@mui/material/colors/indigo';
import lightBlue from '@mui/material/colors/lightBlue';
import lightGreen from '@mui/material/colors/lightGreen';
import lime from '@mui/material/colors/lime';
import orange from '@mui/material/colors/orange';
import pink from '@mui/material/colors/pink';
import purple from '@mui/material/colors/purple';
import red from '@mui/material/colors/red';
import teal from '@mui/material/colors/teal';
import yellow from '@mui/material/colors/yellow';
import diff from 'lodash/difference';
import random from 'lodash/random';

import { theme } from './theme/minerva-main';

const beigeish = ['#E1DABD', '#BDC3A1', '#97AD89', '#6F9776'];
const purpleish = ['#FFE4FA', '#FFD7D4', '#FFD9AD', '#D0CAEB'];
const pinkish = ['#FFC4EB', '#FFC1D9', '#FFC3BB', '#FFE5FF'];
const lightPinkish = ['#F1DEDC', '#D9C0C6', '#BCA4B5', '#978CA7'];
const blueish = ['#CFF6FF', '#A0B3D6', '#A3FFE7', '#A3FFE7', '#AAE8E8'];
const greenish = ['#DFE5CB', '#B1C6B1', '#86A69B', '#90B39A', '#ECF5CE'];
const yellowish = ['#FCFCD4', '#F3F3CC', '#FFEAB3', '#FFDBA2'];

const colors = [
  ...beigeish,
  ...purpleish,
  ...pinkish,
  ...lightPinkish,
  ...blueish,
  ...greenish,
  ...yellowish,
];

const muiColors = [
  red,
  purple,
  indigo,
  blue,
  amber,
  deepOrange,
  deepPurple,
  cyan,
  lightBlue,
  pink,
  teal,
  green,
  lightGreen,
  yellow,
  lime,
  orange,
  brown,
  grey,
  blueGrey,
];

export const createColorGetter = () => {
  let usedColorsCache: Record<string, string> = {};

  return (title: string): string => {
    if (usedColorsCache[title]) {
      return usedColorsCache[title];
    }

    const unusedColors = diff(colors, Object.values(usedColorsCache));
    const newColor = unusedColors[random(0, unusedColors.length - 1)];

    usedColorsCache[title] = newColor;

    return newColor;
  };
};

export const percentToHex = (value: number) => {
  const percent = Math.max(0, Math.min(100, value)); // bound percent from 0 to 100
  const intValue = Math.round((percent / 100) * 255); // map percent to nearest integer (0 - 255)
  const hexValue = intValue.toString(16); // get hexadecimal representation
  return hexValue.padStart(2, '0').toUpperCase(); // format with leading 0 and upper case characters
};

export const generateColoredBoxItem = (id: number) => {
  return {
    color: muiColors[id][600],
    borderColor: muiColors[id][600],
    backgroundColor: muiColors[id][50],
  };
};

export const generateSystemTypeColors = (id: number, name: string) => {
  const getColor = createColorGetter();
  const colorTypeMap = [
    theme.palette.warning.main,
    theme.palette.success.main,
    theme.palette.primary.light,
    theme.palette.minervaColors.primaryViolet,
    theme.palette.error.main,
    theme.palette.minervaColors.primaryRed,
  ];
  const bgColorTypeMap = [
    theme.palette.minervaColors.secondaryOrange,
    theme.palette.minervaColors.secondaryGreen,
    theme.palette.minervaColors.secondaryBlue,
    theme.palette.minervaColors.secondaryViolet,
    theme.palette.minervaColors.secondaryOrange,
    theme.palette.minervaColors.secondaryRed,
  ];

  const totalColors = colorTypeMap.length;
  return {
    color: id <= totalColors ? colorTypeMap[id - 1] : getColor(name),
    borderColor: id <= totalColors ? colorTypeMap[id - 1] : getColor(name),
    backgroundColor:
      id <= totalColors ? bgColorTypeMap[id - 1] : getColor(`${name}1`),
  };
};
const activityTypesColorMap = [
  {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    backgroundColor: theme.palette.minervaColors.secondaryOrange,
  },
  {
    color: 'rgb(57, 73, 127)',
    borderColor: 'rgb(170, 185, 236)',
    backgroundColor: 'rgb(147, 169, 241, 0.5)',
  },
  {
    color: 'rgb(128,78,72)',
    borderColor: 'rgb(199,131,122)',
    backgroundColor: 'rgb(235, 179, 171, 0.5)',
  },
  {
    color: 'rgb(40, 109, 117)',
    borderColor: 'rgb(101, 159, 223)',
    backgroundColor: 'rgb(215, 240, 247)',
  },
  {
    color: 'rgb(119, 76, 128)',
    borderColor: 'rgb(172,134,180)',
    backgroundColor: 'rgb(240, 211, 248, 0.5)',
  },
  {
    color: theme.palette.minervaColors.primaryRed,
    borderColor: 'rgb(201,108,53)',
    backgroundColor: theme.palette.minervaColors.secondaryRed,
  },
  {
    color: 'rgb(64,136,69)',
    borderColor: 'rgb(64,136,69)',
    backgroundColor: 'rgb(112, 187, 117, 0.5)',
  },
  {
    color: 'rgb(110,82,157)',
    borderColor: 'rgb(110,82,157)',
    backgroundColor: 'rgb(179, 148, 223, 0.5)',
  },
  {
    color: 'rgb(75,190,196)',
    borderColor: 'rgb(75,190,196)',
    backgroundColor: 'rgb(135, 247, 253, 0.5)',
  }
];

export const generateActivityTypeColors = (id: number, name: string) => {
  const getColor = createColorGetter();

  const typeColor = activityTypesColorMap[id - 1] ?? {
    color: getColor(name),
    borderColor: getColor(name),
    backgroundColor: getColor(`${name}1`),
  }
  return typeColor;
};
