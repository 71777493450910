import { FoodRecoveryItem } from '../../api/api-types/foods';

import { ItemType } from '../../types';
import { ContactShortType } from '../../api/api-types/address-book';

export class EFRItem implements FoodRecoveryItem {
  readonly id: number;
  readonly parentJurisdiction: ItemType;
  readonly jurisdiction: ItemType;
  readonly reference: string;
  readonly name: string;
  readonly addressLine1: string;
  readonly addressLine2: string;
  readonly city: string;
  readonly state: string;
  readonly zipCode: string;
  readonly contact: ContactShortType;
  readonly usaAddress: string;
  readonly type: ItemType;
  readonly totalPounds: number;
  readonly annualCapacity: number;
  readonly country: string;
  readonly foodWillingTypes: (ItemType | null)[];
  readonly description: string | undefined;
  readonly descriptionTruncated?: boolean

  constructor({
    id,
    name,
    reference,
    parentJurisdiction,
    jurisdiction,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    contact,
    usaAddress,
    type,
    totalPounds,
    annualCapacity,
    country,
    foodWillingTypes,
    description,
    descriptionTruncated
  }: FoodRecoveryItem) {
    this.id = id;
    this.name = name;
    this.reference = reference;
    this.parentJurisdiction = parentJurisdiction;
    this.jurisdiction = jurisdiction;
    this.addressLine1 = addressLine1;
    this.addressLine2 = addressLine2;
    this.city = city;
    this.state = state;
    this.zipCode = zipCode;
    this.contact = contact;
    this.usaAddress = usaAddress;
    this.type = type;
    this.totalPounds = totalPounds;
    this.annualCapacity = annualCapacity;
    this.country = country;
    this.foodWillingTypes = foodWillingTypes;
    this.description = description;
    this.descriptionTruncated = descriptionTruncated;
  }
}
