import { useContext, useEffect } from 'react';

import { SelectChangeEvent } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import CategoryItem from 'src/components/CategoryItem';
import { lookupsStoreCtx } from 'src/stores/lookups';
import { ItemType } from 'src/types';
import { isPending } from 'src/utils/common';

import { WaiverTypeId } from '../CreateActivity/type';

import { storeManager } from './CellManager/store';

interface SelectTypeProps {
  id: number | string;
  value: ItemType;
  field: string;
}

export const SelectTypeComponent = observer(
  ({ id, field, value }: SelectTypeProps) => {
    const {
      fetchAssetCategories,
      fetchAssetSubCategories,
      fetchAssetsWaiverStatus,
      assetTypes,
      clientTypesReq,
    } = useContext(lookupsStoreCtx);
    const apiRef = useGridApiContext();
    const store = storeManager.getStore(value);

    useEffect(() => {
      store.addSubscriber();

      return () => {
        store.removeSubscriber();
        storeManager.releaseStore();
      };
    }, [store]);

    const changeCellHandler = (
      fieldName: string,
      cellValue: ItemType | null,
    ) => {
      return apiRef.current.setEditCellValue({
        id,
        field: fieldName,
        value: cellValue,
      });
    };

    const handleChange = async (_event: SelectChangeEvent, child?: any) => {
      const newType = { id: child?.props.value, name: child?.props?.children };
      await changeCellHandler(field, newType);

      if (newType.id === WaiverTypeId) {
        const waiverStatusRes = await fetchAssetsWaiverStatus();
        waiverStatusRes?.data &&
          (await changeCellHandler(
            'waiverInfoStatusId',
            waiverStatusRes.data?.[0],
          ));
        store.setValue(newType);
      } else {
        await changeCellHandler('waiverInfoStatusId', null);
        store.setValue(null);
      }

      const categoriesRes = await fetchAssetCategories(Number(newType.id));
      categoriesRes?.data &&
        (await changeCellHandler('category', categoriesRes.data?.[0]));
      const subCategoriesRes =
        categoriesRes?.data &&
        (await fetchAssetSubCategories(categoriesRes.data[0]?.id));
      subCategoriesRes?.data &&
        (await changeCellHandler('subCategory', subCategoriesRes.data?.[0]));
    };

    return (
      <Select
        value={String(value?.id)}
        fullWidth
        onChange={handleChange}
        endAdornment={
          <>
            {isPending(clientTypesReq) ? (
              <CircularProgress color="inherit" size={20} />
            ) : null}
          </>
        }>
        {assetTypes?.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {field.includes('type') ? name : <CategoryItem name={name} />}
          </MenuItem>
        ))}
      </Select>
    );
  },
);
