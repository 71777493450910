import { useContext, useEffect } from 'react';

import { SelectChangeEvent } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import CategoryItem from 'src/components/CategoryItem';
import { lookupsStoreCtx } from 'src/stores/lookups';
import { ItemIdType, ItemType } from 'src/types';
import { isPending } from 'src/utils/common';

import { WaiverTypeId } from '../CreateActivity/type';

import { storeManager } from './CellManager/store';

interface SelectTypeProps {
  id: number | string;
  value: ItemType;
  field: string;
}

export const SelectAssetWaiverStatusComponent = observer(
  ({ id, field, value }: SelectTypeProps) => {
    const { assetsWaiverStatus, assetsWaiverStatusLookupReq } =
      useContext(lookupsStoreCtx);
    const apiRef = useGridApiContext();
    const store = storeManager.getStore();
    useEffect(() => {
      store.addSubscriber();

      return () => {
        store.removeSubscriber();
        storeManager.releaseStore();
      };
    }, [store]);

    const changeCellHandler = (fieldName: string, cellValue: ItemIdType) => {
      return apiRef.current.setEditCellValue({
        id,
        field: fieldName,
        value: cellValue,
      });
    };

    const handleChange = async (_event: SelectChangeEvent, child?: any) => {
      const newType = {
        id: child?.props.value,
        name: child?.props.children.props.name,
      };
      await changeCellHandler(field, newType);
    };

    if (store.activityType?.id !== WaiverTypeId) {
      return null;
    }

    return (
      <Select
        value={String(value?.id)}
        fullWidth
        onChange={handleChange}
        endAdornment={
          <>
            {isPending(assetsWaiverStatusLookupReq) ? (
              <CircularProgress color="inherit" size={20} />
            ) : null}
          </>
        }>
        {assetsWaiverStatus?.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            <CategoryItem name={name} />
          </MenuItem>
        ))}
      </Select>
    );
  },
);
