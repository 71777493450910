import axios, { AxiosResponse } from 'axios';
import { computed } from 'mobx';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';

import { authStore } from 'src/stores/auth';
import { ApiError, globalViewModelStore } from 'src/stores/global-vm';
import { authenticationParameters } from 'src/config/auth-config';
import { userStore } from 'src/stores/user';

interface RequestProps<P, D = {}> {
  method: 'put' | 'post' | 'get' | 'delete';
  url: string;
  params?: P;
  data?: D; //AxiosRequestConfig
}

export type ApiReq<T> = IPromiseBasedObservable<AxiosResponse<T | null>>;
export const emptyValue = fromPromise.resolve({ data: null }) as ApiReq<null>;

export class ApiClient {
  private readonly prefix: string;

  constructor({ prefix }: { prefix: string } = { prefix: '' }) {
    this.prefix = prefix;
  }

  @computed get headersProps() {
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${authStore.token}`,
    };
  }

  @computed get headers() {
    if (userStore.selectedJurisdictionId) {
      return {
        ...this.headersProps,
        'Minerva-Jurisdiction': userStore.selectedJurisdictionId.id,
      };
    }
    return this.headersProps;
  }

  protected get env() {
    return {
      __domain: process.env.REACT_APP_Domain,
      __apiVersion: process.env.REACT_APP_ApiVersion,
      __fileApi: process.env.REACT_APP_FilesApi,
      __addressApi: process.env.REACT_APP_AddressesApi,
    };
  }

  protected get apiBase() {
    return `${this.env.__domain}/${this.env.__apiVersion}${this.prefix}`;
  }

  requestGet<T, P = {}>(url: string, params?: P, config?: any) {
    return this.requestDataApi<T, P>({ method: 'get', url, params, ...config });
  }

  requestGetV2<T, P = {}>(url: string, params?: P, config?: any) {
    return this.requestDataApiV2<T, P>({
      method: 'get',
      url,
      params,
      ...config,
    });
  }

  requestDelete<T, P = {}>(url: string, params?: P) {
    return this.requestDataApi<T, P>({ method: 'delete', url, params });
  }

  requestPost<T, D, P = {}>(url: string, data?: D, params?: P) {
    return this.requestDataApi<T, P, D>({ method: 'post', url, data, params });
  }

  requestPut<T, D, P = {}>(url: string, data?: D, params?: P) {
    return this.requestDataApi<T, P, D>({ method: 'put', url, data, params });
  }

  requestExportGet<T, P = {}>(url: string, params?: P) {
    const config = { responseType: 'blob' };
    return this.requestDataApi<T, P>({ method: 'get', url, params, ...config });
  }

  requestExportPost<T, D, P = {}>(url: string, data?: D, params?: P) {
    const config = { responseType: 'blob' };
    return this.requestDataApi<T, P, D>({
      method: 'post',
      url,
      data,
      params,
      ...config,
    });
  }

  requestFiles<T, P = {}>(url: string, params?: P) {
    const config = { responseType: 'blob' };
    return this.requestFilesApi<T, P>({
      method: 'get',
      url,
      params,
      ...config,
    });
  }

  requestFilesDelete<T, P = {}>(url: string, params?: P) {
    return this.requestFilesApi<T, P>({
      method: 'delete',
      url,
      params,
    });
  }

  requestUploadFile<T, P = {}>(
    url: string,
    file: File,
    params?: P,
    progressCallback?: (progress: number) => void,
  ) {
    let formData = new FormData();
    formData.append('file', file);
    const config = {
      data: formData,
      onUploadProgress: (p: any) => {
        progressCallback && progressCallback(p.loaded / p.total);
      },
      headers: this.headers,
    };

    progressCallback && progressCallback(1);
    return this.requestFilesApi<T, P>({
      method: 'post',
      url: url,
      params,
      ...config,
    });
  }

  requestGeneratorsAddresses<T, P = {}>(url: string, params?: P) {
    return this.requestAddressesApi<T, P>({
      method: 'get',
      url,
      params,
    });
  }

  getUploadToken(assetId: number) {
    return this.requestGet<{ token: string }>(
      `activities/${assetId}/attachments/upload-token`,
    );
  }

  private getUrl(url: string, apiVersion?: string) {
    const shouldIgnorePrefix = url.startsWith('$');
    const safeUrl = shouldIgnorePrefix ? url.replace('$', '') : url;

    if (apiVersion) {
      return `${this.env.__domain}/${apiVersion}/${safeUrl}`;
    }

    if (shouldIgnorePrefix) {
      return `${this.env.__domain}/${this.env.__apiVersion}/${safeUrl}`;
    }

    return `${this.apiBase}/${url}`;
  }

  private request<T = {}, P = {}, D = {}>({
    method,
    params,
    url,
    ...config
  }: RequestProps<P, D>): Promise<AxiosResponse<T | null>> {
    const performRequest = async () => {
      try {
        const response = await axios.request({
          method,
          params,
          url,
          headers: this.headers,
          ...config,
        });

        return response;
      } catch (error) {
        // Request error
        // eslint-disable-next-line no-console
        console.error('Request error:', error);
        throw error;
      }
    };
    return performRequest().catch(async error => {
      // eslint-disable-next-line no-console
      console.log(error);
      if (error.response && error.response.status === 401) {
        try {
          // Refresh token
          await authStore.setAuthResult();

          return await performRequest();
        } catch (refreshError) {
          // Refresh token Error
          // eslint-disable-next-line no-console
          console.error('Refresh token Error:', refreshError);
          throw refreshError;
        }
      } else if (error.response && error.response.status === 500) {
        globalViewModelStore.setApiErrors([
          { message: 'Server Error occurred', code: 'InternalServerError' },
        ]);
      } else if (Array.isArray(error?.response?.data?.errors)) {
        globalViewModelStore.setApiErrors(error?.response?.data?.errors || []);
      } else {
        const mappedErrors: ApiError[] = Object.values(
          error?.response?.data?.errors,
        ).map(x => ({
          message: x as string,
          code: 'ValidationError',
          type: error?.response?.data?.type,
        }));
        globalViewModelStore.setApiErrors(mappedErrors || []);
      }

      throw error;
    });
  }

  private requestDataApiV2<T = {}, P = {}, D = {}>({
    method,
    url,
    params,
    data,
    ...config
  }: RequestProps<P, D>): ApiReq<T> {
    return fromPromise(
      this.request<T, P, D>({
        method,
        data,
        params,
        url: this.getUrl(url, 'api/v2'), //todo custom api version need refactoring
        ...config,
      }),
    );
  }

  private requestDataApi<T = {}, P = {}, D = {}>({
    method,
    url,
    params,
    data,
    ...config
  }: RequestProps<P, D>): ApiReq<T> {
    return fromPromise(
      this.request<T, P, D>({
        method,
        data,
        params,
        url: this.getUrl(url),
        ...config,
      }),
    );
  }

  private requestFilesApi<T = {}, P = {}>({
    method,
    params,
    url,
    ...config
  }: RequestProps<P>): ApiReq<T> {
    return fromPromise(
      axios
        .request({
          method,
          params: { ...params },
          url: `${this.env.__fileApi}/v1/${url}`,
          headers: this.headers,
          ...config,
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            authStore.msalInstance.acquireTokenRedirect(
              authenticationParameters,
            );
          }
          if (error.response && error.response.status === 403) {
            globalViewModelStore.setApiErrors([
              { message: 'Server Error ocurred', code: 'InternalServerError' },
            ]);
          }
          if (error.response && error.response.status === 500) {
            globalViewModelStore.setApiErrors([
              { message: 'Server Error ocurred', code: 'InternalServerError' },
            ]);
          }
          if (Array.isArray(error?.response?.data?.errors)) {
            globalViewModelStore.setApiErrors(
              error?.response?.data?.errors || [],
            );
          } else {
            const mappedErrors: ApiError[] = Object.values(
              error?.response?.data?.errors,
            ).map(x => ({
              message: x as string,
              code: 'ValidationError',
            }));
            globalViewModelStore.setApiErrors(mappedErrors || []);
          }

          throw error;
        }),
    );
  }

  private requestAddressesApi<T = {}, P = {}>({
    method,
    params,
    url,
    ...config
  }: RequestProps<P>): ApiReq<T> {
    return fromPromise(
      axios
        .request({
          method,
          params: { ...params },
          url: `${this.env.__addressApi}/${url}`,
          headers: this.headers,
          ...config,
        })
        .catch(error => {
          throw error;
        }),
    );
  }
}
