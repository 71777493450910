import { AsyncExportResponse } from 'src/types';
import {
  SubCategoryDetail,
  RecordCategoriesResponse,
  RecordPeriod,
  SubCategoryNote,
  LegalSectionDetail,
  IRExportParams,
} from './api-types/implementation-record';
import { AssetsApi } from './assets';

export class ImplementationRecordApi extends AssetsApi {
  getImplementationRecordPeriods() {
    return this.requestGet<RecordPeriod[]>('implementation-record/periods');
  }

  getImplementationRecordCategories(periodId: number) {
    return this.requestGetV2<RecordCategoriesResponse>(
      'implementation-record/categories',
      {
        'q.period.id': periodId,
      },
    );
  }

  getRecordSubCategoryDetails(id: number) {
    return this.requestGetV2<SubCategoryDetail>(
      `implementation-record/sub-categories/${id}`,
    );
  }

  getSubCategoryNote(id: number) {
    return this.requestGet<SubCategoryNote>(
      `implementation-record/sub-categories/${id}/notes`,
    );
  }

  updateSubCategoryNote(id: number, note: SubCategoryNote) {
    return this.requestPut<SubCategoryDetail, SubCategoryNote>(
      `implementation-record/sub-categories/${id}/notes`,
      note,
    );
  }

  getLegalSectionDetail(id: number) {
    return this.requestGet<LegalSectionDetail>(
      `implementation-record/legal-sections/${id}`,
    );
  }

  exportImplementationRecord(params: IRExportParams) {
    return this.requestPost<AsyncExportResponse, IRExportParams>(
      'implementation-record/export',
      params,
    );
  }
}
