import { ServiceHistory } from 'src/models/servise/service-history';
import { AssetItem } from 'src/api/api-types/assets';
import { AccountItem, AccountsFilter } from 'src/api/api-types/accounts';
import { AssetFiltersType } from 'src/api/api-types/assets';
import {
  GeneratorItem,
  GeneratorsFilterType,
} from '../../api/api-types/generators';
import { ServiceItem, ServicesFilter } from '../../api/api-types/services';
import { FoodFilterType, FoodRecoveryItem } from '../../api/api-types/foods';
import { FoodGeneratorsFilter } from '../../api/api-types/food-generators';
import { ContactType } from 'src/api/api-types/address-book';
import { UserItem } from 'src/api/api-types/user';
import { ContainerItem } from 'src/api/api-types/containers';
import { EFGItem } from '../edible-food/efg-item';

type AccountsNameMapType = Record<string, keyof AccountsFilter>;
type AssetNameMapType = Record<string, keyof AssetFiltersType>;
type GeneratorsNameMapType = Record<string, keyof GeneratorsFilterType>;
type EFRFilterNameMapType = Record<string, keyof FoodFilterType>;
export type EFRHiddenColumnsType = keyof FoodRecoveryItem &
  keyof Pick<ContactType, 'phone' | 'email'>;
type EFRExportColumnsNameMapType = Record<EFRHiddenColumnsType, string>;

export type EFGHiddenColumnsType = keyof EFGItem &
  keyof Pick<ContactType, 'phone' | 'email'>;
type EFGExportColumnsNameMapType = Record<EFGHiddenColumnsType, string>;

type GeneratorExportColumnsNameMapType = Record<keyof GeneratorItem, string>;

export type ServicesHiddenColumnsType = keyof ServiceItem;
type ServicesExportColumnsNameMapType = Record<
  ServicesHiddenColumnsType,
  string
>;

type ServicesNameMapType = Record<string, keyof ServicesFilter>;
type foodGeneratorMapType = Record<string, keyof FoodGeneratorsFilter>;

type ServicesHistoryExportColumnsNameMapType = Record<
  string,
  string | keyof ServiceHistory
>;

export type UserHiddenColumnsType = keyof Omit<
  UserItem,
  'id' | 'createdAt' | 'updatedAt' | 'updatedBy'
>;
type UserExportColumnsNameMapType = Record<UserHiddenColumnsType, string>;

export type ContainersNameMapType = keyof ContainerItem;
type ContainerExportColumnsNameMapType = Partial<
  Record<ContainersNameMapType, string>
>;

export type AssetsNameMapType = keyof AssetItem;
export type WaiverExportType = 'waiverInfoStatusId' | 'waiverInfoFollowUpDate';
type AssetsExportColumnsNameMapType = Partial<
  Record<AssetsNameMapType | WaiverExportType, string>
>;

export type AccountsExportNameMapType = keyof AccountItem;
type AccountsExportColumnsNameMapType = Partial<
  Record<AccountsExportNameMapType, string>
>;

export const ScheduleWeeks = [
  'Schedule.Sunday',
  'Schedule.Monday',
  'Schedule.Tuesday',
  'Schedule.Wednesday',
  'Schedule.Thursday',
  'Schedule.Friday',
  'Schedule.Saturday',
];

export const accountFilterNameMap: AccountsNameMapType = {
  accountAddress: 'q.usaAddress',
  accountGeneratorId: 'q.location.reference',
  accountName: 'q.name',
  accountType: 'q.type.id',
  agency: 'q.agencies',
  extensionField1: 'q.extensionFields1',
  extensionField2: 'q.extensionFields2',
  hauler: 'q.haulers',
  jurisdiction: 'q.jurisdictions',
  reference: 'q.reference',
  type: 'q.types',
  zone: 'q.zones',
};

export const assetsFilterNameMap: AssetNameMapType = {
  accountAddress: 'q.generator.usaAddress',
  accountGeneratorId: 'q.parentLocation.reference',
  accountName: 'q.generator.name',
  accountNumber: 'q.generator.accountNumber',
  accountType: 'q.accountTypes',
  category: 'q.categoryIds',
  generatorAddress: 'q.parentLocation.id',
  generatorMinervaId: 'q.generator.reference',
  hauler: 'q.haulers',
  jurisdiction: 'q.jurisdictions',
  subCategory: 'q.subCategoryIds',
  type: 'q.types',
  waiverInfoStatusId: 'q.waiverInfoStatuses',
};

export const generatorsFilterNameMap: GeneratorsNameMapType = {
  accountMinervaId: 'q.account.reference',
  accountName: 'q.account.name',
  accountNumber: 'q.account.accountNumber',
  types: 'q.types',
  accountType: 'q.account.types',
  addressLine1: 'q.account.usaAddress',
  agency: 'q.agencies',
  collectionSystem: 'q.collectionSystems',
  hauler: 'q.haulers',
  jurisdiction: 'q.jurisdictions',
  reference: 'q.reference',
};

export const servicesFilterNameMap: ServicesNameMapType = {
  accountId: 'q.generator.id',
  accountType: 'q.accountTypes',
  generatorId: 'q.location.id',
  hauler: 'q.haulers',
  jurisdiction: 'q.jurisdictions',
  serviceWasteType: 'q.serviceWasteTypes',
  unitOfMeasure: 'q.unitsOfMeasure',
};

export const EFRFilterNameMap: EFRFilterNameMapType = {
  type: 'q.types',
  jurisdiction: 'q.jurisdictions',
};

export const foodGeneratorsNameMap: foodGeneratorMapType = {
  jurisdiction: 'q.jurisdictions',
  type: 'q.types',
  tier: 'q.tiers',
};

export enum ActiveFilterType {
  'ActiveOnly' = 1,
  'Inactive' = 2,
  'All' = 3,
}

export const EFRExportColumnsNameMap: EFRExportColumnsNameMapType = {
  reference: 'reference',
  jurisdiction: 'jurisdiction.name',
  type: 'type.name',
  name: 'name',
  usaAddress: 'usaAddress',
  contact: 'contact.fullname',
  phone: 'contact.phone',
  email: 'contact.email',
};

export const ServiceHistoryExportColumnsNameMap = (
  columns: (keyof ServiceHistory)[],
) => {
  const tmp: string[] = [];
  const serviceHistoryColumnsMap: Partial<ServicesHistoryExportColumnsNameMapType> =
    {
      jurisdiction: 'jurisdiction.name',
      accountType: 'accountType.Name',
      generator: 'generator.Name',
      location: 'location.Name',
      containerQuantity: 'containerQuantity',
      container: 'container.Name',
      serviceWasteType: 'serviceWasteType.Name',
      unitOfMeasure: 'unitOfMeasure.Name',
    };
  columns.forEach(column => {
    if (column === 'schedule') return tmp.push(...ScheduleWeeks);
    tmp.push(serviceHistoryColumnsMap[column] || column);
  });
  return tmp;
};

export const GeneratorExportColumnsNameMap: Partial<GeneratorExportColumnsNameMapType> =
  {
    isActive: 'IsActive',
    reference: 'Reference',
    accountMinervaId: 'AccountMinervaId',
    agency: 'Agency.Name',
    nickname: 'Nickname',
    jurisdiction: 'Jurisdiction.Name',
    hauler: 'Hauler.Name',
    accountNumber: 'AccountNumber',
    types: 'Type.Name',
    accountType: 'AccountType.Name',
    accountName: 'AccountName',
    accountAddress: 'AccountAddress.Name',
    statusDate: 'StatusDate',
    subjectTo: 'SubjectTo',
    recycling: 'Recycling',
    collectionSystem: 'CollectionSystem.Name',
    waiver: 'Waiver',
    deMinimisWaiver: 'DeMinimisWaiver',
    spaceWaiver: 'SpaceWaiver',
    departmentWaiver: 'DepartmentWaiver',
    foodAndSoiledPaperGenerates: 'FoodAndSoiledPaperGenerates',
    foodAndSoiledPaperRecycling: 'FoodAndSoiledPaperRecycling',
    greenMaterialGenerates: 'GreenMaterialGenerates',
    greenMaterialRecycling: 'GreenMaterialRecycling',
    landscapeAndPrunningGenerates: 'LandscapeAndPrunningGenerates',
    landscapeAndPrunningRecycling: 'LandscapeAndPrunningRecycling',
    lumberGenerates: 'LumberGenerates',
    lumberRecycling: 'LumberRecycling',
    manureGenerates: 'ManureGenerates',
    manureRecycling: 'ManureRecycling',
    textilesGenerates: 'TextilesGenerates',
    textilesRecycling: 'TextilesRecycling',
    paperProductsGenerates: 'PaperProductsGenerates',
    paperProductsRecycling: 'PaperProductsRecycling',
    printingAndWritingPaperGenerates: 'PrintingAndWritingPaperGenerates',
    printingAndWritingPaperRecycling: 'PrintingAndWritingPaperRecycling',
    woodGenerates: 'WoodGenerates',
    woodRecycling: 'WoodRecycling',
    nonOrganicGenerates: 'NonOrganicGenerates',
    nonOrganicRecycling: 'NonOrganicRecycling',
    isCefg: 'CefgTier.Name',
    moreRequired: 'MoreRequired',
    moreNotRecycling: 'MoreNotRecycling',
    mcrRequired: 'McrRequired',
    mcrNotRecycling: 'McrNotRecycling',
  };
export const ServicesExportColumnsNameMap = (
  columns: ServicesHiddenColumnsType[],
) => {
  const tmp: string[] = [];
  const servicesColumnsMap: Partial<ServicesExportColumnsNameMapType> = {
    generatorId: 'GeneratorId',
    minervaId: 'MinervaId',
    jurisdiction: 'Jurisdiction.Name',
    hauler: 'Hauler.Name',
    accountNumber: 'AccountNumber',
    dataDate: 'DataDate',
    startDate: 'StartDate',
    accountType: 'AccountType.Name',
    generator: 'Generator.Name',
    location: 'Location.Name',
    containerQuantity: 'ContainerQuantity',
    container: 'Container.Name',
    serviceWasteType: 'ServiceWasteType.Name',
    containerType: 'ContainerType',
    containerSize: 'ContainerSize',
    unitOfMeasure: 'UnitOfMeasure.Name',
    weeklyFrequency: 'WeeklyFrequency',
    weeklyVolumeCubicYards: 'WeeklyVolumeCubicYards',
    onCall: 'OnCall',
    site: 'Site',
    containerGroup: 'ContainerGroup',
  };
  columns.forEach(column => {
    if (column === 'schedule') return tmp.push(...ScheduleWeeks);
    tmp.push(servicesColumnsMap[column] || column);
  });
  return tmp;
};

export const UsersExportColumnNameMap: UserExportColumnsNameMapType = {
  active: 'Active',
  firstName: 'FirstName',
  lastName: 'LastName',
  email: 'Email',
  clientType: 'ClientType.Name',
  employer: 'Employer',
  client: 'Client.Name',
  agencies: 'Agencies.Name',
  jurisdictions: 'Jurisdictions.Name',
  haulers: 'Haulers.Name',
  createdBy: 'CreatedBy.Name',
  lastLoggedIn: 'LastLoggedIn',
};

export const ContainersExportColumnsNameMap: ContainerExportColumnsNameMapType =
  {
    name: 'Name',
    jurisdiction: 'Jurisdiction.Name',
    hauler: 'Hauler.Name',
    createdAt: 'CreatedAt',
    wasteMaterialTypes: 'WasteMaterialTypes.Name',
  };

export const AssetsExportColumnsNameMap: AssetsExportColumnsNameMapType = {
  type: 'Type.Name',
  category: 'Category.Name',
  subCategory: 'SubCategory.Name',
  jurisdiction: 'Jurisdiction.Name',
  hauler: 'Hauler.Name',
  account: 'Account.AccountNumber',
  accountType: 'AccountType.Name',
  generator: 'Generator.Name',
  createdBy: 'CreatedBy.Name',
  waiverInfoStatusId: 'WaiverInfo.Status.Name',
  waiverInfoFollowUpDate: 'WaiverInfo.FollowUpDate',
};
export const EFGExportColumnsNameMap: EFGExportColumnsNameMapType = {
  location: 'Location.Reference',
  reference: 'Reference',
  jurisdiction: 'Jurisdiction.Name',
  name: 'Name',
  usaAddress: 'UsaAddress',
  type: 'Type.Name',
  tier: 'Tier.Name',
  fullName: 'Contact.FullName',
  phone: 'Contact.Phone',
  email: 'Contact.Email',
  foodRecoveries: 'FoodRecoveries.Name',
  sqFeet: 'SqFeet',
  rooms: 'Rooms',
  beds: 'Beds',
  seats: 'Seats',
  onSiteFoodFacility: 'OnSiteFoodFacility',
};

export const ContactExportColumnsNameMap = (columns: string[]) => {
  return columns.map(column => {
    if (column === 'type') return 'Type.Name';
    return column;
  });
};

export const AccountsExportColumnNameMap: AccountsExportColumnsNameMapType = {
  jurisdiction: 'Jurisdiction.Name',
  hauler: 'Hauler.Name',
  agency: 'Agency.Name',
  type: 'Type.Name',
  accountAddress: 'AccountAddress.Name',
  zone: 'Zone.Name',
  extensionField1: 'ExtensionField1.Name',
  extensionField2: 'ExtensionField2.Name',
};
