import { SyntheticEvent } from 'react';
import * as React from 'react';

import { useAutocomplete } from '@mui/base';
import {
  Autocomplete,
  Box,
  CircularProgress,
  InternalStandardProps as StandardProps,
  TextField,
  Theme,
} from '@mui/material';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';
import { SxProps } from '@mui/system';
import debounce from 'lodash/debounce';
import { Controller, useFormContext } from 'react-hook-form';
import { DEBOUNCE_DURATION } from 'src/utils/constants';

export interface AutocompleteProps<T>
  extends StandardProps<
    React.HTMLAttributes<HTMLDivElement>,
    'defaultValue' | 'onChange' | 'children'
  > {
  name: string;
  sx?: SxProps<Theme>;
  options: T[];
  loading?: boolean;
  asyncSearch?: boolean;
  placeholder?: string;
  disabled?: boolean;
  disableClearable?: boolean;
  disablePortal?: boolean;
  request?: (value?: string) => Promise<any>;
  onChange?: (data: T) => void;
  optionLabel?: string;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
  ) => React.ReactNode;
  everyOpenUpdate?: boolean;
  ListboxProps?: ReturnType<
    ReturnType<typeof useAutocomplete>['getListboxProps']
  >;
}

export default <T extends object>({
  name,
  options,
  loading,
  request,
  placeholder,
  asyncSearch,
  onChange,
  optionLabel,
  everyOpenUpdate,
  ...props
}: AutocompleteProps<T>) => {
  const { control, formState } = useFormContext();
  const onOpen = () => {
    if (request && (everyOpenUpdate || options.length <= 0)) {
      (async () => {
        await request();
      })();
    }
  };

  const onInputChange = debounce(
    async (_: SyntheticEvent, value: string, reason: string) => {
      if (!asyncSearch) return;
      if ((reason === 'input' || reason === 'clear') && request) {
        await request(value);
      }
    },
    DEBOUNCE_DURATION,
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Autocomplete
          {...field}
          onOpen={onOpen}
          value={field?.value || null}
          getOptionLabel={option => option?.[optionLabel || 'name'] || ''}
          isOptionEqualToValue={(option, value) =>
            `${option.id}` === `${value.id}`
          }
          onChange={(_, data) => {
            field.onChange(data);
            onChange && onChange(data);
            return data;
          }}
          options={options}
          onInputChange={onInputChange}
          loading={loading}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.id}>
              {option?.[optionLabel || 'name']}
            </Box>
          )}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={placeholder}
              sx={{
                '& .MuiInputBase-input': {
                  color: 'black',
                },
              }}
              InputProps={{
                ...params.InputProps,
                ...(!!options.length && {"data-cy": "static-select-autocomplete"}), // required for testing
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              error={!!formState.errors[name]?.message}
              helperText={formState.errors[name]?.message}
            />
          )}
          {...props}
        />
      )}
    />
  );
};
