import { ListResponseType } from 'src/types';
export type MinervaKeyType = number | string;

export type FilesResponse =
  | ListResponseType<{ files: FilesType }>
  | null
  | undefined;

export type ThreadFilesResponse = ListResponseType<{
  files: FileEntityType[];
}>;

export interface UploadFileResponse {
  fileName: string;
  fileId: string;
}

export interface LinkFileDto {
  fileName: string;
  createdDate: Date;
  assetsId?: number;
}

export interface LinkedFileResponse {
  categoryId: number;
  createdDate: Date;
  fileName: string;
  key: number;
  sendToIntegration: boolean;
  assetId: number;
}

export interface FilesType {
  serviceOrderId: number;
  serviceOrderName: string;
}

export interface CreatedBy {
  displayText: string;
  email: string;
  key: number;
  phone: string;
}

export interface FileEntityType {
  id: number;
  key?: number;
  fileName?: string;
  name?: string;
  description?: string;
  createdBy?: string | CreatedBy;
  fileServiceId: number;
  chatMessageId?: number;
  createdDate?: string;
}

export interface AssetAttachmentType {
  assetId: number;
  attachmentFile: FileEntityType;
}

export type FileSignType = 'AssetAttachment' | 'UploadImport' | "Logo";

export interface LinkFileToMessagePayload {
  id: MinervaKeyType;
  serviceOrderId?: number;
  projectId?: number;
  fileName: string;
  fileServiceId: MinervaKeyType;
  description: string;
  createdDate: string;
  fileId: MinervaKeyType;
  modifiedDateUtc: string;
  mimeType: string;
  categoryId: MinervaKeyType;
  categoryName: string;
  chatMessageId: string;
}

export interface LinkFileToQuestionnairePayload {
  name: string;
  workOrderId?: string;
  serviceOrderId?: number;
  serviceId?: number;
  taskId?: number | string;
  categoryId: number | string;
  fileServiceId: string;
  description: string;
}

export enum FileUploadType {
  file,
  questionnaire,
  logo
}

export type UploadParams = {
  groupId?: number;
  token: string;
};

export type LinkFileCallback = (file: LinkFileDto) => Promise<unknown>;
export interface UploadData {
  params: Omit<UploadParams, 'token'>;
  linkAction: LinkFileCallback;
  customToken?: string
}
export interface UploadClientLogoResponse extends LinkFileDto {
  createdDate: Date;
  fileName: string
  fileServiceId: number
  id: number
  sendToIntegration: boolean
}