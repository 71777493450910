import { GeneratorsApi } from '../../api/generator';
import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { AccountsModel } from '../../models/account/accounts-model';
import { GridRowId } from '@mui/x-data-grid-pro';
import { columns } from './helpers/gridColumns';
import {
  defaultGeneratorSearch,
  generatorDefaultValue,
  visibilityDefaultState,
} from './helpers/common';
import { TableFilter } from '../../models/filter/table-filter';
import { GeneratorItem, GeneratorsFilterType, GeneratorsResponse } from '../../api/api-types/generators';
import { GeneratorExportColumnsNameMap, generatorsFilterNameMap } from '../../models/filter/constants';
import { GridColumnsManager } from '../../models/grid-columns-manager/grid-columns-manager';
import { TablePagination } from '../../models/pagination/pagination';
import { ApiReq, emptyValue } from '../../api';
import { GeneratorModification } from '../../models/generators/generator-modification';
import { Generator } from '../../models/generators/generator';
import { ExportDataType } from '../../api/exportTypes';
import { storeFactory } from '../../utils/store';
import { userStore } from '../../stores/user';
import { AsyncExportEntity } from 'src/models/export/AsyncExportEntity';
import { ExportTypes } from 'src/types';

export class GeneratorStore {
  api = new GeneratorsApi({ prefix: '' });

  @observable accounts = new AccountsModel();
  @observable isCreation = false;
  @observable selectedGenerators: Array<GridRowId> = [];

  @observable.ref generatorsReq: ApiReq<GeneratorsResponse> = emptyValue;
  @observable.ref locations: IObservableArray<Generator> = observable([]);
  @observable.ref chosenGenerator?: GeneratorModification;

  @observable exportEntity: AsyncExportEntity = new AsyncExportEntity();

  @observable pagination = new TablePagination<GeneratorsResponse>({
    requestRef: this.generatorsReq,
  });

  @observable columnsManager;
  @observable filterModel?: TableFilter<GeneratorsFilterType, GeneratorItem>;

  constructor() {
    makeObservable(this);
    this.pagination.setFetchingCallBack(this.fetchGenerators);

    this.columnsManager = new GridColumnsManager<GeneratorItem>({
      columns,
      columnsMap: this.columnsVisibleMap,
      visibilityModel: visibilityDefaultState,
    });
  }

  @computed get columnsVisibleMap() {
    return {
      jurisdiction: {
        jurisdiction: false,
      },
    };
  }

  @computed get defaultFilterField() {
    if (userStore.isJurisdiction) {
      delete defaultGeneratorSearch['q.jurisdiction.id'];
      return defaultGeneratorSearch;
    }
    return defaultGeneratorSearch;
  }

  @computed get filter() {
    this.filterModel ??= new TableFilter<GeneratorsFilterType, GeneratorItem>(
      this.columnsManager?.gridColumns,
      this.defaultFilterField,
      generatorsFilterNameMap,
      (column, filters) => this.api.getGeneratorsFilterOptions({ filters, column })
    );
    return this.filterModel;
  }

  @computed get generatorsData() {
    if (this.generatorsReq.state !== 'fulfilled' || !this.generatorsReq.value) {
      return [];
    }
    return this.generatorsReq.value.data?.items || [];
  }

  @action fetchGenerators = async () => {
    this.generatorsReq = this.api.getGeneratorsData({
      ...this.pagination.serialize,
      filters: this.filter.filterParams,
    });
    await this.generatorsReq;

    runInAction(() => {
      if (
        this.generatorsReq.state !== 'fulfilled' ||
        this.generatorsReq.value?.data === null
      )
        return;
      this.locations.replace(
        this.generatorsData.map(location => {
          return new Generator(location);
        }),
      );
      this.pagination.setPagination(this.generatorsReq.value?.data);
    });
    return this.generatorsReq;
  };

  @action createNewGenerator = () => {
    this.isCreation = true;
    this.chosenGenerator = new GeneratorModification(generatorDefaultValue);
  };

  @action setSelectedGenerators = (items: GridRowId[]) => {
    this.selectedGenerators = items;
  };

  @action removeSelectedGenerators = () => this.selectedGenerators = [];

  @action exportAction = async (data: ExportDataType) => {
    if (!data.type) return;
    if (Number(data.mode) === ExportTypes.ALL) this.exportEntity.setExportWithUnactiveRecords(true);
    this.exportEntity.setParams(data);
    await this.exportEntity.asyncExportAction(
      this.api.getAsyncReportByType(
        this.exportEntity.exportParams<
          GeneratorsResponse,
          GeneratorsFilterType,
          GeneratorItem
        >(
          this.columnsManager.hiddenColumns.map(
            el => GeneratorExportColumnsNameMap[el as keyof GeneratorItem] || el
          ),
          this.filter,
          this.pagination,
          this.selectedGenerators
        ),
        data.type,
      )
    );
  };

  @action closeModalAction = () => {
    this.isCreation = false;
    this.removeTargetGenerator();
  };

  @action finishCreation = async () => {
    this.closeModalAction();
    await this.fetchGenerators();
  };

  @action removeTargetGenerator = () => this.chosenGenerator = undefined;

  @action resetStore = () => {
    this.pagination.resetPagination();
    this.filter.resetFilters();
    this.filter.setSearchOpen(false);
    this.removeSelectedGenerators();
  };
}

export const { store: generatorStore, storeCtx: generatorsStoreCtx } = storeFactory(
  GeneratorStore,
  'generators',
);
