import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import { AssetCreationType, AssetItem, AssetsResponse } from 'src/api/api-types/assets';
import { SubCategoryDetail, SubCategoryNote, LegalSectionDetail } from 'src/api/api-types/implementation-record';
import { ImplementationRecordApi } from 'src/api/implementationRecord';
import { Asset } from 'src/models/assets/asset';
import { AssetModification } from 'src/models/assets/asset-modification';
import { ItemType } from 'src/types';
import { isPending } from 'src/utils/common';

export class SubCategory {
  api = new ImplementationRecordApi();

  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly implementationRecordLegalSections?: ItemType[];

  @observable hasNotes: boolean = false;
  @observable activitiesCount: number;

  @observable isOpen: boolean = false;
  @observable editSubCategoryReq?: any;
  @observable getSubCategoryAssetsReq?: any;

  @observable note: string = '';
  @observable selectionDetail?: LegalSectionDetail;
  @observable assets: IObservableArray<Asset> = observable([]);
  @observable.ref chosenAsset?: AssetModification;

  @observable.ref assetsReq: ApiReq<AssetsResponse> = emptyValue;
  @observable.ref subCategoryNoteReq?: ApiReq<SubCategoryNote> = emptyValue;
  @observable.ref updateNoteReq: ApiReq<SubCategoryDetail> = emptyValue;
  @observable.ref legalSectionDetailReq: ApiReq<LegalSectionDetail> = emptyValue;


  constructor({
                id,
                name,
                hasNotes,
                activitiesCount,
                description,
                implementationRecordLegalSections,
              }: SubCategoryDetail) {
    makeObservable(this);

    this.id = id;
    this.name = name;
    this.activitiesCount = activitiesCount;
    this.description = description;
    this.hasNotes = hasNotes;
    this.implementationRecordLegalSections = implementationRecordLegalSections;
  }

  @computed get assetsData() {
    if (this.assetsReq.state !== 'fulfilled' || !this.assetsReq.value) {
      return [];
    }

    return this.assetsReq.value.data?.items || [];
  }

  @computed get subCategoryDataLoading() {
    return isPending(this.assetsReq) || isPending(this.subCategoryNoteReq) ||
      isPending(this.updateNoteReq) || isPending(this.legalSectionDetailReq);
  }


  selectAsset(id: number) {
    const needle = this.assets.find(item => item.id === id);
    if (needle) {
      this.chosenAsset = new AssetModification(needle);
      return needle;
    }
    return undefined;
  }

  @action setNote = (text: string) => this.note = text;

  @action toggleCollapse = () => this.isOpen = !this.isOpen;

  @action getAssetsList = async () => {
    this.assetsReq = this.api.getAssets({
      page: 1,
      pageSize: 100,
      filters: {
        'q.implementationRecordSubCategory.id': String(this.id),
      },
    });
    await this.assetsReq;

    runInAction(() => {
      this.assets.replace(
        this.assetsData.map(clientData => {
          return new Asset(clientData);
        }),
      );
    });
  };

  @action getAssetAttachments = async (id: number) => {
    this.selectAsset(id);
    if (!this.chosenAsset) return;
    await this.chosenAsset.attachments.getAttachmentsList();
  };

  @action deleteAsset = async (id: number) => {
    this.selectAsset(id);
    if (!this.chosenAsset) return;
    await this.chosenAsset.deleteAsset();
    await this.getAssetsList();
  };

  @action getAssetFullDesc = async (id: number) => {
    this.selectAsset(id);
    if (!this.chosenAsset) return;
    await this.chosenAsset.getFullDescription();
  };

  @action editAsset = async (data: Partial<AssetCreationType>) => {
    if (!this.chosenAsset) return;
    this.chosenAsset.setEntity({
      ...data,
      updateDescription: true,
    });
    await this.chosenAsset.editAsset();
    await this.getAssetsList();
  };

  @action fetchSubCategoryNote = async (id: number) => {
    this.subCategoryNoteReq = this.api.getSubCategoryNote(id);
    const res = await this.subCategoryNoteReq;
    if (res.data) this.setNote(res.data?.notes);
  };

  @action updateSubCategoryNote = async () => {
    this.updateNoteReq = this.api.updateSubCategoryNote(
      this.id,
      { notes: this.note },
    );
    await this.updateNoteReq;
    runInAction(() => {
      this.hasNotes = this.note.length > 0;
    });
    return this.updateNoteReq;
  };

  @action getSelectionDetail = async (id: number) => {
    this.legalSectionDetailReq = this.api.getLegalSectionDetail(id);
    const res = await this.legalSectionDetailReq;
    runInAction(() => {
      if (res?.data) {
        this.selectionDetail = res.data;
      }
    });
    return this.legalSectionDetailReq;
  };

  @action syncChosenAsset() {
    this.assets.replace(
      this.assets.map(a => {
        let totalAttachments = a.totalAttachments;
        if (a.id === this.chosenAsset?.id) {
          totalAttachments = this.chosenAsset?.attachments.totalAttachments;
        }
        return new Asset({ ...a as AssetItem, totalAttachments });
      }),
    );
  }
}
