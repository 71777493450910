import { AsyncExportResponse, ExportParams, ItemType, ListResponseType, Pageable, ParamsWithFilters } from 'src/types';
import { ApiClient } from '.';
import {
  DiagramsCardsResponse,
  LineChartsResponse,
  LineChartsType,
  PieChartsResponse,
} from './api-types/diagrams';
import {
  GeneratorsFilterType,
  GeneratorsResponse,
  RequestLocationsParams,
} from './api-types/generators';
import { dotNameKeyToObject } from '../utils/string';

export class EarDiagramsApi extends ApiClient {
  getEarGeneratorsData({ page, pageSize, filters }: RequestLocationsParams) {
    return this.requestPost<
      GeneratorsResponse,
      ParamsWithFilters<GeneratorsFilterType>
    >('ear-locations/list', {
      q: dotNameKeyToObject(filters),
      page: {
        num: page,
        size: pageSize,
      },
    });
  }

  async getGeneratorsFilterOptions({ filters, column }: { filters: Partial<GeneratorsFilterType>, column: string }) {
    const filterMap: { [key: string]: string } = {
      hauler: 'hauler.name',
      jurisdiction: 'jurisdiction.name',
      agency: 'agency.name',
      types: 'type.name',
      accountType: 'accountType.name',
      zone: 'zone.name',
      collectionSystem: 'collectionSystem.name'
    };
    const filterColumn = filterMap[column] ?? column;
    const response = await this.requestPost<
      ListResponseType<ItemType> & Pageable,
      ParamsWithFilters<GeneratorsFilterType> & { column: string }
    >('locations/list/filter-options', {
      q: dotNameKeyToObject(filters),
      page: {
        num: 1,
        size: 100000,
      },
      column: filterColumn,
    });

    return response.data!.items;
  }

  getCommonCards(jurisdictionId?: number) {
    return this.requestGet<DiagramsCardsResponse>('ear-dashboard/Cards', {
      'q.jurisdictionId': jurisdictionId,
    });
  }

  getMOReCards(jurisdictionId?: number) {
    return this.requestGet<DiagramsCardsResponse>('ear-dashboard/MOReCards', {
      'q.jurisdictionId': jurisdictionId,
    });
  }

  getMcrCards(jurisdictionId?: number) {
    return this.requestGet<DiagramsCardsResponse>('ear-dashboard/McrCards', {
      'q.jurisdictionId': jurisdictionId,
    });
  }

  getPieChart(jurisdictionId?: number) {
    return this.requestGet<PieChartsResponse>('ear-dashboard/SlcpPieChart', {
      'q.jurisdictionId': jurisdictionId,
    });
  }

  getNonPieChart(jurisdictionId?: number) {
    return this.requestGet<PieChartsResponse>(
      'ear-dashboard/SlcpNonPieChart', 
      {
      'q.jurisdictionId': jurisdictionId,
      }
    );
  }

  getSubjectLineChart(jurisdictionId?: number) {
    return this.requestGet<LineChartsResponse>(
      'ear-dashboard/SlcpSubjectLineChart',
      {
        'q.jurisdictionId': jurisdictionId,
      },
    );
  }

  getEARLineChart(jurisdictionId?: number) {
    return this.requestGet<LineChartsType>(
      'ear-dashboard/SlcpSubjectLineChart/highcharts',
      {
        'q.jurisdictionId': jurisdictionId,
      },
    );
  }

  getSLCPLineChart(jurisdictionId?: number) {
    return this.requestGet<LineChartsType>(
      'ear-dashboard/SlcpComplianceLineChart/highcharts',
      {
        'q.jurisdictionId': jurisdictionId,
      },
    );
  }

  getAsyncReportByType(path: string, params: ExportParams, exportType: string) {
    return this.requestPost<AsyncExportResponse, ExportParams>(
      `${path}/export/${exportType}?flowType=2`,
      params,
    );
  }

  getAsyncDashboardChartByType(path: string, type: string, params: ExportParams) {
    return this.getAsyncReportByType(`ear-dashboard/${path}/highcharts`,params, type );
  }

  getAsyncGeneratorsReportByType(params: ExportParams, exportType: string) {
    return this.getAsyncReportByType('ear-locations',params, exportType );
  }
}
